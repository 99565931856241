<template>
  <div class="pollintro-container">
    <div class="header">
      Umfrage
    </div>
    <div class="separator"/>
    <div class="intro">
      Schön, dass Sie bereit sind, die Nachhaltigkeit Ihres Handelns zu hinterfragen.
      Gleichzeitig können Sie so Ihre Stimme hörbar machen, indem sie angeben,
      an welchen Stellen Ihrer Meinung nach mehr für Nachhaltigkeit in der Region passieren sollte. <br/>
      Meistens können Sie dabei aus mehreren Antwortmöglichkeiten auswählen, manchmal müssen Sie sich für eine
      Antwort entscheiden. Daraus errechnen wir für Sie Ihren persönlichen Nachhaltigkeitsscore.
      <p>Wir wünschen Ihnen viel Spaß!</p>
    </div>
    <div class="button-container">
      <router-link to="/poll" tag="div" class="button secondary-bg">Los geht's!</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PollIntro',
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.pollintro-container {
  min-height: 100vh;
}

.intro {
  padding: 30px;
  text-align: left;
}
</style>