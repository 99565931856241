<template>
  <div class="thankyou-container">
    <h1>Vielen Dank für deine Teilnahme!</h1>
    <router-link to="/highscores" tag="div" class="highscore">zur Auswertung</router-link>
  </div>
</template>

<script>
export default {
  name: 'ThankYou',
  
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.thankyou-container {
  height: 100%;
  @include gradient;
  padding-top: 100px;
}
.highscore {
  padding: 20px 30px;
  background-color: $secondary;
  color: white;
  display: inline-block;
  margin-top: 20px;
}
</style>