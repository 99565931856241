<template>
  <div class="mainmenu-container">
    <div class="header">
      Schön, dass Sie hier sind!
    </div>
    <div class="separator"/>
    <div class="tiles">
      <div class="tile" @click="$router.push('/sdgs')">
        <h3>
          <img class="icon" src="../assets/info.png"/>
          Nachhaltigkeit
        </h3>
        Das Wort „Nachhaltigkeit“ ist in aller Munde – doch was bedeutet das eigentlich? Unter diesem Menüpunkt erfahren Sie Näheres dazu.
      </div>
      <div class="tile" @click="$router.push('/poll-intro')">
        <h3>
          <img class="icon" src="../assets/poll.png"/>
          Testen Sie sich selbst
        </h3>
        Unter „Testen Sie sich selbst“ können Sie einerseits Ihre Meinung darüber abgeben,
        in welchen Bereichen Ihre Region nachhaltiger werden kann. Anderseits können Sie sich auch selbst testen:
        Wie enkelfreundlich leben Sie heute schon?
      </div>
        <div class="tile" @click="$router.push('/highscores')">
        <h3>
          <img class="icon" src="../assets/results.png"/>
          Nachhaltigkeits-Score
        </h3>
        Ihr Ergebnis können Sie unter dem Menüpunkt „Nachhaltigkeits-Score“ mit den Ergebnissen der anderen Teilnehmer
        vergleichen – völlig anonym versteht sich.
      </div>
      <div class="tile" @click="$router.push('/tipps')">
        <h3>
          <img class="icon" src="../assets/tipps.png"/>
          Das können Sie tun: Praxis-Tipps zum Thema Nachhaltigkeit
        </h3>
          Sie wollen (noch) nachhaltiger leben? – Sehr gut! Viele praxisnahe Tipps für einen zukunftsfähigeren Lebensstil finden Sie hier in diesem Bereich.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainMenu',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.mainmenu-container {
  height: 100%;
  text-align: left;
}

.tiles {
  padding: 20px;
}

.tile {
  @include shadow;
  padding: 20px;
  // padding-top: 0;
  border-radius: $border-radius;
  margin-bottom: 20px;
  cursor: pointer;

  h3 {
    margin-top: 0;
  }
}
</style>
