import Vue from 'vue'
import Vuex from 'vuex'

import JSEncrypt from 'jsencrypt'
import publicKey from '@/data/public-key.js'

Vue.use(Vuex)

import backendService from '@/services/backend-service.js'
import sections from '@/data/poll_questions.js'

export default new Vuex.Store({
    state: {
        answers: [],
        demographics: {},
        email: '',
        group: ''
    },
    mutations: {
        age(state, age) {
            state.demographics.age = age
        },
        gender(state, gender) {
            state.demographics.gender = gender
        },
        education(state, education) {
            state.demographics.education = education
        },
        region(state, region) {
            state.demographics.region = region
        },
        email(state, email) {
            state.email = email
        },
        group(state, group) {
            state.group = group
        },
        addAnswer(state, answer) {
            state.answers.push(answer)
        },
        resetAnswers(state) {
            state.answers = []
        }
    },
    actions: {
        submitAnswers({state}) {
            var encrypt = new JSEncrypt()
            encrypt.setPublicKey(publicKey)
            let anonymizedUserID = encrypt.encrypt(state.email + state.group)

            let datapoints = state.answers.map(a => {
                return {
                    section: a.section,
                    measure: a.measure,
                    relevance: a.relevance,
                    active: a.active,
                    question: a.question,
                    answer: a.answer,
                    score: a.score,

                    age: state.demographics.age,
                    gender: state.demographics.gender,
                    region: state.demographics.region,
                    education: state.demographics.education,
                    anonymizedUserID: anonymizedUserID
                }
            })
            // axios.post(BACKEND_URL + '/save', datapoints, { headers: { 'X-Auth': 'think:green', 'X-Email': state.email }})
            backendService.savePollResults(datapoints, state.email, state.group)
            let score = state.answers.reduce((sum, curr) => sum += curr.score, 0)
            localStorage.setItem('think-green:score', JSON.stringify(score))

            const keys = new Array('Mobilität', 'Abfall', 'Energie', 'Konsum', 'Ernährung')

            let max_score = {}
            for (const s in sections) {
                const sec = sections[s]
                max_score[sec.section] = 0
                for (const meas in sec.measures) {
                    let max = 0;
                    let max2 = 0;
                    const q = sec.measures[meas]
                    for (const a in q.subquestionsActive) {
                        const answers = q.subquestionsActive[a].options
                        max += Math.max.apply(Math, answers.map(function (o) {
                            return o["score"];
                        }))
                    }
                    for (const a in q.subquestionsInactive) {
                        const answers2 = q.subquestionsInactive[a].options
                        max2 += Math.max.apply(Math, answers2.map(function (o) {
                            return o["score"];
                        }))
                    }
                    max_score[sec.section] += Math.max(max, max2)
                }
            }

            let section_scores = {}
            for (const k in keys) {
                const ks = keys[k]
                let sum = 0
                let count = 0
                for (let a in datapoints) {
                    const dp = datapoints[a]
                    if (dp.section == ks) {
                        sum += dp.score
                        count++
                    }
                }
                if (count > 0) section_scores[ks] = sum / max_score[ks] * 100
                else section_scores[ks] = 0
            }
            for (let i = 0; i <= 1; i++) {
                const max = Math.max(...Object.values(section_scores))
                const key = Object.keys(section_scores).find(k => section_scores[k] === max)
                delete section_scores[key]
            }


            localStorage.setItem("think-green:section_scores", JSON.stringify(Object.keys(section_scores)))
        }
    }
})
