<template>
  <div class="poll-container">
    <poll/>
  </div>
</template>

<script>
import Poll from '@/components/Poll.vue'
export default {
  name: 'PollView',
  components: {
    Poll,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.poll-container {
  @include gradient;
  min-height: 100vh;
}
</style>