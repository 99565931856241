<template>
  <div class="header-bar-container">
    <div class="mib">
      <a href="https://mensch-in-bewegung.info/" target="_blank">
        <img src="../assets/logos/mib.svg"/>
      </a>
    </div>

    <Slide :closeOnNavigation="true">
      <router-link to="/" tag="div" class="nav-item">
        <img class="icon" src="../assets/info.png"/>
        Start
      </router-link>
      <router-link to="/menu" tag="div" class="nav-item">
        <img class="icon" src="../assets/info.png"/>
        Hauptmenü
      </router-link>
      <router-link to="/contact" tag="div" class="nav-item">
        <img class="icon" src="../assets/results.png"/>
        Kontakt
      </router-link>
    </Slide>
  </div>
</template>

<script>
import { Slide } from 'vue-burger-menu' 

export default {
  name: 'HeaderBar',
  components: {
    Slide
  },
}
</script>

<style lang="scss">
.header-bar-container {
  height: 100px;
  position: relative;
}
.nav-item {
  line-height: 30px;
  cursor: pointer;
  text-align: left;
}
.icon {
  max-width: 30px;
  max-height: 30px;
  margin-right: 10px;
}

.mib {
  height: 100px;
  text-align: right;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
