<template>
  <div class="highscores-container">
    <div class="header">
      Nachhaltigkeits-Score
    </div>
    <div class="separator"/>
    <div class="intro">
      Ihr eigenes Ergebnis können Sie hier mit den Ergebnissen anderer Teilnehmerinnen und Teilnehmer vergleichen –
      völlig anonym versteht sich.
    </div>
    <div class="content">
      <div v-if="isHighScoresLoading">
        <i class="fa fa-spinner fa-spin"/>
      </div>
      <div v-for="(s, i) in highscores" class="score" :class="{ own : s.score == score }" :key="s.rank">
        Platz {{ i + 1 }} : {{ s.score }}
      </div>
      <div class="own-score">
        Dein Nachhaltigkeits-Score:<br/>
        <span v-if="score" style="font-weight: bold;">{{ score }} Punkte</span>
        <span v-else>noch kein Score vorhanden</span>
        <span v-if="score && highscores.length > 0"> - Platz {{ ownRank }} </span>
      </div>

      <div v-if="score">
        <p v-if="score > 80">
          <b>Perfekt! (100-81 Punkte)</b><br/>
          Was soll man da noch sagen? In Sachen Nachhaltigkeit macht Ihnen keiner was vor. Sie achten bei Ihren
          Konsumentscheidungen stets auf ihre Auswirkungen für Mensch und Umwelt. Nachhaltigkeit ist für Ihren Alltag
          selbstverständlich geworden und Ihr Handeln macht Sie zu einem echten Vorbild für andere – weiter so!
        </p>

        <p v-else-if="score > 60">
          <b>Fast perfekt! (70-61 Punkte)</b><br/>
          Nachhaltigkeit ist für Sie nicht nur irgendein Trend, es ist eine Einstellung. Sie achten zum Großteil auf
          Ihre Konsumentscheidungen und holen sich regelmäßig neue Informationen, wie Sie nachhaltig handeln können.
          Wenn Sie jetzt noch die eine oder andere Gewohnheit (z.B. in den
          Bereichen <a :href="url0">{{ section_scores[0] }}</a>, <a :href="url1">{{ section_scores[1] }}</a>,
          <a :href="url2">{{ section_scores[2] }}</a>)
          ändern, steht Ihnen nichts mehr im Weg, um ganz Perfekt! zu werden. <br/><br/>
          Viele Tipps und Infos, wie Sie nachhaltiger leben können, finden Sie unter dem Menüpunkt
          <a href="/tipps">
            „Das können Sie tun: Praxis-Tipps zum Thema Nachhaltigkeit“
          </a>.

        </p>
        <p v-else-if="score > 40">
          <b>Fortgeschritten (60-41 Punkte)</b><br/>
          Man kann Sie wirklich als Fortgeschritten bezeichnen: Sie konsumieren insgesamt bereits ziemlich bewusst und
          nehmen dafür auch gewisse Unbequemlichkeiten in Kauf. Viele Verhaltensänderungen konnten Sie bereits in Ihren
          Alltag integrieren, andere fallen Ihnen vielleicht noch etwas schwer, z.B. in den
          Bereichen <a :href="url0">{{ section_scores[0] }}</a>, <a :href="url1">{{ section_scores[1] }}</a>,
          <a :href="url2">{{ section_scores[2] }}</a>).
          Klicken Sie gerne auf die Links, um direkt Tipps dazu zu erhalten! <br/><br/>
          Viele Tipps und Infos, wie Sie nachhaltiger leben können, finden Sie unter dem Menüpunkt
          <a href="/tipps">
            „Das können Sie tun: Praxis-Tipps zum Thema Nachhaltigkeit“
          </a>.

        </p>
        <p v-else-if="score > 20">
          <b>Schon gut dabei (40-21 Punkte)</b><br/>
          Nachhaltigkeit ist für Sie kein Fremdwort, wo es für Sie passt, achten Sie bereits auf nachhaltige
          Einkaufsentscheidungen und nachhaltiges Handeln. Sie sind schon auf einem guten Weg, die Umstellung des
          eigenen (Konsum-) Verhaltens braucht jedoch Zeit – und die sollten Sie sich auch nehmen! Hier helfen kleine
          Schritte, die irgendwann einfach zur Gewohnheit werden, fangen Sie doch mit den
          Bereichen <a :href="url0">{{ section_scores[0] }}</a>, <a :href="url1">{{ section_scores[1] }}</a>,
          <a :href="url2">{{ section_scores[2] }}</a>)
          gleich an! <br/><br/>
          Viele Tipps und Infos, wie Sie nachhaltiger leben können, finden Sie unter dem Menüpunkt
          <a href="/tipps">
            „Das können Sie tun: Praxis-Tipps zum Thema Nachhaltigkeit“</a>.
        </p>

        <p v-else-if="score >= 0">
          <b>Guter Anfang (20-0 Punkte)</b><br/>
          Nachhaltigkeit war für Sie bisher noch nicht das große Thema? Kein Problem! Vielleicht finden Sie ein oder
          zwei Bereiche, an denen Sie arbeiten möchten oder die Sie ändern können. So ist ein leichter Einstieg gemacht
          und Sie können sich schrittweise für das Thema begeistern. Keine Sorge, es macht mehr Spaß, als Sie vielleicht
          denken! <br/><br/>
          Viele Tipps und Infos, wie Sie nachhaltiger leben können, finden Sie unter dem Menüpunkt
          <a href="/tipps">
            „Das können Sie tun: Praxis-Tipps zum Thema Nachhaltigkeit“</a>.
        </p>

      </div>
      <p>
        Wenn Sie zum Thema noch tiefer einsteigen wollen, sind Sie im Kapitel <a href="/sdgs">
        „Nachhaltigkeit“</a> richtig!
      </p>
    </div>
  </div>
</template>

<script>
import backendService from '@/services/backend-service.js'

const SCORE_KEY = 'think-green:score'
const SECTION_SCORES_KEY = 'think-green:section_scores'

export default {
  name: 'Highscores',
  data() {
    return {
      score: null,
      section_scores: [],
      rawScores: [],
      isHighScoresLoading: false,
      url0: "",
      url1: "",
      url2: "",
    }
  },
  async created() {
    this.score = JSON.parse(localStorage.getItem(SCORE_KEY));
    this.section_scores = JSON.parse(localStorage.getItem(SECTION_SCORES_KEY));
    this.isHighScoresLoading = true;
    await backendService.getHighscores().then(data => {
      this.rawScores = data;
    }).finally(() => {
      this.isHighScoresLoading = false;
    });
  },
  mounted() {
    this.url0 = "/tipps#" + this.replace_umlaut(this.section_scores[0]);
    this.url1 = "/tipps#" + this.replace_umlaut(this.section_scores[1]);
    this.url2 = "/tipps#" + this.replace_umlaut(this.section_scores[2]);
  },
  methods: {
    name() {

    },
    replace_umlaut(w) {
      let replaced = w.replace('ä', 'ae')
      replaced = replaced.replace('ö', 'oe')
      replaced = replaced.replace('ü', 'ue')
      return replaced
    }
  },
  computed: {
    sortedScores() {
      return this.rawScores.slice().sort((a, b) => a.score < b.score ? 1 : -1)
    },
    highscores() {
      return this.sortedScores.slice(0, 5)
    },
    ownRank() {
      return this.sortedScores.map(s => s.score).indexOf(this.score) + 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.highscores-container {
  height: 100%;
  text-align: left;
}

.content {
  padding: 30px;
}

.own-score {
  margin: 30px 0px;
  font-size: 20px;
}

.score {
  font-size: 20px;
  font-weight: bold;
  border: 1px solid rgb(36, 167, 36);
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: $border-radius;
}

.link {
  text-decoration: underline;
}

.own {
  color: rgb(36, 167, 36);
  text-decoration: underline;
}
</style>
