<template>
  <div class="sdgs-container">
    <div class="header">
    </div>
    <h2>
      FREQUENTLY ASKED QUESTIONS (FAQ) zur NACHHALTIGKEIT<br/>
      (von Dr. Stefan Schweiger)
    </h2>
    <div class="separator"></div>

    <sdg-list/>

  </div>
</template>

<script>
import SdgList from '@/components/SdgList.vue'

export default {
  name: 'Sdg',
  components: {
    SdgList,
  },

}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.sdgs-container {
  min-height: 100%;
}

.separator {
  height: 5px;
  background-color: $secondary;
  margin-bottom: 30px;
}

</style>