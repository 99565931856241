import axios from 'axios'

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL

const httpClient = axios.create({
  timeout: 10000,
  headers: { 'X-Auth': 'think:green' }
})

class BackendService {

  async savePollResults(datapoints, email, alternative) {
    let data = {datapoints}
    if (email !== "") {
      data.email = email
    } else {
      data.alternative = alternative
    }
    return httpClient.post(BACKEND_URL + '/save', data)
      .then(res => res.data)
  }

  async validateEmail(uid) {
    return httpClient.get(BACKEND_URL + '/validate-user?user='+uid).then(res => res.data)
  }

  async getHighscores() {
    return httpClient.get(BACKEND_URL + '/statistics/scores')
      .then(res => res.data)
  }

}

export default new BackendService()