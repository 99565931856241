export default [
  {
    section: "Mobilität",
    measures: [
      {
        name: "Nutzung ÖPNV",
        info: "ÖPNV = Öffentlicher Personennahverkehr",
        relevanceQuestion: "",
        activityQuestion:
          "Nutzen Sie den öffentlichen Nahverkehr (Bus, Bahn etc.)?",
        subquestionsActive: [
          {
            question:
              "Wie oft haben Sie den öffentlichen Nahverkehr (Bus, Bahn etc.) im letzten Monat genutzt?",
            options: [
              {
                uid: "fast täglich.",
                score: 4
              },
              {
                uid: "2-3 mal pro Woche.",
                score: 3
              },
              {
                uid: "weniger als 2 mal pro Woche.",
                score: 2
              },
              {
                uid: "höchstens 1 mal pro Woche.",
                score: 1
              },
              {
                uid: "seltener.",
                score: 0,
                showInactiveAfterLast: true
              }
            ],
            type: "single-select"
          },
          {
            question: "Meine Anbindung an das Nahverkehr-Netz ist...",
            options: [
              {
                uid: "prima, ich kann wohnortnah einsteigen und erreiche meine Zielorte.",
                score: 0
              },
              {
                uid: "gut, ich muss nur selten Strecken überbrücken (z.B. zu Fuß oder mit dem Rad).",
                score: 0
              },
              {
                uid:
                  "in Ordnung, ich muss aber größere Strecken überbrücken (mehr als 500 m).",
                score: 0
              },
              {
                uid:
                  "schlecht, für meine täglichen Strecken ist es nicht ausreichend.",
                score: 0
              }
            ],
            type: "single-select"
          },
          {
            question: "Die Preise für den Nahverkehr sind...",
            options: [
              {
                uid: "günstig.",
                score: 0
              },
              {
                uid: "angemessen",
                score: 0
              },
              {
                uid: "zu hoch.",
                score: 0
              }
            ],
            type: "single-select"
          }
        ],
        subquestionsInactive: [
          {
            question:
              "Ich nutze den öffentlichen Nahverkehr kaum oder gar nicht, weil...  (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "mir Unabhängigkeit von Fahrplänen wichtig ist.",
                score: 0
              },
              {
                uid: "meine Strecke nicht (komplett) abgedeckt ist.",
                score: 0
              },
              {
                uid: "es zu teuer ist.",
                score: 0
              },
              {
                uid: "ich fast nur Fahrrad fahre oder laufe.",
                score: 5
              },
              {
                uid: "er mir zu unzuverlässig ist.",
                score: 0
              },
              {
                uid: "ich Sicherheitsbedenken habe.",
                score: 0
              },
              {
                uid:
                  "ich Sperriges oder Schweres so schlecht transportieren kann.",
                score: 0
              },
              {
                uid: "es mir zu lange dauert.",
                score: 0
              },
              {
                uid: "er mir häufig zu überfüllt ist.",
                score: 0
              },
              {
                uid: "ich Hygienebedenken habe",
                score: 0
              },
              {
                uid: "er mir zu selten fährt",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ]
      },
      {
        name: "Spritverbrauch des Autos",
        info: "",
        relevanceQuestion: "",
        activityQuestion:
          "Ich achte auf einen geringen Spritverbrauch meines Autos. (Wenn Ihrem Haushalt kein Auto zur Verfügung steht, dann klicken Sie den Daumen nach unten.)",
        subquestionsActive: [
          {
            question:
              "Ich achte auf einen geringen Spritverbrauch meines Fahrzeuges durch...  (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "eine spritsparende Fahrweise.",
                score: 1
              },
              {
                uid: "ein Auto mit geringem Verbrauch.",
                score: 2
              },
              {
                uid: "Veränderungen am Auto (z.B. Leichtlauföl).",
                score: 1
              }
            ],
            type: "multi-select"
          }
        ],
        subquestionsInactive: [
          {
            question:
              "Ich achte nicht auf den Spritverbrauch meines Fahrzeugs, weil... (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "andere Eigenschaften eines Autos mir wichtiger sind.",
                score: 0
              },
              {
                uid: "es die Auswahl beim Autokauf zu sehr einschränkt.",
                score: 0
              },
              {
                uid: "es keine passenden Alternativen gibt.",
                score: 0
              },
              {
                uid: "er nebensächlich ist.",
                score: 0
              },
              {
                uid: "ich kein Auto besitze.",
                score: 3
              }
            ],
            type: "multi-select"
          }
        ]
      },
      {
        name:
          "Mobil mit eigener Kraft (keine Nutzung von Auto, Bahn oder Flugzeug)",
        info: "",
        relevanceQuestion: "",
        activityQuestion:
          "Ich lege 90% meiner täglichen Strecken ohne motorisiertes Fahrzeug zurück (also ohne E-Bikes o.ä.).",
        subquestionsActive: [
          {
            question: "Den Großteil meiner täglichen Strecken bewältige ich...",
            options: [
              {
                uid: "mit dem Fahrrad.",
                score: 10
              },
              {
                uid: "zu Fuß.",
                score: 10
              },
              {
                uid: "mit dem Tretroller.",
                score: 10
              },
              {
                uid: "Sonstiges",
                score: 10
              }
            ],
            type: "single-select"
          }
        ],
        subquestionsInactive: [
          {
            question:
              "Ich nutze vor allem das Auto/Motorrad/etc., weil...  (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "es sonst zu zeitaufwändig wäre.",
                score: 0
              },
              {
                uid: "es sonst zu anstrengend wäre.",
                score: 0
              },
              {
                uid: "ich sonst dem Wetter ausgesetzt wäre.",
                score: 0
              },
              {
                uid:
                  "die Infrastruktur wie z. B. Fahrradwege nicht ausreichend ausgebaut ist.",
                score: 0
              },
              {
                uid: "ich gerne Auto/Motorrad fahre.",
                score: 0
              },
              {
                uid: "mir z.B. zu Fuß gehen oder Radfahren zu gefährlich ist",
                score: 0
              },
              {
                uid: "es mir gesundheitlich nicht möglich ist.",
                score: 0
              },
              {
                uid: "ich sonst keine schweren Dinge transportieren kann.",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ]
      },
      {
        name: "Verzicht auf Flugreisen",
        info: "",
        relevanceQuestion: "",
        activityQuestion:
          "Ich verzichte ganz oder teilweise auf Flugreisen.",
        subquestionsActive: [
          {
            question:
              "Ich begrenze meine Flugreisen (Hin- und Rückflug) auf...",
            options: [
              {
                uid: "keine.",
                score: 7
              },
              {
                uid: "1 mal alle 2 bis 3 Jahre.",
                score: 3
              },
              {
                uid: "höchstens 1 mal pro Jahr.",
                score: 2
              },
              {
                uid: "höchstens 2 mal pro Jahr.",
                score: 1
              },
              {
                uid: "mehr als 3 mal pro Jahr.",
                score: 0
              }
            ],
            type: "single-select"
          },
          {
            question: "Ich nutze...",
            options: [
              {
                uid: "vor allem Inlandsflüge.",
                score: 0
              },
              {
                uid: "vor allem Flüge ins Ausland.",
                score: 0
              },
              {
                uid: "Flüge im In- und Ausland.",
                score: 0
              },
              {
                uid: "keine Flugzeuge.",
                score: 0
              }
            ],
            type: "single-select"
          }
        ],
        subquestionsInactive: [
          {
            question:
              "Ich verzichte nicht auf Flugreisen, weil... (Mehrfachnennungen möglich)",
            options: [
              {
                uid:
                  "ich die Treibhausgasemissionen durch Klimaschutzprojekte kompensiere.",
                score: 3
              },
              {
                uid: "mir Reisen wichtig sind.",
                score: 0
              },
              {
                uid: "es günstiger ist als andere Verkehrsmittel.",
                score: 0
              },
              {
                uid: "es mir Zeit spart.",
                score: 0
              },
              {
                uid: "ich beruflich fliegen muss.",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ]
      }
    ]
  },
  {
    section: "Abfall",
    measures: [
      {
        name: "Verpackungsfreie Ware einkaufen",
        info: "",
        relevanceQuestion: "",
        activityQuestion:
            "Ich achte darauf, wenig oder nicht verpackte Produkte zu kaufen.",
        subquestionsActive: [
          {
            question: "Ich kaufe Produkte...",
            options: [
              {
                uid: "meistens verpackungsfrei (sofern möglich).",
                score: 5
              },
              {
                uid: "manchmal verpackungsfrei",
                score: 4
              },
              {
                uid: "selten verpackungsfrei.",
                score: 2
              },
              {
                uid: "nur bei bestimmten Produktgruppen unverpackt.",
                score: 1
              }
            ],
            type: "single-select"
          },
          {
            question:
                "Mir ist verpackungsfreie Ware wichtig, weil... (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "es günstiger ist.",
                score: 0
              },
              {
                uid: "damit Abfall vermieden werden kann.",
                score: 0
              },
              {
                uid: "man die Menge so selbst bestimmen kann.",
                score: 0
              },
              {
                uid: "es Ressourcen schont.",
                score: 0
              },
              {
                uid: "es gesünder ist.",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ],
        subquestionsInactive: [
          {
            question:
                "Ich kaufe keine verpackungsfreie Ware, weil...  (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "es in meiner Nähe keine Möglichkeit gibt, diese zu kaufen.",
                score: 0
              },
              {
                uid: "der Einkauf dadurch komplizierter wird.",
                score: 0
              },
              {
                uid: "es mir zu unbequem ist.",
                score: 0
              },
              {
                uid: "es mir zu unhygienisch ist.",
                score: 0
              },
              {
                uid: "es mir zu zeitaufwändig ist.",
                score: 0
              },
              {
                uid: "Lebensmittel länger haltbar bleiben.",
                score: 0
              },
              {
                uid: "es meine Auswahl zu sehr einschränkt.",
                score: 0
              },
              {
                uid: "ich es nicht wichtig finde.",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ]
      },
      {
        name: "Verzicht auf Produkte mit Mikroplastik",
        info:
            "Als Mikroplastik werden winzige Plastikteile von 5 Millimetern und kleiner bezeichnet. Die gesundheitlichen Auswirkungen auf den Menschen sind noch unklar.",
        relevanceQuestion: "",
        activityQuestion:
            "Ich achte bei meinem regelmäßigen Einkauf darauf Mikroplastik zu vermeiden.",
        subquestionsActive: [
          {
            question: "Bei meinem regelmäßigen Einkauf achte ich...",
            options: [
              {
                uid:
                    "wann immer es möglich ist darauf, dass kein Mikroplastik enthalten ist.",
                score: 4
              },
              {
                uid: "meistens darauf, dass kein Mikroplastik enthalten ist.",
                score: 3
              },
              {
                uid: "selten darauf, dass kein Mikroplastik enthalten ist.",
                score: 1
              },
              {
                uid:
                    "nur bei bestimmten Produktgruppen darauf, dass kein Mikroplastik enthalten ist.",
                score: 1
              }
            ],
            type: "single-select"
          }
        ],
        subquestionsInactive: [
          {
            question:
                "Ich achte beim Kauf nicht darauf, ob Mikroplastik enthalten ist, da es... (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "mir zu zeitaufwändig ist.",
                score: 0
              },
              {
                uid: "zu wenig Alternativprodukte gibt.",
                score: 0
              },
              {
                uid: "Alternativprodukte zu teuer sind.",
                score: 0
              },
              {
                uid: "es nebensächlich ist.",
                score: 0
              },
              {
                uid:
                    "ich nicht erkenne, wo es drin ist.",
                score: 0
              },
              {
                uid: "mir nicht klar ist, um was es geht.",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ]
      }
    ]
  },
  {
    section: "Energie",
    measures: [
      {
        name: "Ökostrom",
        info: "",
        relevanceQuestion: "",
        activityQuestion: "Unser/Mein Haushalt bezieht Strom aus erneuerbarer Energie.",
        subquestionsActive: [
          {
            question: "Unser/Mein Haushalt bezieht Strom aus erneuerbarer Energie...",
            options: [
              {
                uid: "zu 100 %.",
                score: 13
              },
              {
                uid: "zum Großteil (mehr als 50 %).",
                score: 9
              },
              {
                uid: "teilweise (weniger als 50 %).",
                score: 5
              }
            ],
            type: "single-select"
          }
        ],
        subquestionsInactive: [
          {
            question:
                "Unser/Mein Haushalt bezieht keinen Strom aus erneuerbarer Energie, weil... (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "er uns/mir zu teuer ist.",
                score: 0
              },
              {
                uid: "unser/mein Stromversorger ihn nicht anbietet.",
                score: 0
              },
              {
                uid: "ich keine Zeit habe, mich zu informieren.",
                score: 0
              },
              {
                uid: "es nebensächlich ist, woher der Strom kommt.",
                score: 0
              },
              {
                uid: "ich das Konzept erneuerbare Energien nicht unterstützenswert finde.",
                score: 0
              },
              {
                uid: "ich glaube, dass es auch nicht wirklich ökologischer ist.",
                score: 0
              },
              {
                uid: "meine Wohnsituation es nicht erlaubt.",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ]
      },
      {
        name: "Ökologisches Sanieren von Gebäuden",
        info: "z. B. bessere Wärmedämmung, um weniger zu heizen",
        relevanceQuestion: "",
        activityQuestion:
            "Ökologische Sanierung (z.B. bessere Wärmedämmung, um weniger zu heizen) ist für mich eine gute Option. Weiter bei nein, wenn kein eigenes Haus.",
        subquestionsActive: [
          {
            question:
                "Ich habe mich bereits über das ökologische Sanieren meines Hauses informiert.",
            options: [
              {
                uid: "Ja.",
                score: 2
              },
              {
                uid: "Nein.",
                score: 0
              }
            ],
            type: "single-select"
          },
          {
            question:
                "Ich habe bereits Maßnahmen zur ökologischen Sanierung meines Hauses unternommen.",
            options: [
              {
                uid: "Ja.",
                score: 8
              },
              {
                uid: "Nein.",
                score: 0
              }
            ],
            type: "single-select"
          }
        ],
        subquestionsInactive: [
          {
            question:
                "Eine ökologische Sanierung meines Hauses kommt nicht in Frage, weil... (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "es sich für mich nicht lohnt.",
                score: 0
              },
              {
                uid: "die Sanierung auch nicht ökologisch ist.",
                score: 0
              },
              {
                uid: "es zu teuer ist.",
                score: 0
              },
              {
                uid: "der Energieverbrauch nebensächlich ist.",
                score: 0
              },
              {
                uid: "ich nicht Eigentümer(in) des Hauses bin.",
                score: 10
              }
            ],
            type: "multi-select"
          }
        ]
      }
    ]
  },
  {
    section: "Konsum",
    measures: [
      {
        name: "Sharing- und Tauschkonzepte",
        info:
            "Man tauscht, mietet oder teilt Gegenstände anstatt sie alleine zu besitzen oder zu kaufen (z. B. Car-Sharing, kostenloser Kleidertausch, Verleihen von Werkzeug, o. ä.).",
        relevanceQuestion: "",
        activityQuestion:
            "Ich nehme Tausch- oder Teilangebote in Anspruch (z. B. Car-Sharing, Kleidertausch)",
        subquestionsActive: [
          {
            question:
                "Ich nutze Angebote zum Teilen oder Tauschen von Gegenständen (Sharing)... (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "für Autos (Car-Sharing, statt eigenem Auto).",
                score: 7
              },
              {
                uid: "für Fahrgemeinschaften (z.B. zur Arbeit).",
                score: 3
              },
              {
                uid: "für Fahrräder (Bike-Sharing).",
                score: 2
              },
              {
                uid: "bei Lebensmitteln (z. B. Foodsharing).",
                score: 2
              },
              {
                uid: "für Werkzeuge.",
                score: 2
              },
              {
                uid: "für Kleidung.",
                score: 2
              }
            ],
            type: "multi-select"
          },
          {
            question:
                "Ich nutze solche Angebote über...  (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "Online-Plattformen bzw. Smartphone-Apps.",
                score: 0
              },
              {
                uid: "lokale Anlaufstellen (z. B. Tauschläden).",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ],
        subquestionsInactive: [
          {
            question:
                "Ich nutze keine Teil- oder Tauschangebote, weil... (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "es kein lokales Angebot in meiner Umgebung gibt.",
                score: 1
              },
              {
                uid: "es mir wichtig ist, die Dinge zu kaufen",
                score: 0
              },
              {
                uid: "es mir wichtig ist, die Dinge zu besitzen",
                score: 0
              },
              {
                uid: "es zu aufwendig ist.",
                score: 0
              },
              {
                uid: "ich dadurch unflexibler bin.",
                score: 0
              },
              {
                uid:
                    "andere Benutzer oder Benutzerinnen meine angebotenen Dinge beschädigen könnten.",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ]
      }
    ]
  },
  {
    section: "Ernährung",
    measures: [
      {
        name: "Kauf saisonaler & regionaler Produke",
        info: "Der Begriff Region ist weder geschützt noch gibt es dazu eine verbindliche Definition. Es gibt unterschiedliche Anhaltspunkte: So kann z. B. das Regionalfenster (https://www.regionalfenster.de/) bei der Bestimmung helfen. ",
        relevanceQuestion: "",
        activityQuestion:
            "Ich achte bei meinem regelmäßigen Einkauf auf regionale Produkte.",
        subquestionsActive: [
          {
            question:
                "Bei meinem regelmäßigen Einkauf liegt der Anteil regional erzeugter Produkte bei...",
            options: [
              {
                uid: "über 60%.",
                score: 4
              },
              {
                uid: "30% - 60 %.",
                score: 3
              },
              {
                uid: "unter 30%.",
                score: 2
              }
            ],
            type: "single-select"
          },
          {
            question:
                "Das Angebot für regionale Produkte in meinem Supermarkt ist...",
            options: [
              {
                uid: "sehr gut, ich bekomme alles.",
                score: 0
              },
              {
                uid: "gut, ich bekomme das meiste.",
                score: 0
              },
              {
                uid: "in Ordnung, einiges fehlt mir.",
                score: 0
              },
              {
                uid: "nicht gut, es fehlt mir zu viel.",
                score: 0
              }
            ],
            type: "single-select"
          },
          {
            question:
                "Ich kaufe regionale Produkte, weil... (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "sie umweltschonender sind.",
                score: 0
              },
              {
                uid: "sie höhere Qualität haben.",
                score: 0
              },
              {
                uid: "dadurch die regionale Wirtschaft gefördert wird.",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ],
        subquestionsInactive: [
          {
            question:
                "Ich kaufe nicht bewusst regionale Produkte, weil es... (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "zu wenig entsprechende Angebote gibt.",
                score: 0
              },
              {
                uid: "zeitaufwändig ist.",
                score: 0
              },
              {
                uid: "oft Produkte aus dem Gewächshaus sind.",
                score: 0
              },
              {
                uid: "oft nicht klar ist, was \"regional\" ist.",
                score: 0
              },
              {
                uid: "meine Auswahl zu sehr einschränkt.",
                score: 0
              },
              {
                uid: "keinen Unterschied zu anderen Produkten macht.",
                score: 0
              },
              {
                uid: "oft nicht klar ist, was aus der Region kommt.",
                score: 0
              },
              {
                uid: "mir egal ist.",
                score: 0
              },
              {
                uid: "teurer ist.",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ]
      },
      {
        name: "Bio",
        info: "",
        relevanceQuestion: "",
        activityQuestion: "Ich kaufe Bioprodukte",
        subquestionsActive: [
          {
            question:
                "Bei meinem regelmäßigem Einkauf liegt der Anteil ökologisch erzeugter Produkte (Bioprodukte) bei...",
            options: [
              {
                uid: "über 60%.",
                score: 4
              },
              {
                uid: "30% - 60%.",
                score: 3
              },
              {
                uid: "unter 30%.",
                score: 2
              }
            ],
            type: "single-select"
          },
          {
            question:
                "Das Angebot für Bioprodukte in meinem Supermarkt ist...",
            options: [
              {
                uid: "sehr gut, ich bekomme alles.",
                score: 0
              },
              {
                uid: "gut, ich bekomme das meiste.",
                score: 0
              },
              {
                uid: "in Ordnung, einiges fehlt mir.",
                score: 0
              },
              {
                uid: "nicht gut, es fehlt mir zu viel.",
                score: 0
              }
            ],
            type: "single-select"
          },
          {
            question:
                "Ich kaufe Bioprodukte, weil sie... (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "umweltschonender sind.",
                score: 0
              },
              {
                uid: "eine höhere Qualität haben.",
                score: 0
              },
              {
                uid: "mehr Tierwohl versprechen.",
                score: 0
              },
              {
                uid: "gesünder sind.",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ],
        subquestionsInactive: [
          {
            question:
                "Ich kaufe keine Bio-Produkte, weil... (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "es kein entsprechendes Angebot in meiner Nähe gibt.",
                score: 0
              },
              {
                uid: "sie nicht besser sind als andere Produkte.",
                score: 0
              },
              {
                uid: "es aufwendiger ist.",
                score: 0
              },
              {
                uid: "sie teurer sind.",
                score: 0
              },
              {
                uid: "nicht immer Bio drin ist, wo es drauf steht.",
                score: 0
              },
              {
                uid: "sie oft nicht regional erzeugt sind.",
                score: 0
              },
              {
                uid: "sie oft nicht umweltfreundlich verpackt werden.",
                score: 0
              },
              {
                uid: "es für mich nebensächlich ist.",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ]
      },
      {
        name: "Fairtrade",
        info: "",
        relevanceQuestion: "",
        activityQuestion:
            "Ich kaufe Produkte aus zertifiziertem fairen Handel.",
        subquestionsActive: [
          {
            question:
                "Wenn Produkte im Geschäft auch aus fairem Handel verfügbar sind, kaufe ich sie...",
            options: [
              {
                uid: "häufig (in über 60% der Fälle).",
                score: 6
              },
              {
                uid: "meistens (in 60 - 30% der Fälle).",
                score: 4
              },
              {
                uid: "gelegentlich (in weniger als 30% der Fälle).",
                score: 2
              }
            ],
            type: "single-select"
          },
          {
            question:
                "Das Angebot für Produkte aus fairem Handel in meiner Region ist...",
            options: [
              {
                uid: "sehr gut, ich bekomme alles.",
                score: 0
              },
              {
                uid: "gut, ich bekomme das meiste.",
                score: 0
              },
              {
                uid: "in Ordnung, einiges fehlt mir.",
                score: 0
              },
              {
                uid: "nicht gut, mir fehlt zu viel.",
                score: 0
              }
            ],
            type: "single-select"
          },
          {
            question:
                "Ich wähle zertifizierte Produkte aus fairem Handel bei... (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "Schokolade.",
                score: 0
              },
              {
                uid: "Kakao.",
                score: 0
              },
              {
                uid: "Kleidung.",
                score: 0
              },
              {
                uid: "Obst.",
                score: 0
              },
              {
                uid: "Kaffee.",
                score: 0
              },
              {
                uid: "Tee.",
                score: 0
              },
              {
                uid: "Milch.",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ],
        subquestionsInactive: [
          {
            question:
                "Ich kaufe keine Produkte aus fairem Handel, weil... (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "es in meiner Nähe kein entsprechendes Angebot gibt.",
                score: 0
              },
              {
                uid: "sie nicht besser sind als andere Produkte.",
                score: 0
              },
              {
                uid: "es aufwendiger ist.",
                score: 0
              },
              {
                uid: "sie teurer sind.",
                score: 0
              },
              {
                uid:
                    "nicht immer fairer Handel drin ist, wo es drauf steht.",
                score: 0
              },
              {
                uid: "sie meistens nicht regional erzeugt werden.",
                score: 0
              },
              {
                uid: "es nebensächlich für mich ist.",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ]
      },
      {
        name: "Fleischkonsum",
        info: "",
        relevanceQuestion: "",
        activityQuestion:
            "Ich reduziere bewusst meinen Konsum von Fisch und Fleisch.",
        subquestionsActive: [
          {
            question:
                "Ich reduziere meinen Konsum von Fisch und Fleisch bewusst auf (durchschnittlich)...",
            options: [
              {
                uid: "keinen Verzehr.",
                score: 4
              },
              {
                uid: "maximal ein- bis zwei Portionen im Monat.",
                score: 4
              },
              {
                uid: "höchstens ein- bis zwei Portionen pro Woche.",
                score: 2
              },
              {
                uid: "drei bis fünf Portionen pro Woche.",
                score: 1
              }
            ],
            type: "single-select"
          }
        ],
        subquestionsInactive: [
          {
            question:
                "Ich reduziere den Konsum von Fisch und/oder Fleisch nicht, weil...  (Mehrfachnennungen möglich)",
            options: [
              {
                uid: "Fisch oder Fleisch für mich zu einer Mahlzeit dazugehören.",
                score: 0
              },
              {
                uid: "es mich zu sehr einschränkt.",
                score: 0
              },
              {
                uid: "es mir an entsprechenden Alternativen fehlt.",
                score: 0
              },
              {
                uid: "vegetarische Gerichte mir meist nicht schmecken.",
                score: 0
              },
              {
                uid: "es mir egal ist.",
                score: 0
              },
              {
                uid: "es sonst ungesund wäre.",
                score: 0
              },
              {
                uid: "ich beides sowieso nicht esse.",
                score: 8
              },
              {
                uid: "es mir zu schwer fällt.",
                score: 0
              }
            ],
            type: "multi-select"
          }
        ]
      }
    ]
  },
];

