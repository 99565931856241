<template>
  <div class="sdg-wrapper-container">
    
    <router-view/>

    <div class="copyright">
      © 2019 Presse- und Informationsamt der Bundesregierung
    </div>

    <div class="back-button" @click="goBack()">
      <i class="fas fa-chevron-circle-left"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SdgWrapper',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  },
}
</script>

<style lang="scss" scoped>
.sdg-wrapper-container {
  position: relative;
  min-height: 100vh;
}

.back-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 40px;
}

.copyright {
  color: rgba(black, 0.5);
  padding-bottom: 50px;
}
</style>
