<template>
  <div class="demographics-container">
    <div class="header">
      Deine Daten
    </div>
    <div class="data">
      <div class="label">
        Geschlecht
      </div>
      <div class="gender">
        <div class="male" @click="gender = 'male'" :class="{'selected': gender == 'male'}"><i class="fa fa-mars"/></div>
        <div class="male" @click="gender = 'neutral'" :class="{'selected': gender == 'neutral'}"><i class="fa fa-genderless"/></div>
        <div class="female" @click="gender = 'female'" :class="{'selected': gender == 'female'}"><i class="fa fa-venus"/></div>
      </div>
      <div class="label">
        Alter
      </div>
      <div class="age">
        <dropdown :options="ageOptions" @change="age = $event"/>
      </div>
      <div class="label">
        Landkreis
      </div>
      <div class="zip">
        <dropdown :options="regionOptions" @change="region = $event"/>
      </div>
      <div class="label">
        höchster Bildungsabschluss
      </div>
      <div class="education">
        <dropdown :options="educationOptions" @change="education = $event"/>
      </div>
    </div>

    <div class="consent">
      <label class="container">
        Hiermit stimme ich den Datenschutzbestimmungen, sowie der Auswertung meiner komplett anonymisierten Angaben zu.
        <input type="checkbox" v-model="consent">
        <span class="checkmark"></span>
      </label>
      <div class="privacy">
        <a href="https://mib-think-green.app/contact#privacy" target="_blank">Datenschutzbestimmungen</a>
      </div>
    </div>


    <div class="email" v-if="!group">
      Um Missbrauch und Verfälschung der Ergebnisse zu verhindern, gib bitte deine Emailadresse ein. Anschließend erhältst du dann einen Bestätigungslink.
      <input type="email" v-model="email" placeholder="gültige Emailadresse" class="input"/>
    </div>

    <div class="group consent">
      <p>Wenn Sie Ihre Mailadresse nicht angeben wollen oder können, klicken Sie einfach auf „Wir sind eine Gruppe“ und vergrben einen individuellen Benutzernamen.</p>
      <label class="container">
        Wir sind eine Gruppe
        <input type="checkbox" v-model="group"/>
        <span class="checkmark"></span>
      </label>
    </div>


    <div class="email" v-if="group">
      <input type="email" v-model="groupName" placeholder="Benutzer- oder Gruppennamen" class="input"/>
    </div>

    <div class="invalid">
      <span v-if="!dataValid">bitte alle Felder ausfüllen</span>
    </div>

    <div class="save-button-container">
      <div class="save-button" :class="{'inactive': !dataValid}" @click="submit()">
        Abschicken
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/utils/Dropdown.vue'

export default {
  name: 'Demographics',
  components: {
    Dropdown,
  },
  data() {
    return {
      gender:'',

      age: '',
      ageOptions: ["10 – 20", "21 – 30", "31 – 40", "41 – 50", "51 – 60", "61 – 70", "71 – 80", "81 – 90", "91 - 100"],

      region: '',
      regionOptions: ["Eichstätt", "Ingolstadt", "Pfaffenhofen", "Neuburg - Schrobenhausen", "Andere"],

      education: '',
      educationOptions: ["Kein Schulabschluss", "Haupt-/Mittelschulabschluss", "Realschule (Mittlere Reife)", "Gymnasium (Abitur)", "Hochschulabschluss", "Andere"],

      consent: false,
      group: false,

      groupName: "",

      email: '',
    }
  },
  methods: {
    submit() {
      if(!this.dataValid)
        return
      
      this.$store.commit('group', this.groupName)
      this.$store.commit('email', this.email)
      this.$store.dispatch('submitAnswers')
      this.$router.push('/thankyou')
    }
  },
  computed: {
    dataValid() {
      return this.gender && this.age && this.region && this.education && (this.emailValid || (this.group  && this.groupName.length !== 0))
    },
    emailValid() {
      const regex = /\S+@\S+\.\S+/
      return regex.test(this.email)
    }
  },
  watch: {
    gender() {
      if(this.gender) 
        this.$store.commit('gender', this.gender)
    },
    age() {
      if(this.age)
        this.$store.commit('age', this.age)
    },
    region() {
      if(this.region)
        this.$store.commit('region', this.region)
    },
    education() {
      if(this.education)
        this.$store.commit('education', this.education)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.demographics-container {
  @include gradient;
  // overflow: scroll;
  position: relative;
}

.data {
  padding: 0px 30px;
}

.gender {
  display: flex;
  font-size: 30px;
  overflow: hidden;

  div {
    border-radius: $border-radius;
    flex: 1;
    background-color: rgba($secondary, 0.1);
    margin: 10px;
    cursor: pointer;
  }

  .selected {
    background-color: $secondary;
    color: white;
  }
}

.label {
  margin-top: 20px;
  text-align: left;
  color: rgba($secondary, 0.6);
}
.label:first-child {
  margin-top: 0;
}

.consent {
  text-align: left;
  margin: 20px 0px;
  padding: 0px 30px;
}

.email {
  text-align: left;
  padding: 0px 30px;

  input {
    border: 1px solid $secondary;
  }
}

.invalid {
  color: $red;
  margin-top: 30px;
}

.save-button-container {
  text-align: right;
  width: 100%;

  .save-button {
    padding: 20px;
    background-color: $secondary;
    color: white;
    font-weight: 700;
  }

  .inactive {
    cursor: not-allowed;
    background-color: grey;
    color: rgba(white, 0.2);
  }
}


.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  // font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
