<template>
  <div class="verify-container">
    <h2>Verifizierung</h2>

    <div v-if="validationInProgress">
      <i class="fa fa-spinner fa-spin"/>
    </div>

    <div class="valid" v-if="validated">
      <p>
        Deine Daten wurden verifiziert, vielen Dank!
      </p>
      Lernen Sie mehr über Nachhaltigkeit und wie Sie beitragen können:<br/>
      <router-link :to="{ name: 'tipps' }">Nachhaltigkeitstipps</router-link>
    </div>

    <div v-if="err" class="error">
      Es ist ein Fehler aufgetreten.<br/>
      Bitte versuche es erneut und <router-link :to="{ name: 'contact' }">kontaktiere uns</router-link> falls das Problem weiterhin besteht.
      <p>
        {{ err }}
      </p>
    </div>
  
  </div>
</template>

<script>
import backendService from '@/services/backend-service.js'

export default {
  name: 'Verify',
  props: ['uid'],
  data() {
    return {
      err: null,
      validated: false,
      validationInProgress: false,
    }
  },
  async created() {
    if(!this.uid || this.uid == '') {
      this.err = 'invalid UID, cannot verify.'
      return
    }

    this.validationInProgress = true

    await backendService.validateEmail(this.uid)
      .then(() => this.validated = true)
      .catch(err => this.err = err)

    this.validationInProgress = false
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.verify-container {
  height: 100%;
  padding: 30px;
  padding-top: 50px;
  text-align: left;
}
</style>