<template>
  <div class="question-container">

    <div class="question">
      
      <div v-if="showRelevanceQuestion">
        <div class="relevance-header">
          {{ question.relevanceQuestion }}
        </div>
        <div class="relevance">
          <div class="option green" @click="relevance = 'high'" :class="{'not-selected' : relevance != 'high'}">
            <i class="far fa-thumbs-up"/>
          </div>
          <div class="option red" @click="relevance = 'low'" :class="{'not-selected' : relevance != 'low'}">
            <i class="far fa-thumbs-down"/>
          </div>
        </div>
      </div>

      <div v-else-if="showActivityQuestion" class="activity-question">
        <div class="activity-header">
          {{ question.activityQuestion }}
        </div>
        <div class="activity">
          <div class="option green" @click="activity = 'yes'"  :class="{'not-selected' : activity != 'yes' }">
            <i class="far fa-thumbs-up"/>
          </div>
          <div class="option red" @click="activity = 'no'"  :class="{'not-selected' : activity != 'no' }">
            <i class="far fa-thumbs-down"/>
          </div>
        </div>
      </div>

      <div v-else class="subquestion">
        <sub-question :question="currentSubquestion" @next="nextSubquestion"/>
      </div>
    </div>

    <div v-if="activity" class="undo-activity-selection button" @click="back()">
      Zurück
    </div>

  </div>
</template>

<script>
import SubQuestion from '@/components/SubQuestion.vue'
export default {
  name: 'Question',
  props: ['question', 'section'],
  components: {
    SubQuestion,
  },
  data() {
    return {
      relevance: '',
      activity: '',

      subQuestionIndex: 0,

      selectedAnswers: [],
      showInactiveAfterLastQuestion: false
    }
  },
  methods: {
    nextSubquestion(answers) {
      this.$store.commit('addAnswer', {
        section: this.section,
        measure: this.question.name,
        relevance: this.relevance,
        active: this.activity,
        question: this.currentSubquestion.question,
        answer: answers.map(a => a.uid),
        score: answers.reduce((score, a) => score += a.score, 0), 
      })

      answers.map((a) => {
        if (a.showInactiveAfterLast) {
          this.showInactiveAfterLastQuestion = true
        }
      })

      if(this.subQuestionIndex < this.subQuestions.length-1) {
        this.subQuestionIndex++
        return
      } else if (this.showInactiveAfterLastQuestion && this.activity !== 'no') {
        this.activity = 'no'
        this.subQuestionIndex = 0
        return
      }

      this.subQuestionIndex = 0
      this.relevance = ''
      this.activity = ''
      this.showInactiveAfterLastQuestion = false
      this.$emit('next')
    },
    back() {
      // console.log(this.subQuestionIndex)
      if (this.subQuestionIndex == 0) {
        this.activity = ''
      } else {
        this.subQuestionIndex--
      }
    }

  },
  computed: {
    showRelevanceQuestion() {
      return this.question.relevanceQuestion != '' && this.relevance == ''
    },
    showActivityQuestion() {
      return this.activity == ''
    },
    subQuestions() {
      return this.activity == 'yes' ? this.question.subquestionsActive : this.question.subquestionsInactive
    },
    currentSubquestion() {
      if(this.subQuestions && this.subQuestions.length > 0)
        return this.subQuestions[this.subQuestionIndex]
      return {}
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.question-container {
}
.question {
  border-radius: $border-radius;
  @include shadow;
  background-color: white;
}
.relevance-header, .activity-header {
  padding: 20px;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  border-bottom: 2px solid $secondary;
}

.not-selected {
  color: rgba(0, 0, 0, 0.6);
}

.activity-question {
  // border-top: 2px solid $primary;
}

.relevance, .activity {
  display: flex;

  .option {
    flex: 1;
    padding: 30px;
    font-size: 30px;
    cursor: pointer;
  }
}

.undo-activity-selection {
  margin: 30px auto;
  border-radius: $border-radius;
  max-width: 60px;
}

.undo-activity-selection:hover {
  color: $primary;
  background-color: $secondary;
}


.option {
  cursor: pointer;
}
</style>
