<template>
  <div class="tipps-container">
    <div class="header">
      Nachhaltigkeitstipps
    </div>
    <div class="separator"/>
    <div class="content">
      Sie möchten noch mehr für Nachhaltigkeit tun, wissen aber nicht, wo Sie anfangen sollen? Dann sind Sie hier genau
      richtig! Wir haben Tipps und Tricks für einen nachhaltigeren Alltag gesammelt. <br/>
      An den Anfang möchten wir drei goldene Regeln stellen:
      <ul>
        <li>
          Kleine Änderungen, die zur Gewohnheit werden, sind effektiver als eine große Aktion.
        </li>
        <li>
          Bedenken Sie den Rebound-Effekt: nach einer nachhaltigkeitsorientierten Tat (z.B. mit dem Fahrrad zum
          Einkaufen fahren) sollte eine „Belohnung“ die vorherige Maßnahme nicht wieder zunichtemachen (z.B. 1 Kilo
          Süßigkeiten in Plastikfolie kaufen).
        </li>
        <li>
          Haben Sie Geduld mit sich selbst, wenn etwas nicht umgehend klappt oder Sie mal weniger nachhaltig waren!
        </li>
      </ul>

      <div class="category">
        <h2 id="Konsum">Konsum</h2>

        <div class="tipp">
          <div class="tipp-header" @click="regional = !regional">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : regional }"/>
            Regionale Produkte <i class="fa fa-tractor"/>
          </div>
          <div class="tipp-content" v-show="regional">
            Regionale Produkte sichern lokale Arbeitsplätze und haben meistens eine bessere Klimabilanz als weitgereiste
            Importware.
            <ul>
              <li>Der Begriff regional ist nicht gesetzlich geschützt – einige Supermärkte geben aber den Ursprung
                genauer
                an, z.B. das Bundesland.
              </li>
              <li>Bei einem lokalen Markt kann man Verkäufer und Verkäuferinnen fragen, woher die Produkte kommen.</li>
              <li>Auch landwirtschaftliche Hofläden aus der näheren Umgebung bieten Produkte aus eigener Produktion an,
                die manchmal auch unverpackt oder in Mehrwegbehältnissen (z.B. Pfandglas bei Honig) zu haben sind.
              </li>
            </ul>
          </div>
        </div>

        <div class="tipp">
          <div class="tipp-header" @click="fairtrade = !fairtrade">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : fairtrade }"/>
            Produkte aus Fairem Handel <i class="fa fa-balance-scale"/>
          </div>
          <div class="tipp-content" v-show="fairtrade">
            Gerade bei Produkten aus so genannten Entwicklungsländern ist nicht immer klar, unter welchen Bedingungen
            sie
            erzeugt wurden.
            Bei Produkten aus zertifiziertem fairem Handel ist garantiert, dass die Erzeuger einen angemessenen Preis
            bekommen und die Produkte nicht durch Kinderarbeit erzeugt wurden.
            <ul>
              <li>In sogenannten Eine-Welt-Läden gibt es ausschließlich fair hergestellte Produkte.</li>
              <li>Faire Produkte sind durch verschiedene Siegel gekennzeichnet, weitere Informationen finden Sie hier:
                <a
                    href="https://www.nachhaltiger-warenkorb.de/siegel/fairtrade/" target="_blank">https://www.nachhaltiger-warenkorb.de/siegel/fairtrade/</a>
              </li>
              <li>Neben Lebensmitteln wie Kaffee, Schokolade und Tee gibt es noch einige weitere Produkte, die Sie aus
                fairem Handel kaufen können: z. B. Blumen, Kleidung oder Sportbälle.
              </li>
            </ul>
          </div>
        </div>

        <div class="tipp">
          <div class="tipp-header" @click="organic = !organic">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : organic }"/>
            Bioprodukte <i class="fa fa-seedling"/>
          </div>
          <div class="tipp-content" v-show="organic">
            Auch wenn immer wieder daran gezweifelt wird: Wo zertifiziertes Bio draufsteht,
            ist auch Bio drin, das wird durch unabhängige Stellen geprüft.
            Neben mehr Tierwohl und großen Vorteilen für die Natur, sorgt die ökologische Anbauweise für mehr
            Arbeitsplätze.

            <ul>
              <li>Es gibt unterschiedliche Bio-Siegel, die jeweils verschieden strenge Anforderungen haben, weitere
                Infos
                finden Sie u.a. hier: <a href="https://www.oekolandbau.de/bio-siegel/" target="_blank">https://www.oekolandbau.de/bio-siegel/</a>
              </li>
              <li>Durch das Verbot von Pflanzenschutzmitteln helfen Bioprodukte, die Insekten- und übrige Tierwelt zu
                schonen, aber auch die Biodiversität von z.B. Wildpflanzen.
              </li>
              <li>Auf Bio-Produkten wird automatisch auch immer die Herkunftsregion angegeben.</li>
              <li>Durch das steigende Bio-Angebot in Supermärkten und Discountern ist es einfacher und bezahlbarer
                geworden, Bio-Produkte zu kaufen.
              </li>
              <li>Nicht nur Lebensmittel können biologisch erzeugt sein, auch Kosmetik und Kleidung gibt es aus
                Öko-Anbau
                und mit entsprechenden Siegeln zertifiziert.
              </li>
            </ul>
          </div>
        </div>

        <div class="tipp">
          <div class="tipp-header" @click="microplastic = !microplastic">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : microplastic }"/>
            Mikroplastik vermeiden <i class="fa fa-microscope"/>
          </div>
          <div class="tipp-content" v-show="microplastic">
            Mikroplastik gelangt mehr und mehr in die Umwelt. Die Folgen sind bisher noch nicht absehbar.
            <ul>
              <li>Die Smartphone-App <a href="https://www.codecheck.info/" target="_blank">„Codecheck“</a> informiert
                durch einen Barcode-Scan über alle Inhaltsstoffe,
                ob und welche Art von z.B. Mikroplastik enthalten ist, und schlägt Alternativen vor.
              </li>
              <li>Zertifizierte Naturkosmetik enthält kein Mikroplastik. Darauf sollte man achten.</li>
              <li>Grundsätzlich gilt: Plastik gehört in die Tonne, nicht in die Natur! Helfen Sie doch vielleicht bei
                einer regionalen Sammelaktion mit, die Natur von Plastik und anderem Müll zu befreien.
              </li>
              <li>Das meiste Mikroplastik entsteht durch Reifenabrieb, achten Sie daher bei Ihren Autoreifen besonders
                auf
                hohe Haltbarkeit,
                gute Qualität und eine vorausschauende Fahrweise. Autoreifen sollten der Witterung angepasst sein (z.B.
                Sommer-/Winterbereifung) und können auch einmal runderneuert werden.
              </li>
              <li>Vermeiden Sie Kleidung aus Polyester, Acryl und Elasthan – beim Waschen lösen sich viele kleine
                Plastikteile, die dann im Abwasser landen.
              </li>
            </ul>
          </div>
        </div>

        <div class="tipp">
          <div class="tipp-header" @click="trash = !trash">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : trash }"/>
            Verpackungsmüll <i class="fa fa-trash"/>
          </div>
          <div class="tipp-content" v-show="trash">

            Kunststoffe sind vielseitig einsetzbare Werkstoffe, die z.B. im Leichtbau sehr wertvoll sind.
            Um sie als Verpackungen zu verschwenden, sind sie viel zu wertig.

            <ul>
              <li>
                Es gibt Geschäfte, die ihre Waren unverpackt anbieten. So können Sie zudem selbst entscheiden, welche
                Mengen Sie von einem Produkt kaufen möchten.
              </li>
              <li>
                Immer mehr Geschäfte, wie z.B. Cafés, Bäckereien und Metzgereien, geben ihre Waren auch in mitgebrachte
                Becher, Gefäße oder Taschen.
                ToGo-Becher oder Plastikbeutel für Obst, die meist gleich weggeworfen werden, können dadurch reduziert
                werden.
              </li>
              <li>
                Auch immer mehr Geschäfte, z. B. Metzgereien, geben dir ihre Waren
                in mitgebrachte Gefäße. Plastikbeutel für Obst sind überflüssig
                und werden oft daheim auch sofort weggeworfen.
              </li>
              <li>
                Dinge selbst herzustellen, kann Verpackungsmüll sparen, z.B. bei Reinigungsmitteln
              </li>
              <li>
                Statt in Flaschen abgefülltes Wasser zu trinken, kann man auf Leitungswasser umsteigen – übrigens das am
                besten kontrollierte Lebensmittel in Deutschland.
              </li>
            </ul>
          </div>
        </div>

        <div class="tipp">
          <div class="tipp-header" @click="sharing = !sharing">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : sharing }"/>
            Sharing-Angebote <i class="fa fa-handshake"/>
          </div>
          <div class="tipp-content" v-show="sharing">
            Auch für die Herstellung von Produkten werden viele Ressourcen verbraucht. Indem man Dinge mit anderen teilt
            und mehrfach- oder wiederverwendet, kann man hier Geld und Energie einsparen.
            <ul>
              <li>Dinge, die man selten benutzt, wie z.B. Werkzeug, kann man sich eventuell ausleihen.
                Dafür gibt es auch Apps. Eine kleine Auswahl von Plattformen finden sich <a
                    href="https://utopia.de/bestenlisten/sharing-economy-plattformen-teilen-verleihen/" target="_blank">
                  hier </a>
              </li>
              <li>
                Second-Hand-Kleidung ist deutlich besser als ihr Ruf.
              </li>
              <li>Autos stehen im Schnitt 23 Stunden nur herum und besetzen den knappen Raum, durch Car-Sharing bezahlt
                man dagegen nur dann für ein Auto, wenn man es wirklich braucht.
              </li>
              <li>Die Foodsharing-Bewegung ermöglicht es, Lebensmittel an andere weiterzugeben, anstatt sie wegzuwerfen.
              </li>
              <li>Durch Mitfahrgelegenheiten kommt man günstig von A nach B. Haben Sie schon einmal über eine
                Fahrgemeinschaft für den Weg zur Arbeit nachgedacht?
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="category">
        <h2 id="Mobilitaet">Mobilität</h2>

        <div class="tipp">
          <div class="tipp-header" @click="opnv = !opnv">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : opnv }"/>
            ÖPNV <i class="fa fa-bus"/>
          </div>
          <div class="tipp-content" v-show="opnv">
            Durch eine höhere Anzahl von Mitfahrerinnen oder Mitfahrern ist der ÖPNV häufig umweltfreundlicher als das
            eigene Auto.
            Außerdem spart man sich Kosten für Versicherung, Sprit, Reparaturen etc.
            <ul>
              <li>
                Für Fernreisen bietet sich die Bahn an, auch Fernbusse bedienen viele Ziele in Deutschland.
              </li>
              <li>
                Kosten für den öffentlichen Nahverkehr gleichen sich häufig durch die wegfallenden Kosten für den
                eigenen Pkw aus.
              </li>
              <li>
                Die Zeit in öffentlichen Verkehrsmitteln kann man, anders als im Auto, für andere Dinge nutzen.
              </li>
              <li>
                Für Einkäufe oder Transporte können ein Lastenrad oder ein Fahrradanhänger bzw. Fahrradtaschen das Auto
                ersetzen
              </li>
              <li>
                Wird doch einmal ein Auto benötigt, könnte Car-Sharing eine gute Alternative zum eigenen Pkw oder zum
                Zweit-PKW sein: man bezahlt nur für die Zeit, die man ein Auto nutzt.
              </li>
            </ul>
          </div>
        </div>

        <div class="tipp">
          <div class="tipp-header" @click="mobil = !mobil">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : mobil }"/>
            Mobil aus eigener Kraft <i class="fa fa-biking"/>
          </div>
          <div class="tipp-content" v-show="mobil">
            Wenn Sie sich mit eigener Muskelkraft fortbewegen, tun Sie nicht nur der Umwelt und Ihrem Geldbeutel einen
            Gefallen, sondern auch Ihrer Gesundheit.
            <ul>
              <li>Bei Kurzstrecken das Auto öfter mal stehen lassen, gerade in der Stadt ist man so oft schneller – und
                spart sich die Parkplatzsuche sowie die Parkgebühren.
              </li>
              <li>Für größere Transporte, wie z.B. den Wocheneinkauf, bieten sich Fahrradtaschen oder ein Lastenrad an.
              </li>
              <li>Spezielle Kleidung hilft auch bei Regen, als Fahrradfahrer oder -fahrerin trocken zu bleiben.</li>
              <li>Viele Städte bieten mittlerweile Mietfahrräder an, so sind Sie auch an anderen Orten mobil.</li>
              <li>Tretroller oder Falträder können meist relativ problemlos (und kostenlos) in Bus und Bahn mitgenommen
                werden, so kommt man trotzdem schnell und umweltfreundlich von Tür zu Tür.
              </li>
            </ul>
          </div>
        </div>

        <div class="tipp">
          <div class="tipp-header" @click="flying = !flying">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : flying }"/>
            Flugreisen vermeiden <i class="fa fa-plane-arrival"/>
          </div>
          <div class="tipp-content" v-show="flying">
            <ul>
              <li>Beim Fliegen wird viel Energie verbraucht, schon, um überhaupt abheben zu können. Dabei entstehen
                Treibhausgase, die sich oft vermeiden ließen: Für Fernreisen bietet sich die Bahn an, auch Fernbusse
                bedienen viele Ziele in Deutschland.
              </li>
              <li>Inlandsflüge sind unnötig: Auch mit Bus und Bahn kommt man überall in Deutschland hin.
                Die Zeitersparnis beim Fliegen geht durch die An- und Abreise zum Flughafen meist verloren. Bedenken Sie
                deshalb immer die Gesamtreisezeit von A nach B.
              </li>
              <li>Der Weg ist das Ziel: Statt die Anreise zum Urlaubsort als Verschwendung zu sehen, kann man den Weg
                dorthin nutzen, z.B. um zu arbeiten oder um abzuschalten.
              </li>
              <li>Wenn ein Flug sich gar nicht vermeiden lässt, gibt es eine Vielzahl an Anbietern, die entstandene
                Treibhausgase durch Projekte wie z. B. Baumpflanzungen für einen verträglichen Preis ausgleichen.
                Leider sind nicht alle Anbieter seriös, hinterfragen lohnt sich!
              </li>
            </ul>
          </div>
        </div>

        <div class="tipp">
          <div class="tipp-header" @click="fuel = !fuel">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : fuel }"/>
            Spritverbrauch reduzieren <i class="fa fa-gas-pump"/>
          </div>
          <div class="tipp-content" v-show="fuel">
            Gerade in ländlichen Gegenden geht es manchmal (noch) nicht ohne eigenes Auto. Doch auch dann gibt es
            Möglichkeiten, sich nachhaltiger zu verhalten.
            <ul>
              <li>Beim Kauf eines Neuwagens sollte Wert auf einen geringen Spritverbrauch gelegt werden. Oft lohnt sich
                die Anschaffung eines elektrisch betriebenen Fahrzeuges.
              </li>
              <li>Auch die Fahrweise hat Einfluss auf den Spritverbrauch: Wer im niedrigen Drehzahlbereich fährt, spart
                bis zu 25% Kraftstoff. Automobilvereine bieten Fahrtrainings für eine umweltbewusste Fahrweise an.
              </li>
              <li>Der Kauf eines gebrauchten Fahrzeugs schont Ressourcen und den Geldbeutel. Hier bitte trotzdem auf ein
                Fahrzeug mit geringem Spritverbrauch achten.
              </li>
              <li>Auch bei Elektrofahrzeugen können Ressourcen gespart werden, z.B. durch eine dem persönlichen
                Fahrverhalten angepasste Auswahl von Fahrzeuggröße und Akkureichweite sowie durch gesteuertes,
                stromsparendes Aufladen.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="category">
        <h2 id="Abfall">Abfall</h2>

        <div class="tipp">
          <div class="tipp-header" @click="trash2 = !trash2">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : trash2 }"/>
            Verpackungsmüll <i class="fa fa-trash"/>
          </div>
          <div class="tipp-content" v-show="trash2">

            Kunststoffe sind vielseitig einsetzbare Werkstoffe, die z.B. im Leichtbau sehr wertvoll sind.
            Um sie als Verpackungen zu verschwenden, sind sie viel zu wertig.

            <ul>
              <li>
                Es gibt Geschäfte, die ihre Waren unverpackt anbieten. So können Sie zudem selbst entscheiden, welche
                Mengen Sie von einem Produkt kaufen möchten.
              </li>
              <li>
                Immer mehr Geschäfte, wie z.B. Cafés, Bäckereien und Metzgereien, geben ihre Waren auch in mitgebrachte
                Becher, Gefäße oder Taschen.
                ToGo-Becher oder Plastikbeutel für Obst, die meist gleich weggeworfen werden, können dadurch reduziert
                werden.
              </li>
              <li>
                Auch immer mehr Geschäfte, z. B. Metzgereien, geben dir ihre Waren
                in mitgebrachte Gefäße. Plastikbeutel für Obst sind überflüssig
                und werden oft daheim auch sofort weggeworfen.
              </li>
              <li>
                Dinge selbst herzustellen, kann Verpackungsmüll sparen, z.B. bei Reinigungsmitteln
              </li>
              <li>
                Statt in Flaschen abgefülltes Wasser zu trinken, kann man auf Leitungswasser umsteigen – übrigens das am
                besten kontrollierte Lebensmittel in Deutschland.
              </li>
            </ul>
          </div>
        </div>

        <div class="tipp">
          <div class="tipp-header" @click="microplastic2 = !microplastic2">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : microplastic2 }"/>
            Mikroplastik vermeiden <i class="fa fa-microscope"/>
          </div>
          <div class="tipp-content" v-show="microplastic2">
            Mikroplastik gelangt mehr und mehr in die Umwelt. Die Folgen sind bisher noch nicht absehbar.
            <ul>
              <li>Die Smartphone-App <a href="https://www.codecheck.info/" target="_blank">„Codecheck“</a> informiert
                durch einen Barcode-Scan über alle Inhaltsstoffe,
                ob und welche Art von z.B. Mikroplastik enthalten ist, und schlägt Alternativen vor.
              </li>
              <li>Zertifizierte Naturkosmetik enthält kein Mikroplastik. Darauf sollte man achten.</li>
              <li>Grundsätzlich gilt: Plastik gehört in die Tonne, nicht in die Natur! Helfen Sie doch vielleicht bei
                einer regionalen Sammelaktion mit, die Natur von Plastik und anderem Müll zu befreien.
              </li>
              <li>Das meiste Mikroplastik entsteht durch Reifenabrieb, achten Sie daher bei Ihren Autoreifen besonders
                auf
                hohe Haltbarkeit,
                gute Qualität und eine vorausschauende Fahrweise. Autoreifen sollten der Witterung angepasst sein (z.B.
                Sommer-/Winterbereifung) und können auch einmal runderneuert werden.
              </li>
              <li>Vermeiden Sie Kleidung aus Polyester, Acryl und Elasthan – beim Waschen lösen sich viele kleine
                Plastikteile, die dann im Abwasser landen.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="category">
        <h2 id="Energie">Energie</h2>

        <div class="tipp">
          <div class="tipp-header" @click="renewables = !renewables">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : renewables }"/>
            Strom aus erneuerbarer Energie <i class="fa fa-solar-panel"/>
          </div>
          <div class="tipp-content" v-show="renewables">
            Die Zeiten, in denen Strom aus erneuerbaren Energien teuer war, sind vorbei.

            <ul>
              <li>Empfehlenswert ist, 100% Öko-Strom zu beziehen und einen Mix mit z.B. Kohlestrom zu vermeiden.</li>
              <li>Strom aus erneuerbaren Energien wird häufig auch von regionalen Erzeugern oder Stadtwerken angeboten.
              </li>
              <li>In vielen Fällen lohnt sich eine eigene Photovoltaikanlage auf dem Dach.</li>
              <li>
                Am besten ist immer noch Strom, der gar nicht verbraucht wird:
                <ul>
                  <li>Energiesparlampen (z.B.: LED) mit geringerem Verbrauch und höherer Lebensdauer bevorzugen. Das
                    spart
                    auf Dauer auch noch Geld.
                  </li>
                  <li>Geräte, die nicht benötigt werden, nicht im Standby-Modus eingeschaltet lassen, sondern ganz von
                    der
                    Stromversorgung trennen.
                    Geräte möglichst lange nutzen und beim Neukauf auf geringen Verbrauch achten.
                  </li>
                  <li>Nur Geräte kaufen, die man wirklich benötigt.</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div class="tipp">
          <div class="tipp-header" @click="renovation = !renovation">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : renovation }"/>
            Ökologische Sanierung <i class="fa fa-home"/>
          </div>
          <div class="tipp-content" v-show="renovation">
            Durch eine ökologische Sanierung des Eigenheims können große Mengen Heizenergie oder Strom eingespart werden
            –
            das schont das Klima und das Bankkonto.
            <ul>
              <li>Überprüfen Sie die Dichtungen von Fenstern und Türen. Poröse Dichtungen können einfach und günstig
                ausgetauscht werden.
              </li>
              <li>Heiz-Checks und Förderratgeber findet man unter anderem unter <a href="https://www.co2online.de"
                                                                                   target="_blank">www.co2online.de</a>
                und <a href="http://www.heizspiegel.de" target="_blank">www.heizspiegel.de</a>.
                Auch ehrenamtliche Initiativen wie Bürgerenergiegenossenschaften oder die eigene Kommune unterstützen in
                vielen Städten und Landkreisen, Hausbesitzerinnen und Hausbesitzer oder auch Mieter und Mieterinnen mit
                Energiesparberatungen.
              </li>
              <li>
                Auch beim Heizen gilt: Sparen hilft. Hier einige Beispiele, wie es funktionieren kann.
                <ul>
                  <li>Heizen Sie Räume nicht unnötig auf. Im Schlafzimmer halten wir uns beispielsweise tagsüber selten
                    auf.
                  </li>
                  <li>18 - 21 °C reichen aus.</li>
                  <li>Es hilft nicht, eine höhere Stufe am Thermostatregler der Heizung einzustellen, dadurch steigt die
                    Temperatur nicht schneller, sondern nur höher.
                  </li>
                  <li>Beim kurzen Stoß- oder Querlüften geht weniger Wärme verloren als beim dauerhaften Kippen der
                    Fenster.
                  </li>
                  <li>Drehen Sie die Heizung ab bzw. regeln sie herunter, wenn Sie sich länger nicht in Räumen aufhalten
                    z.B. nachts oder im Urlaub.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div class="tipp">
          <div class="tipp-header" @click="fuel2 = !fuel2">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : fuel2 }"/>
            Spritverbrauch reduzieren <i class="fa fa-gas-pump"/>
          </div>
          <div class="tipp-content" v-show="fuel2">
            Gerade in ländlichen Gegenden geht es manchmal (noch) nicht ohne eigenes Auto. Doch auch dann gibt es
            Möglichkeiten, sich nachhaltiger zu verhalten.
            <ul>
              <li>Beim Kauf eines Neuwagens sollte Wert auf einen geringen Spritverbrauch gelegt werden. Oft lohnt sich
                die Anschaffung eines elektrisch betriebenen Fahrzeuges.
              </li>
              <li>Auch die Fahrweise hat Einfluss auf den Spritverbrauch: Wer im niedrigen Drehzahlbereich fährt, spart
                bis zu 25% Kraftstoff. Automobilvereine bieten Fahrtrainings für eine umweltbewusste Fahrweise an.
              </li>
              <li>Der Kauf eines gebrauchten Fahrzeugs schont Ressourcen und den Geldbeutel. Hier bitte trotzdem auf ein
                Fahrzeug mit geringem Spritverbrauch achten.
              </li>
              <li>Auch bei Elektrofahrzeugen können Ressourcen gespart werden, z.B. durch eine dem persönlichen
                Fahrverhalten angepasste Auswahl von Fahrzeuggröße und Akkureichweite sowie durch gesteuertes,
                stromsparendes Aufladen.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="category">
        <h2 id="Ernaehrung">Ernährung</h2>

        <div class="tipp">
          <div class="tipp-header" @click="meat = !meat">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : meat }"/>
            Reduktion Fleisch & Fisch <i class="fa fa-carrot"/>
          </div>
          <div class="tipp-content" v-show="meat">
            Da die Erzeugung der Futtermittel für die Tierhaltung viele Ressourcen (wie z.B. Wasser) verbraucht und
            große
            Flächen für den Anbau benötigt, ist es eine wichtige Nachhaltigkeitsmaßnahme, weniger Fleisch zu verzehren.
            In aquatischen Ökosystemen sind häufig Überfischung und umweltzerstörende Fangmethoden problematisch,
            weshalb
            auch beim Konsum von Fisch Mäßigung empfohlen wird.
            <ul>
              <li>Auch aus gesundheitlichen Gründen ist es sinnvoll, den Fleischverzehr zu reduzieren und zum
                traditionellen Sonntagsbraten – also z.B. nur 1x in der Woche Fleisch - zurückzukehren.
              </li>
              <li>Wenn Fleisch auf den Tisch kommt, sollte es möglichst biozertifiziert sein, dieses ist meist deutlich
                weniger mit Antibiotika-Rückständen belastet und unter tier- und umweltfreundlicheren Bedingungen
                erzeugt.
              </li>
              <li>Inzwischen ist das Angebot an vegetarischen und auch veganen Lebensmitteln enorm: Wer weniger Fleisch
                isst, kann ganz neue leckere pflanzliche Lebensmittel entdecken.
              </li>
              <li>Beim Kauf von Fisch sollte man darauf achten, keine überfischten Arten auszuwählen. Eine Übersicht
                finden Sie z. B. hier: <a href="https://www.greenpeace.de/themen/meere/app-fuer-nachhaltigen-fisch"
                                          target="_blank">https://www.greenpeace.de/themen/meere/app-fuer-nachhaltigen-fisch</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="tipp">
          <div class="tipp-header" @click="regional2 = !regional2">
            <i class="fa fa-chevron-right expand-icon" :class="{ expanded : regional2 }"/>
            Regionale Produkte <i class="fa fa-tractor"/>
          </div>
          <div class="tipp-content" v-show="regional2">
            Regionale Produkte sichern lokale Arbeitsplätze und haben meistens eine bessere Klimabilanz als weitgereiste
            Importware.
            <ul>
              <li>Der Begriff regional ist nicht gesetzlich geschützt – einige Supermärkte geben aber den Ursprung
                genauer
                an, z.B. das Bundesland.
              </li>
              <li>Bei einem lokalen Markt kann man Verkäufer und Verkäuferinnen fragen, woher die Produkte kommen.</li>
              <li>Auch landwirtschaftliche Hofläden aus der näheren Umgebung bieten Produkte aus eigener Produktion an,
                die manchmal auch unverpackt oder in Mehrwegbehältnissen (z.B. Pfandglas bei Honig) zu haben sind.
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Tipps',
  data() {
    return {
      opnv: false,
      trash: false,
      trash2: false,
      renewables: false,
      sharing: false,
      regional: false,
      regional2: false,
      microplastic: false,
      microplastic2: false,
      mobil: false,
      renovation: false,
      flying: false,
      organic: false,
      fairtrade: false,
      meat: false,
      fuel: false,
      fuel2: false,
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.tipps-container {
  height: 100%;
  @include gradient;
}

.content {
  min-height: 100vh;

  a {
    color: $secondary;
  }
}

.category {
  margin-top: 40px;
}

.tipp {
  margin: 10px 0px;

  .tipp-header {
    font-weight: bold;
    font-size: 16pt;
    cursor: pointer;

    i {
      font-size: 12pt;
    }
  }
}

.expand-icon {
  transition: transform 0.2s;
}

.expanded {
  transform: rotate(90deg);
}
</style>
