<template>
  <div class="contact-view-container">
    <div class="header">
      Feedback und weitere Informationen unter: 
    </div>
    <div class="contact">
      <a href="mailto:MenschInBewegung@thi.de">MenschInBewegung@thi.de</a>
    </div>

    <div class="logos">
      <a href="https://mensch-in-bewegung.info/" target="_blank">
        <img src="../assets/logos/mib.svg"/>
      </a>
      <img src="../assets/logos/thi.png"/>
      <img src="../assets/logos/logo2.png"/>
    </div>

    <div class="imprint">
      <p>Impressum</p>
      <pre>
Technische Hochschule Ingolstadt
Esplanade 10, 85049 Ingolstadt 

Telefon: + 49 841 / 9348-0
Fax: + 49 841 / 9348-2000
E-Mail: info@thi.de

Die Technische Hochschule Ingolstadt ist eine Körperschaft des Öffentlichen Rechts. Sie wird durch ihren Präsidenten Prof. Dr. Walter Schober gesetzlich vertreten. 

Zuständige Aufsichtsbehörde: Bayerisches Staatsministerium für Wissenschaft und Kunst, 80333 München
Umsatzsteuer-Identifikationsnummer gem. § 27 Umsatzsteuergesetz (UStG): DE 234419796
Gesamtverantwortlichkeit für den Inhalt: Prof. Dr. Walter Schober, Präsident
Projektkoordination und Redaktion der allgemeinen Inhalte: Thomas Schmidt
Anregungen und Kritik: MenschInBewegung@thi.de

Haftungsausschluss: 
Die Inhalte unserer Applikation wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte innerhalb der Applikation nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.

Haftung für Links
Mit Urteil vom 12. Mai 1998 hat das Landgericht Hamburg entschieden, dass mit Anbringung eines Verweises die Inhalte der verwiesenen Seite ggf. mit zu verantworten sind. Dies kann - so das Landgericht - ausschließlich dadurch verhindert werden, dass man sich von diesen Inhalten ausdrücklich distanziert. Wir bemühen uns um Sorgfalt bei der Auswahl der Verweise, haben aber selbst keinerlei Einfluss auf Inhalte von gelinkten externen Seiten. Die Technische Hochschule Ingolstadt erklärt, dass sie sich ausdrücklich von allen Inhalten sämtlicher extern verlinkter Seiten distanziert. Neben Links auf externe Websites, zu denen von unserer Applikation verwiesen wird, distanziert sich die Technische Hochschule Ingolstadt auch von allen Inhalten, die auf eigenen Servern bereitgestellt werden (Web-Hosting). Darunter fallen insbesondere die privaten Homepages von Studierenden und Mitarbeitern der Technischen Hochschule Ingolstadt.

Urheberrecht
Die durch die Applikationsbetreiber erstellten Inhalte und Werke innerhalb dieser Applikation unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Inhalte sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte dieser Applikation nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
      </pre>
    </div>

    <div class="privacy" id="privacy">
      <p><a href="#privacy">Datenschutzhinweis</a></p>
      <pre>
Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (Europäische Datenschutzgrundverordnung - DSGVO, Bayerisches Datenschutzgesetz – Bay DSG). 
Entsprechend dieser Bestimmungen informieren wir Sie in diesen Datenschutzinformationen über die für Sie relevanten Aspekte der Verarbeitung Ihrer Daten im Rahmen der Nutzung der Applikation „THInk Green“.
Wir passen unsere Datenschutzinformationen aktuellen Entwicklungen gemäß an und informieren Sie regelmäßig datenschutzkonform über vorgenommene Anpassungen. 

Name und Kontaktdaten des Verantwortlichen (Art. 13 Abs. 1 lit a DSGVO) 

Technische Hochschule Ingolstadt (THI)
Esplanade 10, 85049 Ingolstadt
vertreten durch den Präsidenten Prof. Dr. Walter Schober 

Tel. +49 841 9348-0
Fax +49 841 9348-2000
Email info@thi.de 

Kontaktdaten des Datenschutzbeauftragten (Art. 13 Abs. 1 lit b DSGVO) 

Technische Hochschule Ingolstadt
Esplanade 10, 85049 Ingolstadt 
Tel. +49 841 9348-1234
Email datenschutz@thi.de 


Zweck und Rechtsgrundlage der Datenverarbeitung (Art. 13 Abs. 1 lit c DSGVO) 

Wir nutzen die erhobenen persönlichen Daten, um genauere Informationen zur Einstellung der Bewohner der Region 10 zu verschiedenen Nachhaltigkeitsthemen zu erfassen. Diese Informationen dienen als Grundlage, um zukünftige Forschungs- und Bildungsaktivitäten der Hochschule zielgruppengerechter und effektiver zu gestalten. Darüber hinaus sollen die Daten als Argumentations- und Diskussionsgrundlage für geplante Stakeholder-Dialoge dienen. In diesen soll die Weiterentwicklung verschiedener Konsumbereich in der Region 10 diskutiert werden können. Ihre Daten werden jedoch komplett anonymisiert gespeichert – eine Rückverfolgbarkeit auf einzelne Personen, Endgeräte bzw. E-Mail-Adressen ist ausgeschlossen.
Die Verarbeitung Ihrer E-Mail-Adresse dient nur zu Verifikationszwecken, um einen Missbrauch, z.B. durch eine mehrfache Teilnahme und somit Verfälschung der Ergebnisse, zu verhindern. Eine Weitergabe, Kontaktaufnahme zu Zwecken abseits der Benutzung der App oder gar eine Nutzung für kommerzielle Zwecke findet nicht statt.

Rechtsgrundlage für die Datenerhebung sind Art.6 Abs. 1 lit a DSGVO.
Empfänger bzw. Kategorien der Empfänger personenbezogener Daten (Art. 13 Abs. 1 lit e DSGVO) 
Die THI gibt Ihre Daten nur auf der Grundlage gesetzlicher Ermächtigungen oder mit Ihrem Einverständnis weiter: Darüber hinaus erfolgt keine Datenweitergabe. 
Im Rahmen der Zusammenarbeit zwischen der THI und der Katholischen Universität Eichstätt-Ingolstadt (KU) werden die gespeicherten Daten beiden Hochschulen für die zuvor genannten Zwecke zur Verfügung gestellt. 
Darüber hinaus werden die anonymisierten Daten nach dem Open Source-Prinzip ebenfalls weiteren wissenschaftlichen Institutionen zu Forschungszwecken frei zugänglich gemacht

Ihre Pflicht zur Bereitstellung der personenbezogenen Daten / mögliche Folgen der Nichtbereitstellung (Art. 13 Abs. 2 lit e DSGVO) 
Sollten Sie abgefragte, entscheidungsrelevante Daten nicht angeben, können Ihre zuvor gemachten Angaben nicht für eventuelle Forschungs- und Entscheidungsprozesse basierend auf Daten der App berücksichtigt werden. 
Geplante Speicherdauer Ihrer personenbezogenen Daten (Art. 13 Abs. 2 lit DSGVO) 
Die Speicherdauer Ihrer personenbezogenen Daten beträgt maximal 3 Jahre.

Ihre RECHTE

Ihr Recht auf Auskunft, auf Berichtigung von Daten bzw. Einschränkung der Verarbeitung, auf Löschung und Datenübertragbarkeit (Art. 13 Abs. 2 lit b, 15 bis 20 DSGVO) 

Sie haben gem. Art. 15 DSGVO das Recht Auskunft darüber zu verlangen, ob und welche persönlichen Daten von Ihnen gespeichert sind, zu welchem Zweck sie genutzt werden, an wen die Daten übermittelt wurden, ggfs. woher die gespeicherten Daten stammen, sofern Sie nicht bei Ihnen persönlich erhoben wurden (Art. 15 DSGVO). Sollten Sie der Meinung sein, es werden unrichtige persönliche Daten über Sie gespeichert, haben Sie ein Recht auf Berichtigung dieser Daten gem. Art. 16 DSGVO bzw. gem. Art. 18 DSGVO auf Einschränkung der Verarbeitung bis zu Klärung der Richtigkeit. In Bezug auf personenbezogene Daten, die für den erhobenen Zweck nicht mehr benötigt werden, haben Sie ggfs. ein Recht auf Löschung von Daten gem. Art. 17 DSGVO. 
Art. 20 DSGVO begründet Ihr Recht, die von Ihnen bereitgestellten Daten in einem strukturierten, gängigen, maschinenlesbaren Format zur Verfügung gestellt zu bekommen bzw. einem anderen von Ihnen benannten Verantwortlichen direkt zur Verfügung gestellt zu bekommen. 


Ihr Recht auf Widerruf der Einwilligung zur Datenverarbeitung (Art. 13 Abs. 2 lit c, DSGVO) 

Sie haben gem. Art. 13. Abs. 2 lit c das Recht, die Einwilligung jederzeit zu widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird
Ihr Beschwerderecht (Art. 13 Abs. 2 lit d, Art. 77 DSGVO) 
Sollten Sie als Betroffene oder Betroffener der Ansicht sein, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, haben Sie gem. Art. 77 DSGVO unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs auch das Recht auf Beschwerde bei einer Aufsichtsbehörde. Die für öffentliche Stellen in Bayern zuständige Aufsichtsbehörde ist gemäß Art. 15 BayDSG der Bayerische Landesbeauftragte für den Datenschutz (BayLfD). Sie erreichen ihn unter 

Email: poststelle@datenschutz-bayern.de 
Tel. +49 89 212672-0
Fax +49 89 212672-50


Ihr Widerspruchrecht aus einer besonderen Situation („atypische Konstellation“) - (Art. 21 DSGVO) 

Sie haben das Recht, jederzeit gegen die auf der Rechtsgrundlage des Art. 6 Abs. 1 lit e DSGVO erfolgende Verarbeitung Ihrer privaten Emailadresse ganz oder teilweise Widerspruch einzulegen aus Gründen, die sich aus einer bei Ihnen möglicherweise vorhandenen besonderen Situation ergeben. Es muss sich um eine im Kontext als Benutzer oder Benutzerin atypische Fallkonstellation handeln. Die teilweise oder vollständige Beendigung der Verarbeitung für die genannten Zwecke kommt nur in Betracht, sofern keine zwingenden schutzwürdigen Gründe für die weitere Verarbeitung vorliegen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder aber die weitere Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. 
Sie können den Widerspruch einlegen unter kanzler@thi.de.
      </pre>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  data() {
    return {
      key: "value"
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.$route.hash) {
        const el = document.querySelector(this.$route.hash)
        el && el.scrollIntoView()
      }
    })
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.contact-view-container {
  @include gradient;
  min-height: 100%;
}

.contact {
  margin: 50px 0px;

  a {
    color: $secondary;
    font-size: 20px;
    font-weight: bold;
  }
}

.logos {
  max-width: 100%;

  img {
    max-width: 100%;
    max-height: 100px;
  }
}

.imprint, .privacy {
  padding: 30px;
  text-align: left;

  pre {
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}
</style>