import Vue from 'vue'
import Router from 'vue-router'

import Contact from '@/views/Contact.vue'
import Demographics from '@/components/Demographics.vue'
import Highscores from '@/views/Highscores.vue'
import MainMenu from '@/views/MainMenu.vue'
import PollIntro from '@/views/PollIntro.vue'
import PollView from '@/views/PollView.vue'
import Sdgs from '@/views/Sdgs.vue'
import ThankYou from '@/components/ThankYou.vue'
import Tipps from '@/views/Tipps.vue'
import ValidateEmail from '@/views/ValidateEmail.vue'
import Welcome from '@/views/Welcome.vue'


import SdgWrapper from '@/components/SdgWrapper.vue'

const SdgComponets = {}

import sdgs from '@/data/sdg_urls.js'

/*
sdgs.forEach(s => {
  SdgComponets['Sdg'+s.id] = () => import(`@/components/sdgs/Sdg${s.id}.vue`);
})
*/

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: 'welcome'
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome
    },
    {
      path: '/menu',
      name: 'menu',
      component: MainMenu
    },
    {
      path: '/sdgs',
      name: 'sdgs',
      component: Sdgs
    },
    {
      path: '/sdg',
      name: 'sdg',
      component: SdgWrapper,
      children: sdgs.map(s => {
        return {
          path: s.url,
          name: s.url,
          component: SdgComponets['Sdg'+s.id]
        }
      })
    },
    {
      path: '/poll-intro',
      name: 'pollintro',
      component: PollIntro
    },
    {
      path: '/poll',
      name: 'poll',
      component: PollView
    },
    {
      path: '/demographics',
      name: 'demographics',
      component: Demographics
    },
    {
      path: '/thankyou',
      name: 'thankyou',
      component: ThankYou
    },
    {
      path: '/tipps',
      name: 'tipps',
      component: Tipps
    },
    {
      path: '/highscores',
      name: 'highscores',
      component: Highscores
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    {
      path: '/validate/:uid',
      name: 'validate',
      component: ValidateEmail,
      props: true
    }
  ],
  scrollBehavior: function(to) {
      if (to.hash) {
          return {selector: to.hash}
        } else {
          return {x: 0, y: 0}
        }
      },
})
