<template>
  <div class="welcome-container">
    <div class="header">
      <div class="logo">
        <img src="../assets/logo01.png"/>
      </div>
    </div>
    <div class="content">
      Herzlich willkommen bei „Think Green“, der Nachhaltigkeits-App für die
      Region Ingolstadt, Eichstätt, Neuburg-Schrobenhausen und Pfaffenhofen!
      Wir wollen die Region 10 nachhaltiger gestalten, damit auch zukünftige
      Generationen hier noch gut und gerne leben können.
    </div>
    <div class="button-container">
      <div class="button secondary-bg" @click="go()">
        <b>Los geht's</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    go() {
      this.$router.push('/menu')
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.welcome-container {
  position: relative;
  @include gradient;
  padding-bottom: 120px;
  text-align: left;
}

.button-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  color:white;
}

.logo {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
