<template>
  <div id="app">
    <header-bar/>
    <router-view/>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue'
export default {
  name: 'App',
  components: {
    HeaderBar,
  }
}
</script>


<style lang="scss">
html, body {
  margin: 0;
}
html {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
}
body {
  height: 100%;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  height: 100%;
  max-width: 800px;
  margin:auto;
}
</style>
