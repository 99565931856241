<template>
  <div class="tipps-container">
    <div class="content">

      <div class="tipp">
        <div class="tipp-header" @click="welcome = !welcome">
          <i :class="{ expanded : welcome }" class="fa fa-chevron-right expand-icon"/>
          Willkommen in den FAQ der THInk-Green-App
        </div>
        <div v-show="welcome" class="tipp-content">
          <p>Als Wissenschaftler:innen aus dem Bereich der <a
              href="https://www.thi.de/campus-neuburg/willkommen-am-campus-neuburg/" target="_blank">
            Nachhaltigkeitswissenschaften</a> mit <a
              href="https://shop.arl-net.de/media/direct/pdf/nachrichten/2016-2/NR2_2016_Schneidewind_S13_online.pdf"
              target="_blank"> transformativen Anspruch</a>
            versuchen
            wir stets bürgernah Wissen über den Status Quo zu vermitteln und der Gesellschaft Lösungspfade für die
            Herausforderungen der Umwelt- und Klimakrisen offenzulegen. Wir gehen an Schulen, Volkshochschulen, treten
            auf
            Messen auf, organisieren offene Veranstaltungen an Universitäten und Hochschulen, gehen in die Unternehmen
            und
            versuchen so, die Menschen in ihren unterschiedlichen Rollen auf dem neuesten Stand der Forschung zu halten
            und
            ihnen die Mündigkeit zu verleihen, die sie in einer immer komplexer werdenden Welt als Verbraucher:innen,
            Bürger:innen, Eltern und als Gestalter:innen in der Arbeitswelt brauchen. Dabei werden wir immer wieder mit
            denselben Fragen konfrontiert.</p>
          <p>
            Wir haben uns zusammengesetzt und die <b>23 Fragen</b> herausgearbeitet, mit denen wir gefühlt am häufigsten
            konfrontiert
            worden sind, um diese kurz und bündig zu beantworten. Wir haben hier keine wissenschaftlichen Abhandlungen
            verfasst. Die Literatur, auf die wir uns stützen, haben wir Ihnen in einer <b>kommentierte
            Literaturliste</b>
            zusammengestellt oder <b>im Text verlinkt</b>, wenn dies möglich war. In vielen Fällen führt der Link direkt
            zu
            einer
            downloadbaren pdf-Datei. Vielleicht können wir mit diesen <b>FAQ</b> Ihr Interesse an Nachhaltigkeitsthemen
            wecken und
            Sie wollen noch tiefer einsteigen, ansonsten <b>greifen Sie einfach heraus, was Sie interessiert. Die
            einzelnen
            Antwortangebote lassen sich unabhängig voneinander verstehen.</b></p>
          <p>
            Wir sehen diese FAQ als Ergänzung zum Selbsttest der <b>THInk-Green-App</b>. Nachhaltigkeit ist ein Ergebnis
            einer
            Kooperation von Menschen und Institutionen in allen Lebensbereichen. Wir wollen Ihnen helfen, sich in dem
            Dschungel aus Informationen besser zurechtzufinden, Ihnen Wissen an die Hand geben, das Ihnen ermöglicht,
            mitreden
            zu können, aber vor allem <b>Ihnen bei Handlungsentscheidungen hilft</b>, eine rationale Entscheidung zu
            treffen.
            Unsere Welt ist am Scheideweg. Um die Erde angenehm bewohnbar zu halten, kommt es auf jeden und jede an.
            Auch
            auf SIE!</p>
          <p>
            Falls Sie noch weitere Fragen haben, deren Beantwortung Sie in diesem Rahmen vermissen, lassen Sie es uns
            wissen
            und schreiben Sie uns: <a
              href="mailto:stefan.schweiger@thi.de" target="_blank">
            stefan.schweiger@thi.de</a> Wir ergänzen und erweitern diese FAQ über die gesamte
            Projektlaufzeit. Auch einer Kritik stehen wir offen gegenüber. Wissenschaft lebt von Kritik und unfehlbar
            ist
            niemand. Alles, was zählt, ist der zwanglose Zwang des besseren Arguments.
          </p>
        </div>
      </div>

      <br>

      <div class="tipp">
        <div class="tipp-header" @click="whatsSustainability = !whatsSustainability">
          <i :class="{ expanded : whatsSustainability }" class="fa fa-chevron-right expand-icon"/>
          Was bedeutet Nachhaltigkeit?
        </div>
        <div v-show="whatsSustainability" class="tipp-content">
          <p>Werden Begriffe von unterschiedlichen politischen Parteien, Wissenschaftsdisziplinen, Unternehmen, Medien
            und
            in der Alltagssprache gebraucht, dann bilden sich sehr unterschiedliche Vorstellungen über deren Inhalte
            aus.
            Wir wollen hier ein wenig Licht ins Dunkle bringen.</p>
          <h3>Ein Begriff aus der Forstwirtschaft</h3>
          <p>Der Ursprung des Begriffs der Nachhaltigkeit wird oftmals in der Forstwirtschaft bei <b>Hans Carl von
            Carlowitz</b> (1645-1714) verortet. Carlowitz wollte für einen langfristigen ökonomischen Maximalertrag aus
            dem
            Abholzen von Wäldern sorgen, auch um einer drohenden Holznot zuvorzukommen, die man damals fürchtete. Der
            Ansporn war also weder der Erhalt der Schöpfung noch ein breiterer Zugriff der Bevölkerung auf die
            Ressourcen, sondern dem Kurfürsten Friedrich August I. von Sachsen (August der Starke) und seinem Geschlecht
            einen nachhaltigen Zugriff auf Holz sichern. Die daraus folgende Konzentration auf Fichten, machten den
            deutschen Wald weniger resilient gegenüber Schädlingsbefall und Unwettern. Carlowitz definiert
            Nachhaltigkeit mit den Worten und der Rechtschreibung seiner Zeit:
          </p>
          <div class="tab"><i>„Wird derhalben die gröste Kunst, Wissenschafft, Fleiß und Einrichtung hiesiger Lande
            darinnen beruhen
            wie
            eine sothane Conservation und Anbau des Holtzes anzustellen, daß es eine continuirliche beständige und
            nachhaltende Nutzung gebe, weiln es eine unentberliche Sache ist, ohne welche das Land in seinem Esse [lat.:
            Sein] nicht bleiben mag.“</i></div>
          <p>
            <b>Heute verwenden wir einen dennoch komplexeren Begriff von Nachhaltigkeit</b>, der Mensch, Natur und
            Kultur in
            den Blick nimmt und nicht allein Bezug auf die ökonomischen Belange des Menschen nimmt.
          </p>
          <h3>Die vier Säulen der Nachhaltigkeit</h3>
          <p>Nachhaltige Entwicklung setzt sich aus vier Aspekten zusammen, welche oft als die vier Säulen der
            Nachhaltigkeit bezeichnet werden. Diese Zielsetzungen gehen auch mit Konflikten einher. Können diese durch
            Konsens oder Kompromisse gelöst werden, könnte eine in Summe nachhaltige Entwicklung möglich werden.
            Nachhaltige Entwicklung hat ein lebenswertes, zukunftsfähiges und resilientes gesellschaftliches
            Zusammenleben zum Ziel. Unter den vier Säulen der Nachhaltigen Entwicklung versteht man:</p>
          <p><b>Ökologische Nachhaltigkeit</b> bedeutet, dass natürliche Ressourcen nur in dem Maße ausgebeutet werden
            dürfen und die Umwelt nur so belastet werden soll, dass diese in ihrer Regenerationsfähigkeit nicht
            eingeschränkt wird.</p>
          <p><b>Kulturelle Nachhaltigkeit</b> bedeutet, dass materielle und immaterielle Kulturgüter (z.B. Denkmäler,
            Riten)
            geschützt und erhalten werden sollen. Der Kultur- und Medienbereich und soll laut der deutschen
            <a
                href="https://www.bundesregierung.de/breg-de/bundesregierung/bundeskanzleramt/staatsministerin-fuer-kultur-und-medien/kultur/nachhaltigkeit-1967426"
                target="_blank">
              Staatsministerin für Kultur und Medien</a>, auch Vermittler, Impulsgeber und Innovationstreiber für
            Umwelt- und
            Klimaschutz sein. </p>
          <p><b>Ökonomische Nachhaltigkeit</b> bedeutet, dass die Erträge von Unternehmen nur so weit maximiert werden
            sollen,
            dass auch zukünftig entstehende Unternehmen noch in dem Maße auf natürliche Ressourcen und Funktionen
            zugreifen können, dass auch sie noch Gewinne erwirtschaften, Arbeitsplätze schaffen und Kredite zurückzahlen
            können.
          </p>
          <p><b>Soziale Nachhaltigkeit</b> bedeutet, dass die Erträge von Unternehmen nur so weit maximiert werden
            sollen,
            dass auch zukünftig entstehende Unternehmen noch in dem Maße auf natürliche Ressourcen und Funktionen
            zugreifen können, dass auch sie noch Gewinne erwirtschaften, Arbeitsplätze schaffen und Kredite zurückzahlen
            können.
          </p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="strategies = !strategies">
          <i :class="{ expanded : strategies }" class="fa fa-chevron-right expand-icon"/>
          Was sind die drei großen Strategien einer auf Nachhaltigkeit ausgerichteten Versorgung mit Ressourcen und
          Energie?
        </div>
        <div v-show="strategies" class="tipp-content">
          <p>Man unterscheidet drei große Strategielinien in der Nachhaltigen Entwicklung: Effizienz-, Konsistenz- und
            Suffizienzstrategien. Möchte man sie so kurz wie möglich zusammenfassen, könnte man von besser (Effizienz),
            anders (Konsistenz) und weniger (Suffizienz) sprechen.</p>
          <h3>Effizienzstrategie</h3>
          <p><b>Effizienz</b> bedeutet, ein Ziel mit möglichst geringem Aufwand zu erreichen. Dies geht aus ökologischer
            Sicht damit einher, dass möglichst wenig Ressourcen bzw. Energie pro Wareneinheit bei gleichbleibender
            Qualität und Nutzen eingesetzt werden. Ein Beispiel dafür ist der Einsatz von LED-Lampen anstelle von
            energiehungrigen Glühbirnen. </p>
          <h3>Konsistenzstrategie</h3>
          <p><b>Konsistenz</b> edeutet, dass Energie, Roh- und Werkstoffe so eingesetzt werden, dass sie einer
            Nachnutzung zugeführt werden können bzw. aus erneuerbaren Quellen stammen. Beispiele dafür sind die
            Umstellung von fossilen auf erneuerbare Energieträger in der Stromerzeugung, Wiederverwertungsprozesse wie
            bei Altglas, Altpapier, Kunststoffen oder aber auch Müllverbrennungsanlagen, durch die aus dem anfallenden
            Müll Strom erzeugt wird. Letzteres nennt man <a
                href="https://www.umweltbundesamt.de/themen/abfall-ressourcen/entsorgung/thermische-behandlung"
                target="_blank">
              thermische Verwertung</a>, die eine Form der Endverwertung eines
            Stoffes ist. Ziel ist es stets, unter möglichst geringem Materialverlust und Energieeinsatz Stoffe so oft
            wie möglich nutzen zu können.</p>
          <h3>Suffizienzstrategie</h3>
          <p><b>Suffizienz</b> bedeutet eine <a
              href="https://www.researchgate.net/profile/Niko-Paech/publication/320182009_Suffizienz_Subsistenz/links/61816e9aeef53e51e11d84fe/Suffizienz-Subsistenz.pdf"
              target="_blank">
            Konsum</a>- und Produktionsumstellung, um gesamtbilanziell Energie und Material
            einzusparen. Auf privater Ebene lohnt sich z.B. die Anschaffung einer Bohrmaschine angesichts der realen
            Nutzungsminuten im Jahr kaum. Die Anschaffung einer hochwertigen Maschine für eine ganze Hausgemeinschaft
            ist ökologisch nachhaltiger und erhöht die Nutzungs- und Ergebnisqualität, jedoch wird mit so einem Vorgehen
            das ökonomische Wachstum gebremst. Jedoch können auch Unternehmen von suffizientem Handeln profitieren. So
            spart eine <a
                href="https://epub.wupperinst.org/files/3955/ImpW2.pdf"
                target="_blank">
              Reederei</a> Treibstoff und Liegekosten in Häfen, indem sie die Geschwindigkeit ihrer Schiffe
            drosselt, trotzdem pünktlich liefert und sogar durch Kostenreduktion ohne Entlassungen die Gewinne erhöht.
          </p>
          <h3>Starke und schwache Nachhaltigkeit</h3>
          <p><b>Schwache Nachhaltigkeit</b> nimmt in erster Linie die Bedürfnisse der Menschen in den Blick und
            natürliche Ressourcen gelten <b>prinzipiell als durch menschliche oder maschinelle Arbeit ersetzbar</b>. So
            können
            – nach Auffassung der Vertreter:innen der schwachen Nachhaltigkeit – die Naturdienstleistungen, die z.B.
            durch die Bestäubung von Pflanzen durch Bienen erbracht werden, auch von Arbeiter:innen oder Robotern
            erledigt werden. </p>
          <p>Im Vordergrund steht bei der schwachen Nachhaltigkeit die <b>Aufrechterhaltung der Wirtschaftstätigkeit</b>
            und
            damit eng verbunden die <b>politische Stabilität</b>. <i>Befürworter:innen</i> fordern eine Nachhaltigkeit
            des Machbaren.
            Kritiker:innen nennen diese Form der Nachhaltigkeit <b>„Micky-Mouse-Nachhaltigkeit“</b>, was sich von einer
            bildlichen Darstellung ableitet und im der wissenschaftlichen Nachhaltigkeitsszene zum geflügelten Wort
            geworden ist:</p>
          <img src="../assets/faq-images/sustainability.png" style="width:500px">
          <p><b>Starke Nachhaltigkeit</b> hingegen räumt der <b>ökologischen Nachhaltigkeit Vorrang vor anderen
            Dimensionen</b>
            (kulturell, ökonomisch, sozial) ein. Ökologische Nachhaltigkeit wird dabei als Voraussetzung für eine
            gelingende Umsetzung der Ziele (Wohlstand, Wirtschaftswachstum, Erhalt des kulturellen Erbes der Menschheit)
            der jeweils anderen Nachhaltigkeitsdimensionen gesehen. <i>Befürworter:innen</i> erinnern an die enormen
            Risiken,
            die der Mensch mit seiner Wirtschaftstätigkeit eingeht. <i>Kritiker:innen</i> meinen, dass Maximalansprüche
            und
            Forderungen nach Systemänderungen scheitern werden und gar keine oder kaum Veränderungen nach sich ziehen.
          </p>
          <p><b>Nachhaltigkeit in der internationalen Politik: Die Ziele der Vereinten Nationen (UN): MDG und SDG</b>
          </p>
          <p>Auf der Ebene der internationalen Politik haben die Staaten der Vereinten Nationen sich auf <b><a
              href="https://www.un.org/millenniumgoals/pdf/MDG_Gap_2015_E_web.pdf"
              target="_blank">
            Millennium
            Development Goals</a></b> und <b><a
              href="https://sdgs.un.org/goals"
              target="_blank">
            Sustainable Development Goals</a></b> geeinigt und für Letztere auch Messindikatoren
            entwickelt. Hier eine Übersicht über die 17 Ziele: </p>
          <img src="../assets/faq-images/goals.png" style="width:900px">
          <p>Den globalen Stand der Zielerreichung kann man hier finden: <a
              href="https://sdg-indikatoren.de/"
              target="_blank">
            sdg-indikatoren.de</a>. Durch
            Presseveröffentlichungen und Übernahme auf unterschiedlichen politischen Ebenen und Systemen haben die MDG
            und SDG große Bekanntheit erlangt und wirken bis auf <a
                href="https://link.springer.com/content/pdf/10.1007/978-3-658-33927-2.pdf"
                target="_blank">
              kommunale Ebene</a>. Die <a
                href="https://www.bertelsmann-stiftung.de/de/ueber-uns/auf-einen-blick"
                target="_blank">
              Bertelsmann-Stiftung</a> hat zusammen
            mit dem <a
                href="https://difu.de/institut/leitbild-aufgaben"
                target="_blank">
              Deutschen Institut für Urbanistik</a> <b>Indikatoren für Kommunen</b> entwickelt. <a
                href="https://sdg-portal.de/de/sdg-indikatoren/ingolstadt"
                target="_blank">
              Hier</a> findet sich z.B. der
            aktuelle Stand in der Zielerreichung für die <b>Stadt Ingolstadt</b>. </p>
          <p>Mit diesen Zielen wird die sogenannte <b>Trichtertheorie</b> verfolgt. Auf einer ganz breiten Ebene werden
            abstrakte Ziele formuliert, auf die sich möglichst viele Staatenlenker:innen einigen können, z.B. Frieden,
            Gerechtigkeit, Umweltschutz und Kampf gegen Armut und Hunger. Dieser breite Trichter soll sich über die Zeit
            und über die Politikebenen von den internationalen Institutionen bis zu den Kommunen hinweg verengen, bis es
            zu Verträgen, Verordnungen und Gesetzen kommt, die die Staaten, die Unternehmen, die Institutionen und die
            Menschen immer stärker verpflichten, den Zielen näherzukommen. Dabei hilft der öffentliche Druck, der durch
            die o.g. Zielsetzungen argumentativ unterstützt werden kann.</p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="criticism = !criticism">
          <i :class="{ expanded : criticism }" class="fa fa-chevron-right expand-icon"/>
          Gibt es Kritik an den Sustainable Development Goals?
        </div>
        <div v-show="criticism" class="tipp-content">
          <p><b>Kritiker:innen</b> dieser Zielsetzungen bemängeln</p>
          <ul>
            <li><i>erstens</i>, dass die Einhaltung der Ziele nicht einklagbar ist und so die sind die SDG in einem
              System souveräner Staaten <a
                  href="https://www.2030report.de/sites/default/files/bericht2030/Kapitel_2-20_Abshagen-Demokratie.pdf"
                  target="_blank">
                nicht bindend</a> sind.
            </li>
            <li><i>zeitens</i>, dass sie <a
                href="https://www.econstor.eu/bitstream/10419/222320/1/1702849023.pdf"
                target="_blank">
              nicht adäquat evaluiert werden</a> und sich so autoritäre Staaten in einem
              positiven Licht präsentierten können. So bescheinigen die Indikatoren z.B. für <a
                  href="https://dashboards.sdgindex.org/profiles/russian-federation"
                  target="_blank">
                Russland</a> moderate
              Verbesserungen in der Inklusion von Minderheiten und vergaben 2020 die Bestbewertung beim Zugang zu
              Klagerechten.
            </li>
            <li><i>drittens</i>, dass die Wirkungen nicht <a
                href="https://www.ssoar.info/ssoar/bitstream/handle/document/64952/ssoar-2019-beisheim-UN-Gipfel_-_jetzt_mal_Taten.pdf?sequence=1&isAllowed=y"
                target="_blank">
              ausreichen</a>.
            </li>
          </ul>
          <h3>Alternative Begriffe</h3>
          <p>Es gibt <b>zahlreiche Alternativvorschläge</b> zum Begriff der Nachhaltigkeit aus Politik, sozialen
            Bewegungen und
            auch der Wissenschaft. Der indische Historiker Dipesh Chakrabarty z.B. schlägt in seinem Buch „Das Klima der
            Geschichte im planetarischen Zeitalter” vor, nicht mehr von Nachhaltigkeit zu sprechen, sondern von den
            <b>Bedingungen der Bewohnbarkeit</b> des Planeten. Diesen Begriff engt er nicht auf den Menschen ein,
            sondern
            weitet ihn auf „komplexes, vielzelliges Leben im Allgemeinen - und was dieses, nicht Menschen allein,
            nachhaltig bzw. zukunftsfähig macht”. Der Vorteil dieses Begriffs liegt nach Chakrabarty darin, dass er
            nicht allein auf die Bedürfnisse der Menschen zugeschnitten ist. Weitere alternative Begriffe sind
            <b>Zukunftsfähigkeit, Enkeltauglichkeit, Gleichgewichtserhaltung, verantwortungsbewusstes Handeln</b> und
            <b>große
              Transformation</b>.</p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="challenges = !challenges">
          <i :class="{ expanded : challenges }" class="fa fa-chevron-right expand-icon"/>
          Was sind die neun größten ökologischen Herausforderungen der Menschheit?
        </div>
        <div v-show="challenges" class="tipp-content">
          <p>Der schwedische Wissenschaftler Johan Rockström, der heute das <a
              href="https://www.pik-potsdam.de/de/startseite"
              target="_blank">
            Potsdamer Institut für Klimafolgenforschung</a>
            leitet, hat 2009 neun planetare Grenzen definiert, die der Mensch nicht überschreiten sollte, möchte er den
            Planeten für seine Spezies bewohnbar halten. Diese Forschungsarbeit wurde von Will Steffen und vielen seiner
            Kollegen 2015 aktualisiert. Das englischsprachige Original finden Sie <a
                href="https://www.science.org/doi/10.1126/science.1259855"
                target="_blank">
              hier</a>. </p>
          <p>Auf der untenstehenden Grafik, die das <a
              href="https://www.google.com/url?sa=i&url=https://www.umweltbundesamt.de/sites/default/files/medien/5750/publikationen/2020_10_26_texte_183_2020_planetary_boundaries.pdf&psig=AOvVaw2SkxYXl1av_VG2_N99JEEj&ust=1666342642444000&source=images&cd=vfe&ved=0CA0QjRxqFwoTCJjc_5u47voCFQAAAAAdAAAAABAE"
              target="_blank">
            Umweltbundesamt</a> übersetzt hat, sind die neun planetaren Grenzen zu
            finden. Nach dem Forschungsstand von 2015 muss man konstatieren, dass die Menschheit in den Bereichen
            genetische Vielfalt, auch Biodiversität oder Artenvielfalt genannt, sowie beim Phosphor- und
            Stickstoffkreislauf durch ihre Art zu wirtschaften den sicheren Handlungsspielraum bereits verlassen hat.
            Obgleich hier die Menschheit genannt wird, sind die Länder des globalen Nordens wesentlich stärker für die
            Ausbeutung der Rohstoffe, Treibhausgasemissionen, der Einbringung neuer Stoffe (z.B. Kunststoffe) und
            weiteren Eingriffen in die Ökosysteme des Planeten verantwortlich. Die Folgen dieses Systems des
            Wirtschaftens und der individuellen auf Konsum ausgerichteten Lebensstile sind jedoch für die gesamte
            Menschheit spürbar.</p>
          <p>In den Kategorien Klimawandel und Landnutzung bewegt sich die Menschheit im Bereich des erhöhten Risikos.
            Unter neuen Substanzen, die in die Umwelt eingetragen werden, versteht man auch z.B. den Eintrag von
            Plastikmüll in die Umwelt. Bei dieser planetaren Grenze fehlen der Wissenschaft noch Daten und dazugehörige
            Studien, um Grenzwerte definieren zu können. Dasselbe trifft auf die Kategorien Funktionale Vielfalt und
            Aerosolgehalt in der Atmosphäre zu. </p>
          <img src="../assets/faq-images/challenges.png" style="width:800px">
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="greenhouse = !greenhouse">
          <i :class="{ expanded : greenhouse }" class="fa fa-chevron-right expand-icon"/>
          Was ist der Treibhauseffekt?
        </div>
        <div v-show="greenhouse" class="tipp-content">
          <p>Grundsätzlich ist der Treibhauseffekt ein <b>natürlicher Prozess</b>, der die Temperatur auf der Erde
            beeinflusst und Leben auf der Erde erst möglich macht. </p>
          <p>Die Erdoberfläche wird von der kurzwelligen Sonnenstrahlung erwärmt und reflektiert diese als langwellige
            Wärmestrahlung zurück in die Atmosphäre. In der Atmosphäre befinden sich so genannte Treibhausgase, die
            diese Wärmestrahlung teilweise absorbieren und die Energie wieder an die Erdoberfläche zurückstrahlen.
            Dadurch erwärmen sich die Erdoberfläche und die untere Atmosphärenschicht zusätzlich. Den Treibhauseffekt
            kann man mit einer wärmeisolierenden Decke vergleichen.</p>
          <p>Das natürliche Verhältnis zwischen Ein- und Abstrahlung, und damit dessen Auswirkungen auf unser Klima,
            <b>wird durch die vom Menschen verursachten Treibhausgase</b> gestört. Diese Emission von klimawirksamen
            Gasen hat
            sich seit Beginn der Industrialisierung drastisch erhöht. Die Auswirkungen der Treibhausgaskonzentration auf
            das Klima sind komplex. Die Änderung der Strahlung hat Einfluss auf die Änderung von z.B. Temperatur,
            Luftdruck, Verdunstung oder Bewölkung zur Folge. Die globale Mitteltemperatur ist seit Beginn des 20.
            Jahrhunderts <b>bereits um ca. 1,2°C gestiegen</b>. Man spricht hier vom menschengemachten oder <b>anthropogenen
              Treibhauseffekt</b>.</p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="emissions = !emissions">
          <i :class="{ expanded : emissions }" class="fa fa-chevron-right expand-icon"/>
          Was sind Treibhausgasemissionen?
        </div>
        <div v-show="emissions" class="tipp-content">
          <p>Treibhausgase sind diejenigen Gase in der Erdatmosphäre, die den sogenannten Treibhauseffekt verursachen.
            Treibhausgase können einen natürlichen Ursprung, aber auch durch anthropogene (menschengemachte) Aktivitäten
            verstärkt emittiert werden. Die bekanntesten Treibhausgase <b>Kohlenstoffdioxid (CO<sub>2</sub>), Methan (CH<sub>4</sub>),
              Wasserdampf und Lachgas (N<sub>2</sub>O)</b> sind natürlicherweise in geringen Konzentrationen in der
            Atmosphäre zu
            finden.
            Durch menschliche Handlungen (insb. Nutzung fossiler Energieträger wie z.B. Öl und Erdgas) hat sich der
            Anteil seit Beginn des letzten Jahrhunderts jedoch deutlich erhöht. In den vergangenen 800.000 Jahren lag
            die <b>CO<sub>2</sub>-Konzentration</b> nie über 300 ppm (<i>parts per million</i>). Seit der
            Industrialisierung ist
            die
            CO<sub>2</sub>-Konzentration auf über 420 ppm (April 2022) angestiegen. <a
                href="https://www.umweltbundesamt.de/daten/klima/atmosphaerische-treibhausgas-konzentrationen#kohlendioxid-"
                target="_blank">
              Hier</a> können sie nachsehen, bei wie viel ppm Sie
            geboren worden sind und wie sich die Konzentration von Treibhausgasen seitdem verändert hat. </p>
          <p>Kohlendioxid (CO<sub>2</sub>), Methan (CH<sub>4</sub>) und Lachgas (N<sub>2</sub>O) haben einen
            unterschiedlich starken Einfluss auf das
            Klima. Um die <b>Klimawirksamkeit</b> der Treibhausgase miteinander vergleichen und das Erwärmungspotential
            definieren zu können, werden Methan und Lachgas in sogenannten <b>CO<sub>2</sub>-Äquivalenten
              (CO<sub>2e</sub>)</b> angegeben,
            wobei auch
            die Verweildauer der Treibhausgase eine Rolle spielt. Lachgas bleibt beispielsweise rund 100 Jahre in der
            Atmosphäre, Methan rund 10 – 12 Jahre. Bei CO<sub>2</sub> befinden sich nach 1000 Jahren immer noch bis zu
            40 % des
            emittierten CO<sub>2</sub> in der Atmosphäre. Methan hat ein <a
                href="https://link.springer.com/content/pdf/10.1007/978-3-658-18218-2.pdf"
                target="_blank">
              28-faches Erwärmungspotential</a>, Lachgas sogar ein
            <a
                href="https://www.ernaehrungs-umschau.de/fileadmin/Ernaehrungs-Umschau/pdfs/pdf_2011/EU01_2011_026_029.qxd.pdf"
                target="_blank">
              298-faches Erwärmungspotential</a> von CO<sub>2</sub> auf den Betrachtungszeitraum von 100 Jahren
            gerechnet. Man kann
            sagen, dass Methan und Lachgas zwar über einen kürzeren Zeitraum wirksam sind, jedoch innerhalb dessen eine
            stärkere Wirkung entfalten.</p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="limit = !limit">
          <i :class="{ expanded : limit }" class="fa fa-chevron-right expand-icon"/>
          Was bedeutet eigentlich die 2°/1,5° Grad-Grenze?
        </div>
        <div v-show="limit" class="tipp-content">
          <p>Im Pariser Klimaschutzabkommen 2015 haben sich 195 Staaten darauf verständigt, bis 2100 die globale
            Erwärmung <b>deutlich unter 2°C</b> zu begrenzen, um die Folgen des Klimawandels einzudämmen. Die Erwärmung
            wird
            dabei in Referenz zum <b>vorindustriellen Niveau als Mittelwert der Temperaturen zwischen 1850-1900</b>
            gesetzt.
            Die Grenze stellt dabei einen politisch festgesetzten Schwellenwert dar. Wissenschaftler:innen empfehlen in
            ihrer Mehrzahl, die Erwärmung auf unter 1,5°C zu halten. </p>
          <p>Doch selbst bei einem <b>Anstieg von 1,5°C</b> ist mit erheblichen Veränderungen zu rechnen, auf welche die
            Menschen mit Anpassungen in Wohnort, Ausgestaltung des Arbeitsplatzes und in der Landwirtschaft reagieren
            werden müssen. Auch ist schon bei einem Anstieg von 1,5°C mit vermehrten Extremwetterereignissen wie Dürren
            und Starkregenereignissen zu rechnen. </p>
          <p>Leider sind seit dem Pariser Klimaschutzabkommen (2015) die Treibhausgasemissionen gestiegen und nicht
            gesunken. <b>2015</b> emittierte die Welt ca. <b>32,1 Gigatonnen CO<sub>2</sub></b>. <b>2021</b> lagen die
            Emissionen
            bei ca. <b>36,3
              Gigatonnen</b>.</p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="weatherCLimate = !weatherCLimate">
          <i :class="{ expanded : weatherCLimate }" class="fa fa-chevron-right expand-icon"/>
          Was ist der Unterschied zwischen Wetter und Klima?
        </div>
        <div v-show="weatherCLimate" class="tipp-content">
          <p>Der <b>Unterschied liegt in den Dimension Raum und Zeit</b>. Vereinfacht könnte man sagen, dass Wetter, das
            ist,
            was der Wetterbericht vorherzusagen imstande ist, also das, was im Moment passiert und einige wenige Tage in
            der Zukunft liegt. Über die vergangenen Jahrzehnte wurden die Wettervorhersagen immer genauer, jedoch ist
            man vor Überraschungen immer noch nicht ganz gefeit. Die Entwicklung des Wetters ist ein komplexes Phänomen
            und die Menschen haben immer noch nicht ganz verstanden, welche Phänomene wie aufeinander einwirken.
            Wettervorhersagen sind räumlich begrenzt. So ergibt der Satz „Morgen wird es in Europa 25 Grad warm und es
            ist vereinzelt mit Schauern zu rechnen.“ keinen Sinn. </p>
          <p>Die Erforschung des Klimas ist das <b>Sammeln zeitlich und räumlich weitreichender Wetterdaten</b> über
            Jahre,
            Jahrzehnte, Jahrhunderte und sogar über Jahrtausende hinweg. Es wird dabei mit Durchschnittswerten
            gerechnet. In den Durchschnittsdaten gehen Wetterkapriolen wie ein einzelner enorm heißer Tag unter und es
            lassen sich große Linien nachzeichnen oder in die Zukunft projizieren. Dabei gilt das gleiche wie beim
            Wetterbericht. Je weiter die Szenarien in die Zukunft reichen, desto unsicherer werden die Vorhersagen.
            Jedoch ist man in der Lage, die Unsicherheiten zu quantifizieren. Zudem ermittelt man räumlich
            weitreichendere Daten, sodass man Aussagen darüber treffen kann, wie sehr sich die Durchschnittstemperatur
            in Europa oder weltweit in bestimmten Zeitabschnitten verändert hat. </p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="neutral = !neutral">
          <i :class="{ expanded : neutral }" class="fa fa-chevron-right expand-icon"/>
          Was bedeutet Klimaneutralität?
        </div>
        <div v-show="neutral" class="tipp-content">
          <p>Klimaneutralität bedeutet, ein Gleichgewicht zwischen Kohlenstoffemissionen und der Aufnahme von
            Kohlenstoff aus der Atmosphäre in Kohlenstoffsenken herzustellen. Es gibt somit zwei Ansätze:</p>
          <p>1. Weniger Emissionen, also massive Reduktion von CO<sub>2</sub>-Emissionen, indem die Nutzung fossiler
            Energien
            eingestellt wird, und </p>
          <p>2. Durch das Binden von CO<sub>2</sub>, das sich bereits in der Atmosphäre befindet, in Kohlenstoffsenken.
            Als
            Kohlenstoffsenke wird ein System bezeichnet, das mehr Kohlenstoff aufnimmt als es abgibt. Die wichtigsten
            natürlichen Kohlenstoffsenken sind Böden, Wälder, Moore und Ozeane. Laut Schätzungen entfernen natürliche
            Senken <b>zwischen 9,5 und 11 Gigatonnen CO<sub>2</sub> pro Jahr</b>. 2021 betrugen die globalen
            CO<sub>2</sub>-Emissionen <b>36,3
              Gigatonnen</b>. Die Lücke zwischen Emissionen und dem Binden von CO<sub>2</sub> ist also noch eklatant
            hoch. </p>
          <p>Die natürlichen CO<sub>2</sub>-Senken, die der Planet Erde zur Verfügung stellt, reichen laut Weltklimarat
            nicht aus,
            um rechtzeitig Klimaneutralität zu erreichen. Es braucht daher auch <b>künstliche Kohlenstoffsenken</b> und
            Eingriffe in die Natur, um die Klimaziele einhalten zu können. Dazu zählen unter anderem der Schutz und die
            <a
                href="https://link.springer.com/content/pdf/10.1007/978-3-662-58650-1.pdf"
                target="_blank">
              Renaturierung von Mooren</a> und der Schutz der Wälder mit einem geeigneten <a
                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiKxImOkf76AhVLsaQKHWE8CDkQFnoECAcQAQ&url=https://www.rosalux.de/fileadmin/rls_uploads/pdfs/sonst_publikationen/Economic-Valuation-of-Nature.pdf&usg=AOvVaw13hk0cJEVMSRCFdUgUn3tp"
                target="_blank">
              Waldmanagement</a>, da abgestorbene
            Bäume das gespeicherte CO<sub>2</sub> wieder abgeben. Es ist also klug, daraus z.B. langlebige Möbel zu
            bauen und
            wieder neue Bäume anzupflanzen.</p>
          <p>
            Daneben gibt es Pläne, <b>CO<sub>2</sub> so lange in der Erde zu speichern, bis es versteinert ist</b>.
            Diese Technik
            nennt
            man <b><a
              href="https://www.umweltbundesamt.de/themen/wasser/gewaesser/grundwasser/nutzung-belastungen/carbon-capture-storage"
              target="_blank">
            Carbon Capture and Storage</a></b> (CCS) und es braucht – je nach vorhandener Gesteinsart - zwischen
            Jahrzehnten
            und Jahrhunderten zur endgültigen Versteinerung. <a
              href="https://www.deutschlandfunkkultur.de/pilotprojekt-fuer-treibhausgas-norwegen-will-co2-endlager-100.html"
              target="_blank">
            Norwegen</a> plant bereits, CO<sub>2</sub> aus Europa anzukaufen und <b>unter
            dem Meer</b> zu speichern. Ein anderer Vorschlag lautet, die <b>Weltmeere mit Eisen zu düngen</b>, um die
            Algenproduktion zu fördern, damit diese Kohlenstoffdioxid in Sauerstoff umwandeln. Viele Methoden der so
            genannten <b><a
              href="https://www.umweltbundesamt.de/themen/cO<sub>2</sub>-entnahme-aus-der-atmosphaere-muss-sicher"
              target="_blank">
            Negativen Emissionstechnologien</a></b> gelten als hochumstritten, aber kein optimistischeres Szenario des
            IPCC kommt ohne künstliche Entfernung von Treibhausgasen aus der Atmosphäre aus. Hochumstritten sind u.A.
            die Wirksamkeit wie z.B. in Fragen der Aufforstung und der Wiederaufforstung oder dem Problem der Leckagen
            bei der Speicherung von CO<sub>2</sub> in Gestein. Zudem ist es fraglich, ob es ausreichend Akzeptanz in der
            lokalen
            Bevölkerung gibt, wenn eine CO<sub>2</sub>-Lagerstätte errichtet werden soll oder sich landwirtschaftlich
            genutzte
            Felder zurück in Wälder entwickeln sollen. Außerdem ist man stets der Gefahr unerwünschter Nebenfolgen
            ausgesetzt, die dann wieder technologische Innovationen fordern, um diese auszugleichen. So kann z.B. die
            vermehrte Algenbildung durch Meeresdüngung, Lebewesen schaden oder sogar ganze Ökosysteme gefährden.
            Weitergehend, aber nicht abschließend stellt sich die Frage der Finanzierung solcher Vorhaben. Gilt hier ein
            Verursacherprinzip, werden die Kosten von den Steuerzahler:inen übernommen und welche Auswirkungen haben
            solche Klimaschutzmaßnahmen auf die ökonomische Leistungskraft der Weltwirtschaft? Die Stiftung Mercator hat
            zu diesen Technologien ein kurzes <a
              href="https://www.youtube.com/watch?v=jKmoTBb4fiM"
              target="_blank">
            Video</a> produziert.
          </p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="budget = !budget">
          <i :class="{ expanded : budget }" class="fa fa-chevron-right expand-icon"/>
          Was ist ein CO<sub>2e</sub>-Budget und wie hoch ist es?
        </div>
        <div v-show="budget" class="tipp-content">
          <p>Um die Klimaziele einzuhalten, <b>darf rechnerisch nur noch eine Restmenge von Treibhausgasen (THG) in die
            Atmosphäre ausgestoßen werden</b>. Man spricht dabei auch von einem sogenannten Restbudget, dass der
            Weltgesellschaft noch bleibt. Treibhausgase haben unterschiedliche Klimawirksamkeit, welche man in
            CO<sub>2</sub>-Äquivalenten (CO<sub>2e</sub> oder CO<sub>2eq</sub>) ausdrückt. Das globale CO<sub>2e</sub>-Budget
            betrug im Januar 2022 rund 400
            Gigatonnen CO<sub>2</sub>-Äquivalente (CO<sub>2e</sub>). Heute werden <b>pro Sekunde rund 1.337 t
              CO<sub>2e</sub></b> ausgestoßen. Gemäß der
            Klimaziele müsste die Weltwirtschaft bis Mitte des Jahrhunderts CO<sub>2</sub>-neutral sein, ab 2050 müssten
            die
            THG-Emissionen sogar negativ sein. Wie schnell die CO<sub>2</sub>-Uhr tickt, kann man auf der Seite des <a
                href="https://www.mcc-berlin.net/forschung/co2-budget.html"
                target="_blank">
              Mercator Research Instituts on Gobal Commons and Climate Change</a> verfolgen. </p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="budgetAmount = !budgetAmount">
          <i :class="{ expanded : budgetAmount }" class="fa fa-chevron-right expand-icon"/>
          Wie hoch ist das CO<sub>2</sub>-Budget für Deutschland?
        </div>
        <div v-show="budgetAmount" class="tipp-content">
          <p>Bevor wir uns den dem verbleibenden Budget Deutschlands zuwenden, muss geklärt werden, warum man welches
            Ausgangsjahr für die Berechnungen heranzieht. Erst danach kann man die restliche Menge auch aus
            Gerechtigkeitsaspekten einordnen. </p>
          <h3>Gleiche Emissionsziele für alle Nationalstaaten?</h3>
          <p>In dieser Frage kommt man um eine <b>ethische Bewertung</b> nicht herum. Sollen die bereits getätigten
            Emissionen
            zwischen 1850 und heute dabei auch einberechnet werden oder soll jedem heute und zukünftig lebenden Menschen
            dasselbe Budget zur Verfügung stehen? In einer Rangliste befindet sich der historischen
            Treibhausgasemittenten befindet sich <b><a
                href="https://www.carbonbrief.org/analysis-which-countries-are-historically-responsible-for-climate-change/"
                target="_blank">
              Deutschland auf Rang 6</a></b>, zwischen Indonesien und Indien. Angeführt wird
            die Liste von den Vereinigten Staaten von Amerika, China und Russland. Hier gilt es jedoch zu bemerken, dass
            Deutschland wesentlich weniger Einwohner:innen hat und Fläche besitzt als alle hier in diesem Absatz
            genannten Nationalstaaten. Bezöge man die ein, würde Deutschland noch einen wesentlich höheren Platz
            einnehmen. </p>
          <p>Die ehemalige Bundeskanzlerin <b>Dr. Angela Merkel</b> (CDU) folgte der Gerechtigkeitsvorstellung, dass
            jedem
            Menschen auf der Erde unabhängig der Emissionsgeschichte des Nationalstaats, in dem er lebt, <b>die gleichen
              Emissionsrechte</b> zustehen, also Burkina Faso und Nigeria ebenso behandelt werden wie die
            Industriestaaten,
            Deutschland, Frankreich oder die USA. Dahinter steht die Idee, <b>dass man nicht für das Verhalten anderer
              in
              der Vergangenheit verantwortlich gemacht werden kann</b>, sondern vielmehr Verantwortung zeigen muss, für
            die
            Menschen, die erst in der Zukunft leben werden. Daraus folgt, dass unbesehen vergangener Handlungen, die
            Menschen, die Wirtschaft und die politischen Entscheidungträger:innen alle dieselbe Verantwortung tragen.
            Allerdings – so ein Gegenargument – profitieren die Nationalstaaten des Globalen Nordens auch von den
            Handlungen ihnen vorangegangener Menschen in den Bereichen Infrastruktur, Stabilität des politischen Systems
            und Konzentration von Kapital. </p>
          <h3>Die Zeit rennt: Das CO<sub>2</sub>-Budget für Deutschland</h3>
          <p>Folgt man dem Leitsatz „Gleiche Emissionsziele für alle Nationalstaaten“ und setzt den <b>Beginn der
            historischen Verantwortung mit der Ratifizierung des Pariser Klimaabkommens</b> durch den deutschen
            Bundestag
            im
            Jahre <b>2016</b>, dann verbleiben ab dem Jahr 2022 Deutschland noch <a
                href="https://www.umweltrat.de/SharedDocs/Downloads/DE/04_Stellungnahmen/2020_2024/2022_06_fragen_und_antworten_zum_co2_budget.pdf?__blob=publicationFile&v=30"
                target="_blank">
              folgende Restbudgets</a>:</p>
          <ul>
            <li>Möchte man mit einer <b>Wahrscheinlichkeit von 67%</b> eine Erwärmung höher als <b>1,75°C</b> bis in das
              Jahr 2100 verhindern, dann hat Deutschland noch ein Restbudget von <b>6,1 Gigatonnen CO<sub>2</sub></b>.
            </li>
            <li>Möchte man mit einer <b>Wahrscheinlichkeit von 50%</b> eine Erwärmung von höher als <b>1,5°C</b> bis in
              das Jahr 2100 verhindern, dann hat Deutschland noch ein Restbudget von <b>3,1 Gigatonnen
                CO<sub>2</sub></b>.
            </li>
            <li>Möchte man mit einer <b>Wahrscheinlichkeit von 67%</b> eine Erwärmung höher als <b>1,5°C</b> bis in das
              Jahr 2100 verhindern, dann hat Deutschland noch ein Restbudget von <b>2,0 Gigatonnen CO<sub>2</sub></b>.
            </li>
          </ul>
          <p>Die Wahrscheinlichkeiten ergeben sich aus rechnerischen Unsicherheiten, die sich aus in der Zukunft nicht
            oder nur schwer hervorzusehenden Ereignissen ergeben. Eine sichere Prognose abzugeben wäre wissenschaftlich
            nicht seriös. Z.B. ist es schwer, Vulkanausbrüche, die fortschreitende Abholzung des Regenwaldes oder die
            Gefahren von <a
                href="https://www.umweltbundesamt.de/sites/default/files/medien/publikation/long/3283.pdf"
                target="_blank">
              Kipppunkten</a> einzuberechnen. </p>
          <p>Deutschland emittierte <b><a
              href="https://www.umweltbundesamt.de/sites/default/files/medien/384/bilder/dateien/2_abb_thg-emissionen-seit-1990-nach-gasen_2022.pdf"
              target="_blank">
            2021 ca. 762.000.000 Tonnen CO<sub>2e</sub></a></b>, also 0,762 Gigatonnen Treibhausgase
            (CO<sub>2</sub>, Methan,
            Lachgas usw.). Davon waren 88,6% CO<sub>2</sub>, also <b>0,675 Gigatonnen</b>. Es bleiben also noch <b>knapp
              3
              Jahre</b>,
            um mit einer
            Wahrscheinlichkeit von 67% unter der 1,5°Grenze zu bleiben, wenn die Emissionen stabil bleiben. </p>
          <p><b><a
              href="https://de.statista.com/statistik/daten/studie/1275275/umfrage/treibhausgasbilanz-pro-person/"
              target="_blank">
            2021 haben die Deutschen etwas mehr als 11 CO<sub>2</sub>-Äquivalente</a></b> pro Kopf ausgestoßen. Allein
            an CO<sub>2</sub> waren
            es
            <b>2015</b>
            im Jahre des Pariser Klimaabkommens <b>pro Kopf 8,93 Tonnen</b> und die Deutschen <b>steigerten sich im Jahr
              2018 sogar
              auf 9,13 Tonnen</b>, während der weltweite Durchschnitt <b><a
                href="https://de.statista.com/statistik/daten/studie/159811/umfrage/cO<sub>2</sub>-emissionen-weltweit-pro-kopf-seit-1990/"
                target="_blank">
              2018 bei 4,8 Tonnen</a></b> pro Kopf lag. <a
                href="https://uba.cO2-rechner.de/de_DE/living-hs#panel-calc"
                target="_blank">
              Hier</a> können Sie ihre
            persönliche CO<sub>2</sub>-Bilanz errechnen. </p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="ipcc = !ipcc">
          <i :class="{ expanded : ipcc }" class="fa fa-chevron-right expand-icon"/>
          Was macht eigentlich der Weltklimarat IPCC?
        </div>
        <div v-show="ipcc" class="tipp-content">
          <p>Das <b>Intergovernmental Panel on Climate Change (IPCC)</b>, im Deutschen oft Weltklimarat genannt, wurde
            1988
            durch das United Nations Environment Programme (UNEP) und den World Meteorological Organization (WMO)
            gegründet. Am ersten Treffen beteiligten sich 28 Staaten. Der Weltklimarat ist Teil des UNFCCC-Prozesses
            (<b>U</b>nited <b>N</b>ations <b>F</b>ramework <b>C</b>onvention on <b>C</b>limate <b>C</b>hange), in dessen
            Rahmen die jährlichen Klimakonferenzen
            organisiert und abgehalten werden. Diese Konferenzen werden als Conference of the Parties (COP) bezeichnet.
            2023 wird die 28. Konferenz dieser Art durchgeführt. </p>
          <p>Das IPCC führt keine eigenen Studien durch, sondern wertet die vorhandene wissenschaftliche Literatur in
            einer so genannten <b>Metastudie</b> aus und fasst die Ergebnisse in Sachstandsberichten zusammen. Die
            Sachstandsberichte geben den momentanen Stand der Forschung wieder. Es <b>ist der Goldstandard der
              Klimaforschung</b>. Wir reden hier von zehntausenden von Einzelstudien von zahllosen Wissenschaftler:innen
            von
            Universitäten und Fachhochschulen auf der gesamten Welt. Der IPCC-Bericht ist dabei sogar sehr konservativ
            ausgelegt. Der Bericht orientiert sich nicht an dramatischen Szenarien, sondern äußert sich sehr
            zurückhaltend. Die Berufung auf den IPCC liefert also einen sehr gesicherten Stand der Forschung. Eine
            vertrauenswürdigere Quelle für den Stand des Klimawandels und seine prognostizierte Entwicklung gibt es im
            Moment nicht. In den Medien findet man hingegen manchmal eine sogenannte false balance. Dabei wird dem
            IPCC-Bericht, ein Forscher oder eine Forscherin gegenübergestellt und es hat den Anschein, als ob sich zwei
            gleichwertige Meinungen gegenüberstehen. Davon kann aber keine Rede sein. <b>Seit der Gründung
              veröffentlichte
              das IPCC sechs Sachstandsberichte</b>. Den aktuellen Sachstandsbericht finden Sie <a
                href="https://www.ipcc.ch/report/ar6/wg3/downloads/report/IPCC_AR6_WGIII_Full_Report.pdf"
                target="_blank">
              hier</a>. </p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="ipccCriticism = !ipccCriticism">
          <i :class="{ expanded : ipccCriticism }" class="fa fa-chevron-right expand-icon"/>
          Was sind die Kritikpunkte an der Arbeit des IPCC?
        </div>
        <div v-show="ipccCriticism" class="tipp-content">
          <p>Kritiklos von allen Seiten akzeptiert ist der IPCC-Bericht dennoch nicht - und das ist auch gut so. Als
            sakrosankt kann man keine Forschungsergebnisse behandeln. Nur Kritik hilft bei der Weiterentwicklung. Es
            wird sich aber im Folgenden zeigen, dass unseres Erachtens nicht jede Kritik berechtigt ist. </p>
          <p>Kritik am IPCC kommt aus unterschiedlichen Richtungen. Zwei Kritikpunkte beschäftigen sich mit der
            politischen Einwirkung sowie der politischen Wirkung des IPCC: Einerseits wird die politische Einflussnahme
            auf die Zusammenfassung des Sachstandsberichts kritisiert. Andererseits wird das starke politische Gewicht
            der Sachstandsberichte einer Kritik unterzogen. Ein dritter Kritikpunkt setzt an der Methodik der
            Modellierung an. Doch trotz aller sicherlich berechtigten Kritik, ist die wissenschaftliche Leistung der
            Meta-Studie enorm und die transformative Wirkung kaum abzuschätzen, da die Sachstandsberichte medial stark
            verbreitet werden und in politischen Aushandlungsprozessen als starke Argumente wiegen. </p>
          <h3>Die politische Einflussnahme auf die Zusammenfassung der Ergebnisse</h3>
          <p>Bei der medien- und politikrelevanten Zusammenfassung, die <b>„Summary for Policy Makers”</b> genannt wird,
            müssen
            alle teilnehmenden Regierungen zustimmen. Bei der Zusammenfassung handelt es sich also nicht um eine
            Publikation, die allein aus dem System Wissenschaft stammt, sondern bei der auch Politiker:innen - je nach
            Interessenlage - um jede Formulierung ringen. Zum Beispiel sorgten beim aktuellen sechsten Sachstandsbericht
            erdölexportierende Länder dafür, dass Carbon Capture and Storage (CCS), also die Speicherung von
            CO<sub>2</sub> in
            Gesteinsschichten, eine stärkere Rolle bekommt. So erhöht sich die Menge an Treibhausgasen, die noch
            emittiert werden können, da sich so bilanziell durch unterirdische Speicherung das Restbudget erhöht und so
            noch mehr Öl gefördert werden kann, ohne die Klimaziele zu gefährden. So kann das Wirtschaftswachstum der
            erdölproduzierenden Länder besser gesichert werden.</p>
          <p><b>Politische Einflussnahme</b> sorgte auch dafür, dass die Formulierung „substantielle Reduktion fossiler
            Energien” in Umstieg auf „kohlenstoffarme Energiequellen” geändert worden ist. Jedoch wird durch die
            Einflussnahme der Politik auf die Zusammenfassung auch garantiert, dass alle teilnehmenden Staaten das
            Summary akzeptieren. Das aktuelle Summary for Policy Makers finden Sie <a
                href="https://www.ipcc.ch/report/ar6/wg3/downloads/report/IPCC_AR6_WGIII_SPM.pdf"
                target="_blank">
              hier</a>. Gleichzeitig ist die
            Einsichtnahme des <a
                href="https://www.ipcc.ch/site/assets/uploads/sites/4/2021/02/210202-IPCCJ7230-SRCCL-Complete-BOOK-HRES.pdf"
                target="_blank">
              ausführlichen wissenschaftlichen Berichtes</a> möglich, wodurch auch diese politischen
            Anpassungen diskutiert werden können und sich die Politik stellen muss. Es herrscht also vollkommene
            Transparenz inklusive der Offenlegung aller Quellen und Daten. Zudem reagiert das IPCC auch auf Kritik und
            <a
                href="https://www.ipcc.ch/errata/"
                target="_blank">
              arbeitet die Verbesserungen nachträglich</a> ein. So werden die Ergebnisse immer exakter. Veränderung ist
            ein
            Zusammenspiel unterschiedlicher Akteure mit unterschiedlichen Interessenlagen. Dies ist in Fragen des
            Klimaschutzes, der Klimaanpassung und der Resilienzsteigerung nicht anders. Wir erleben das auch in der
            Frage nach der Höhe des Kindergeldes, der Hartz IV-Sätze oder der Legalisierung von Cannabis. Die Kritik,
            die unterschiedlichen Schlüsse und Dateninterpretationen ist ein öffentlicher Aushandlungsprozess, der
            tagtäglich geführt, vom Small-Talk beim Bäcker über Tweets bis hin zu zwischenstaatlichen Verträgen wie dem
            Kyotoprotokoll. </p>
          <h3>Die fehlende demokratische Legitimation des IPCC</h3>
          <p>Das IPCC ist ein Expertengremium, auf dessen Basis Eingriffe in das Alltagsleben der Menschen vorgeschlagen
            und legitimiert werden, <b>ohne dafür einen demokratischen Auftrag</b> zu haben. Allerdings sind
            Kommissionen zur
            Beratung von politischen Entscheidungsträger:innen nicht ungewöhnlich und erfüllen den Zweck, dass
            Politiker:innen wohlinformierte Entscheidungen treffen können. Legitimiert werden die Ergebnisse durch
            weitestgehende Einigkeit innerhalb der wissenschaftlichen Gemeinschaft, aber das bedeutet noch keine
            politische Umsetzung. Die Wissenschaftler:innen, die am IPCC mitarbeiten, treffen keine politischen
            Entscheidungen, sondern geben den Stand der Forschung wieder. Die Einigkeit darüber, dass der Klimawandel
            auf Eingriffe des Menschen zurückzuführen ist, erreicht z.B. laut einer Studie von James Powell 100%. Er
            wertete dazu über 11.600 wissenschaftliche Artikel aus. Was die Politik, die Wirtschaft, die Religion, das
            Gesundheitswesen oder das System Recht im jeweiligen Land auf Grund dieser Informationen macht, darauf haben
            die Wissenschaftler:innen keinen Einfluss; abgesehen von einem diskursiven, also öffentlicher Äußerungen.
            Denn freilich können Wissenschaftler:innen Interviews geben, Gastbeiträge in Tageszeitungen und Sachbücher
            schreiben, Bürgerveranstaltungen organisieren, mit Entscheider:innen persönlich sprechen und <a
                href="https://rp-online.de/politik/deutschland/fridays-for-future-12000-wissenschaftler-unterstuetzen-schuelerbewegung_aid-37425781"
                target="_blank">
              Soziale Bewegungen unterstützen</a>. Jeder Wissenschaftler und jede Wissenschaftlerin ist schließlich auch
            ein Bürger oder eine Bürgerin. </p>
          <h3>Die nivellierende Methodik der Sachstandsberichte</h3>
          <p>David Archer von der Universität in Chicago <b>kritisiert die Gleichmäßigkeit, mit der der
            Temperaturanstieg
            in den Modellen der Szenarien des IPCC dargestellt wird</b>. Dies liegt in der Methodik einer Metastudien
            begründet, die Durchschnittswerte aus Tausenden wissenschaftlich begründeten Modellierungen bildet. Archer
            schreibt, dass die Klimaveränderungen in der Vergangenheit tendenziell plötzlich geschehen, und die Modelle
            des IPCC sich nicht besonders gut eignen, die Umschlagpunkte der Vergangenheit zu simulieren. Diese Kritik
            besagt, dass die Zukunft sich wesentlich negativer zeigen wird, als die konservativ gestalteten Modelle
            zeigen. Diese Kritik richtet sich am konservativen Vorgehen des IPCC. Unseres Erachtens macht jedoch genau
            dieser Konservativismus des Vorgehens, die Ergebnisse so überzeugend. Die Verantwortlichen des IPCC wollen
            niemand in Panik versetzen, es sind keine Aktivist:innen, sondern sie geben kühl das an, was sie für wie
            wahrscheinlich halten. Nicht mehr, aber auch nicht weniger. </p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="rebound = !rebound">
          <i :class="{ expanded : rebound }" class="fa fa-chevron-right expand-icon"/>
          Was sind Rebound-Effekte?
        </div>
        <div v-show="rebound" class="tipp-content">
          <p>Das Paradox, dass eine erhöhte Effizienz in der Nutzung von z.B. Rohstoffen, nicht zu einer Einsparung,
            sondern zu einer erhöhten Nutzung führt, wurde bereits Mitte des 19. Jahrhunderts entdeckt und von <b>William
              Stanley Jevons</b> in seinem Buch „The Coal Question” (1865) publiziert. Dieses Phänomen, dass
            Effizienzsteigerungen nicht zu Einsparungen führen, findet sich auch heute noch. So führt ein relativ zu
            anderen Kraftfahrzeugen <a
                href="http://www.santarius.de/1028/mythos-gr%C3%BCnes-wachstum/"
                target="_blank">
              spritsparendes Auto bei vielen Menschen dazu, dass sie das Auto öfter und für längere Strecken nutzen</a>.
            Der effizient arbeitende Motor führt auf diese Weise nicht zu Einsparungen, sondern
            bleibt im Verbrauch entweder gleich oder es wird sogar mehr Treibhausgas emittiert als vorher. Wird sogar
            mehr emittiert, dann spricht man von <b>Backfire</b>. Nimmt man die Umweltbelastungen durch häufigere
            Wartungsarbeiten, Mikroplastikbelastungen durch Reifenabrieb und die Umweltkosten zur Bereitstellung des
            Treibstoffs hinzu, ergeben sich weitere Umwelt- und Klimaprobleme. </p>
          <p>Der Nachhaltigkeitsforscher Tilman Santarius, Professor für Sozial-ökologische Transformation und
            nachhaltige Digitalisierung an der TU Berlin, beschäftigt sich <a
                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwia7Oz-k_H6AhUtRvEDHSo0BnEQFnoECDQQAQ&url=https://epub.wupperinst.org/files/4219/ImpW5.pdf&usg=AOvVaw0OzWzStESz_5GHAYnWGDSY"
                target="_blank">
              intensiv</a> mit den heutigen Erscheinungsformen.
            Er unterscheidet u.a. <b>Finanzielle Rebound-Effekte, materielle Rebound-Effekte</b> und <b>psychologische
              Rebound-Effekte</b>. </p>
          <h3>Finanzielle Rebound-Effekte</h3>
          <p>in Beispiel: Ein Energiesparberater oder eine Energiesparberaterin leistet bei einer Familie ihre Arbeit,
            die soziale und ökologische Nachhaltigkeit zum Ziel hat. Durch verändertes Verhalten beim Lüften und andere
            Maßnahmen spart die Familie <b>700 Euro</b> pro Jahr. Jetzt stellt sich die Frage, was die Familie mit dem
            gesparten Geld macht. Es ergeben sich vier Möglichkeiten.</p>
          <ul>
            <li><i>Erstens</i> könnte ein <b>indirekter Rebound-Effekt</b> eintreten: Mit dem gesparten Geld unternimmt
              die Familie
              nach zwei Jahren eine Flugreise in die USA. Die Einsparungen waren auf diese Weise nur finanzieller Art.
              Die Emissionen, die die Familie eingespart hatte, reduzieren sich durch die Emissionen, die durch den Flug
              in die USA verursacht wurden. Je nach Länge des Fluges, Gegenwind, Art der Motors, Flugstil und Jetstream,
              Auslastung des Fluges sowie möglicher <a
                  href="https://www.handelsblatt.com/meinung/homo-oeconomicus/kolumne-homo-oeconomicus-helge-peukert-wie-flugzeugemissionen-weggerechnet-werden/26267052.html"
                  target="_blank">
                Kompensationsmaßnahmen</a>, kann dies sogar zum so genannten Backfire
              führen, was bedeutet, dass die Einsparungen im Haushalt durch die Reise sogar übertroffen werden.
            </li>
            <li><i>Zweitens</i> könnte ein <b>direkter Rebound-Effekt</b> eintreten: Die Familie schafft sich mehr
              elektronische
              Geräte an, nutzt sie intensiver, geht mehr baden als duschen und erhöht die Durchschnittstemperatur der
              Wohnung von 19,5 Grad auf 22 Grad.
            </li>
            <li><i>Drittens</i> kann ein <b>Spareffekt</b> eintreten. Die Familie legt das Geld in einen Fond an. Dabei
              wird das Geld
              an Firmen ausgeschüttet, die mehr oder weniger Treibhausgase bei dem Versuch aus Kapital durch ein
              Unternehmen mehr Kapital zu machen und ein Teil an die Familie als Zinsen ausschütten. Inzwischen gibt es
              auch die Möglichkeit auch in nachhaltige Fonds zu investieren. Letztlich hat die Familie aber auch in
              diesem Falle mehr Geld zur Verfügung und es könnten in einem noch stärkeren Maße direkte oder indirekte
              Rebound-Effekte oder sogar Backfire auftreten, abhängig davon, was die Familie mit dem gesparten und
              zusätzlich durch Zinsen erwirtschafteten Geld unternimmt.
            </li>
            <li><i>Viertens</i> könnte die Familie das Geld zu Zwecken sozialer, ökologischer oder kultureller
              Nachhaltigkeit
              <b>spenden</b>. Nur in diesem Fall ist ein Rebound mit größtmöglicher Wahrscheinlichkeit zu verhindern.
            </li>
          </ul>
          <h3>Materielle Rebound-Effekte</h3>
          <p>Materielle Rebound-Effekte ergeben sich z.B., wenn ökologisch nachhaltigere Neuanschaffungen <b>nicht zu
            einem
            Ersatz von Gegenständen</b> führen, sondern beide genutzt werden. Ein typisches Beispiel ist die Anschaffung
            eines energiesparenden Kühlschranks. Oftmals wird der ältere Kühlschrank nicht entsorgt, sondern wird im
            Keller weiterbetrieben, um z.B. Bier für den Fußballabend kalt zu stellen. So zahlt sich der Erwerb des
            neuen Kühlschranks zwar auf der Ebene der Wohlstandsmehrung aus, jedoch nicht im Sinne einer Energie- und
            damit Treibhausgaseinsparung. </p>
          <h3>Psychologische Rebound-Effekte</h3>
          <p>Ein Beispiel für einen psychologischen Rebound-Effekt besteht im so genannten
            <b>moral-licensening-effect</b>. Der
            Konsum eines nachhaltigen Produkts kann dazu führen, dass an anderer Stelle ein weniger nachhaltiges Produkt
            konsumiert wird. Man legitimiert z.B. den Kurzflug von München nach Hamburg damit, dass man an tollen
            Abenden nur regionale Weine auf den Tisch stellt. Beim psychologischen Reboundeffekt geht es nach innen
            gerichtet, um <b>Gewissensberuhigung</b>. In der Kommunikation mit anderen hingegen geht es um
            <b>Rechtfertigungserzählungen</b>. </p>
          <h3>Was bedeutet Klimaresilienz?</h3>
          <p>Der Begriff Resilienz wird in vielen Wissenschaftsdisziplinen unterschiedlich gebraucht, z.B. in der
            Materialwirtschaft. Dort beschreibt Resilienz, ob und wie sich ein Material nach dem Verbiegen von selbst
            wieder in den Ursprungszustand zurückbiegen kann. In der Biologie wird mit dem Begriff der Resilienz
            beschrieben, ob und in welchem Zeitraum ein Ökosystem nach einer Störung wieder seine volle
            Funktionsfähigkeit erlangen kann. Luft und oberirdische Gewässer können sich dabei relativ schnell erholen.
            Erdreich und Grundwasser hingegen benötigen eine Erholungszeit von Tausenden von Jahren. Die
            Wiederherstellung von verlorener Biodiversität würde Millionen Jahre dauern. </p>
          <p>Der Begriff der Klimaresilienz bezieht sich auf die <b>Resilienz gesellschaftlicher Systeme, wie z.B.
            kritischer Infrastrukturen</b> (z.B. Stromversorgung, Wasserversorgung und Bevölkerungsschutz). Neben
            Bedrohungen wie z.B. Starkregenereignissen vermehren sich bereits jetzt die Schäden durch Waldbrände und
            Mensch und Material sind durch Hitzewellen gefährdet. Als klimaresilient können Unternehmensbauten,
            Privatgebäude, öffentlichen Einrichtungen gelten, die schnell wiederhergestellt werden können. Dafür braucht
            es Versicherungen und Bauweisen, die einen schnellen Wiederaufbau ermöglichen. Es sollte stabiler gebaut
            werden, damit die Gebäude, Extremwetterereignissen besser standhalten. Eine Bauweise, die zu einem
            angenehmen Wohn- und Arbeitsklima beiträgt, zählt hingegen in den Bereich der Klimaadaption, also der
            Anpassung an den Klimawandel. </p>
          <p>Auch das Individuum braucht hinsichtlich der häufiger werdenden Klimaschäden eine verstärkte Resilienz, die
            sich insbesondere auch auf die psychische Konstitution bezieht. Der Verlust von Hab und Gut oder sogar
            nahestehender Menschen muss von den Menschen verkraftet werden. Bei einer Zunahme von heißen Tagen und
            Nächten spielt auch die physische Resilienz eine Rolle. Das Umweltbundesamt warnt: „Modellrechnungen
            prognostizieren für Deutschland, dass zukünftig mit einem Anstieg hitzebedingter Mortalität von 1 bis 6
            Prozent pro einem Grad Celsius Temperaturanstieg zu rechnen ist, dies entspräche <b>über 5.000 zusätzlichen
              Sterbefällen pro Jahr durch Hitze</b> bereits bis Mitte dieses Jahrhunderts.” Wer lieber hört als liest:
            Ein
            Vortrag über <i>Klimawandel und Extremwetter</i> mit Ökonom Ottmar Edenhofer findet sich in der <a
                href="https://www.ardaudiothek.de/episode/hoersaal-deutschlandfunk-nova/klimawandel-von-oekonom-ottmar-edenhofer/deutschlandradio/10750131/"
                target="_blank">
              ARD Audiothek</a>. </p>
          <p>Die Unterscheidung zwischen Klimaanpassung und -resilienz ist nicht ganz trennscharf. Grob könnte man
            sagen, dass es beim Resilienzbegriff darum geht, dass den Klimawandel und dessen Folgen Stand zu halten und
            wenn das nicht gelingen sollte, so <b>schnell wie möglich den Zustand vor dem Schadensfall wiederherstellen
              zu
              können</b>. Der Begriff der Resilienz wird u.a. von Prof. Dr. Matthias Groß kritisiert. Prof. Groß meint,
            dass
            es nicht darum gehen sollte, den Zustand wie er vor einer Katastrophe wiederherzustellen, sondern zu
            versuchen, neue Wege zu gehen und nicht zu versuchen alles so einzurichten, dass man möglichst schnell zum
            <i>business as usual</i> zurückkehren kann, sondern so, dass diese Schadensfälle erst gar nicht eintreten
            können.
            Dies jedoch wird die Gesellschaft ohne Experimentierfreude unter der steten Gefahr des Scheiterns nicht
            leisten können. </p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="adaption = !adaption">
          <i :class="{ expanded : adaption }" class="fa fa-chevron-right expand-icon"/>
          Was bedeutet Klimaanpassung oder Klimaadaption?
        </div>
        <div v-show="adaption" class="tipp-content">
          <p>Die globale Erwärmung wird weitreichende Folgen für Umwelt, Wirtschaft und Gesundheit haben, der Umfang der
            Folgen ist stark von der Menge der CO<sub>2</sub>e-Emissionen abhängig. Das macht eine vorsorgliche
            Anpassungsstrategie
            notwendig. Der Deutsche Bundestag hat 2008 eine Anpassungsstrategie erarbeiten lassen und veröffentlicht
            seitdem regelmäßig Fortschrittsberichte dazu. Der aktuelle Monitoring-Bericht findet sich <a
                href="https://www.umweltbundesamt.de/sites/default/files/medien/1410/publikationen/das_monitoringbericht_2019_barrierefrei.pdf"
                target="_blank">
              hier</a>. </p>
          <p><b>Die Anpassung an den Klimawandel betrifft die Bereiche Menschliche Gesundheit, Bauwesen, Wasserhaushalt,
            Küsten- und Meeresschutz, Boden/Landwirtschaft, Biologische Vielfalt, Wald und Forstwirtschaft, Fischerei
            und Energiewirtschaft, Finanzwirtschaft, Verkehr und Verkehrsinfrastruktur, Industrie und Gewerbe,
            Tourismuswirtschaft, Raumplanung und Bevölkerungsschutz.</b></p>
          <p>An dieser Breite wird deutlich, wie tief ein erwärmter Planet in die Gesellschaftssysteme eingreift und
            eingreifen wird sowie in welchem Ausmaß sich gerüstet werden muss, möchte man die Gesellschaft und ihre für
            Familien, Einzelpersonen, Unternehmen und Institutionen notwendigen Funktionen bewahren. </p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="sustainabilityGer = !sustainabilityGer">
          <i :class="{ expanded : sustainabilityGer }" class="fa fa-chevron-right expand-icon"/>
          Wie nachhaltig lebt Deutschland?
        </div>
        <div v-show="sustainabilityGer" class="tipp-content">
          <p>Nachhaltigkeit ist ein Thema, an dem kaum eine Institution in Deutschland vorbeikommt. Nachhaltigkeit tritt
            als Verkaufsargument, Vereinsziel, Floskel, wissenschaftlicher Begriff, Lebensstil (Konsument:innentyp:
            <b>L</b>ifestyles <b>o</b>f <b>H</b>ealth <b>a</b>nd <b>S</b>ustainability, LOHA) und auch als Chance zu
            unternehmerischen Gewinne auf. </p>
          <p>Auf der <b>materiell messbaren Seite</b> jedoch spiegelt sich der Nachhaltigkeitsdiskurs aus Werbung,
            medialer
            Verhandlung in den sozialen und professionalisierten Medien, politischen Zielsetzungen, wissenschaftlicher
            Publikationen, Demonstrationen und privater Lebensstiländerung <b>noch nicht in ausreichendem Maße</b>
            wider, um
            die Bewohnbarkeit des Planeten für nachfolgende Generationen sicherzustellen. Wir wollen dies hier an
            einigen Kennzahlen aufzeigen, aber auch beim Sektor Energieproduktion und der Stickstoffbelastung zeigen,
            dass <b>auch positive Entwicklungen</b> zu verzeichnen sind. </p>
          <h3>Immer stärkeres Müllaufkommen in vielen Bereichen</h3>
          <p>Der <b>Verpackungsmüll</b> hat zwischen 2000 und 2018 um mehr als <b>3 Millionen Tonnen</b> von 15.620.300
            auf 18.860.600
            Tonnen zugenommen. Die Problematik des Verpackungsmülls zieht sich über die gesamte Wertschöpfungskette bis
            zur Entwertung. Die Verpackungsherstellung benötigt Rohstoffe und Energie, insbesondere die
            Papierherstellung ist enorm energieintensiv. Die Verpackungen werden mehrfach transportiert; erst zwischen
            den Herstellern, dann zum Großhandel, von dort zum Einzelhandel und dann zu den Endverbraucher:innen. Dort
            wird die Verpackung zu Müll, indem die Verbraucher:innen dem Gegenstand keinen Gebrauchswert mehr
            zuschreiben. Dieser Müll muss eingesammelt werden, dann entweder zum energieintensiven Recycling, zur <a
                href="https://www.umweltbundesamt.de/themen/abfall-ressourcen/entsorgung/thermische-behandlung#thermische-behandlung-von-siedlungsabfallen"
                target="_blank">
              thermischen Verwertung</a> (Müllverbrennung) oder zum <a
                href="https://www.umweltbundesamt.de/themen/abfall-ressourcen/grenzueberschreitende-abfallverbringung/grenzueberschreitende-abfallstatistik"
                target="_blank">
              Müllexport</a> transportiert werden. <b>Achten Sie mal darauf, wie viele Lkw auf deutschen Straßen mit
              einem
              großen “A” gekennzeichnet sind. Diese Fahrzeuge fahren nur
              Abfall von A nach B</b>. Sie werden auf Ihren alltäglichen Wegen viele von ihnen entdecken. </p>
          <p>Verhaltensänderungen, weder beim Individuum noch im Handel und auch in der Industrie, schlagen sich bisher
            nicht in mess- und vergleichbaren Zahlen nieder. Was sich jedoch niederschlägt, sind die Folgen der
            Digitalisierung: Waren es laut Umweltbundesamt 2010 noch <b>586.000 Tonnen Elektrogeräte</b>, die im
            Haushaltsmüll
            in Deutschland angefallen sind, waren bereits 2019 <b>768.000 Tonnen; Tendenz: steigend</b>. Dabei sind die
            einzelnen wiederverwertbaren Komponenten im jeweiligen Gerät in solch kleinen Mengen verbaut, dass es viel
            Aufwand und damit Energie benötigen würde, um aus dieser Abfallsorte wieder Rohstoffe zu generieren. Doch es
            sind nicht nur die Endverbraucher:innen, deren Konsum zu immer mehr Müll führt. Auch die <b>Abfälle aus
              Produktion und Gewerbe</b> haben von 47.657.000 Tonnen im Jahre 2000 auf 50.698.000 im Jahr 2019
            zugenommen. </p>
          <p>Abgenommen hingegen hat der <b>Glasmüll</b> aus den Haushalten. Waren es im Jahre 2000 noch 3.443.000
            Tonnen Glas
            aus deutschen Haushalten, schlug diese Müllfraktion 2019 nur noch mit 2.640.000 zu Buche. Ebenfalls
            abgenommen hat die Müllmenge beim gut wiederverwertbaren <b>Altpapier</b>. Hier schlägt die Digitalisierung
            anscheinend positiv zu Buche (E-Mail statt Brief, papierloses Büro). </p>
          <h3>Individualmobilität weiter auf dem Vormarsch</h3>
          <p>Die Liebe der deutschen Bevölkerung zum Auto scheint ungebrochen, zumindest, wenn man die Zahl der
            zugelassenen Pkw, die zahlreichen werbefinanzierten Kfz-Dokutainment-Formate im Privatfernsehen, die
            Veränderungen in der Nachfrage nach bestimmten Fahrzeugarten und die Haltung der Fahrer:innen in Augenschein
            nimmt. Dies schlägt sich in der Gesamtbilanz des Verkehrssektors nieder: <b>Waren es im Jahre 2010 noch 153
              Millionen Tonnen CO<sub>2</sub>-Äquivalente, die im Verkehrssektor in Deutschland emittiert worden sind,
              steigerte
              sich
              die Menge an Treibhausgasen im Jahre 2018 auf 162 Millionen Tonnen</b>. Die Fahrzeuge werden zwar immer
            effizienter, was den Spritverbrauch angeht, doch dieser technische Fortschritt schlägt sich kaum im
            Gesamtverbrauch wieder. <b>1995 wurden 47.263 Millionen Liter Diesel und Benzin verbrannt, um Pkw und Kombis
              anzutreiben. 2019 waren es 0,535% weniger</b>, bei einem enorm angestiegen Anteil von durch Diesel
            angetriebene
            Fahrzeuge, die für eine Erhöhung des Stickstoffdioxid-Anteils in deutschen Städten sorgten, und so z.B. in
            <b>Stuttgart</b> europäisch festgelegte Grenzwerte überstiegen haben. Die Fahrzeuge werden immer schwerer
            und haben
            immer mehr Funktionen. Ein VW-Käfer konnte fahren, ein heutiges Auto ist ein Hightech-Produkt, das von
            Internetfähigkeit über die Massagefunktion bis hin zu ausgeklügelten Sicherheitsarchitekturen und vor allem
            wesentlich mehr PS-Leistung viel mehr zu bieten hat. Das bedeutet Gewichtszunahme und erhöhten
            Energieverbrauch. Prof. Dr. Maja Göpel weist darauf hin, dass ein VW-Käfer Mitte der Fünfziger Jahre 7,5
            Liter Benzin auf 100km schluckte und der in den neunziger Jahren aufgelegte Beetle kaum weniger benötigte.
            Die soziale Konstruktion von Fortschritt ist nicht zwingend gleichlaufend mit ökologischen
            Nachhaltigkeitszielen. </p>
          <p>Der Trend geht nicht zu kleineren und sparsamen Pkw. Zwischen 2009 und 2020 stieg der Bestand von
            sogenannten <b><i>Sports- and Utilitity Vehicles</i> (SUV)</b> um 122%. Eine aktuelle <a
                href="https://www.tuev-verband.de/?tx_epxelo_file%5Bid%5D=878103&cHash=058225fadc61f486b7a51dedcaa8b551"
                target="_blank">
              Umfrage</a> zeigt, dass das
            Umweltbewusstsein der Fahrer:innen nicht besonders stark ausgeprägt ist. Für 41% spielen Klima- und
            Umweltaspekte „keine“ oder „eher eine kleine Rolle“ und 5% entschieden sich für „Weiß nicht/Keine Angaben”.
            Nur 15% gaben an, dass bei ihren individuellen Mobilitätsentscheidungen klima- und umweltpolitische Aspekte
            „eine große Rolle” spielen würden. Das könnte zu wenig sein, um ausreichend nachfragegetriebene ökologische
            Produktionsumstellungen zu erreichen. </p>
          <p>Nicht nur der Betrieb, auch die Produktion eines Autos verbraucht sehr viel Energie und Rohstoffe. Auch in
            der Produktion steigen die Zahlen an: 2017 waren in Deutschland 45,8 Millionen Pkw angemeldet. 2021 waren es
            bereits <b>etwa 2,4 Millionen Pkw</b> mehr. Bei einer angenommenen Durchschnittslänge 4,60 Metern pro Pkw
            könnten
            allein diese zusätzlichen Fahrzeuge Luftlinie einen Stau vom Rathausplatz in <b>Ingolstadt bis hinter
              Jakarta</b>
            (Indonesien) bilden (wenn da nicht sehr viel Wasser dazwischen wäre). </p>
          <p>Kraftfahrzeuge sind auch der Hauptemittent von Mikroplastik: Forscher:innen des <a
              href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjc9YPHjfH6AhUTRfEDHapdAJsQFnoECBAQAQ&url=https://www.researchgate.net/publication/325989261_Kunststoffe_in_der_Umwelt_Mikro-_und_Makroplastik&usg=AOvVaw2YWB87Vn_Y_jyecEUJl1Dc"
              target="_blank">
            Fraunhofer Instituts</a>
            schätzen, dass ca. <b>1,2 Kilogramm pro Kopf und Jahr allein durch Reifenabrieb</b> (Pkw, Lkw, Motorräder,
            Fahrräder und sonstige Reifen) in Deutschland emittiert werden. Zum Vergleich: In derselben Studie werden 19
            Gramm pro Kopf und Jahr als Mikroplastikemissionen durch Kosmetik angegeben, die noch dazu <a
                href="www.replawa.de/wordpress/wp-content/uploads/2021/09/Fuhrmann-et-al_Mikroplastik-Emissionen-aus-Kl%C3%A4ranlagen_KA-9-2021.pdf"
                target="_blank">
              im rückholfähigen Technosystem des Abwassersystems</a> bleiben, während das Mikroplastik am Straßenrand
            beim momentanen
            technologischen Stand der Produktionsmittel nicht mehr aus der Umwelt entfernt werden kann. </p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="eCarsAlternative = !eCarsAlternative">
          <i :class="{ expanded : eCarsAlternative }" class="fa fa-chevron-right expand-icon"/>
          Das Elektroauto als nachhaltige Alternative?
        </div>
        <div v-show="eCarsAlternative" class="tipp-content">
          <p>Produktion und Verkauf von Elektroautos verzeichnen hohe Zuwachsraten: 2017 waren 34.022 rein elektrisch
            betriebene Kfz in Deutschland zugelassen. Zum 01.01.2022 waren es bereits 618.460. Das politisch ausgegebene
            Ziel des Wirtschaftsministeriums, bis zum Jahre 2020 eine Million E-Autos auf deutschen Straßen zu sehen,
            wurde damit zwei Jahre später immer noch um über 38% verfehlt. Stattdessen <b>wurden 2020 und 2021 immer
              noch
              wesentlich mehr Pkw mit Verbrennermotor neu zugelassen als Pkw mit reinem E-Antrieb.</b></p>
          <p>Es gilt zu beachten, dass der Bezug von Rohöl zur aktuellen Kraftstoffproduktion (Diesel, Benzin) ebenso
            problematisch ist; schließlich muss das Öl über weite Strecken transportiert werden. Durch die Nutzung von
            Strom als Energieträger kann der Endenergiebedarf in Form von flüssigen Kraftstoffen um knapp den Faktor 3
            reduziert werden (aufgrund des schlechten Wirkungsgrades der mit Verbrennungsmotor betriebenen Fahrzeuge im
            Vergleich zum E-Fahrzeug). </p>
          <p>Doch auch die Individualmobilität mit E-Autos wirft noch mehr Fragen auf als die Art (z.B. Kohle, Atom,
            Wind, Sonne, Biomasse oder Erdgas), wie der Strom für die individuelle Mobilität gewonnen wurde. Eine noch
            nicht geklärte Frage ist die preislich abbildbare langfristige Versorgung mit Rohstoffen für den Bau von
            rein elektrisch betriebenen Fahrzeugen. In der wissenschaftlichen Literatur wird mit <a
                href="https://link.springer.com/content/pdf/10.1007/978-3-658-29730-5.pdf"
                target="_blank">
              unterschiedlichen</a> <a
                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiNt_jT5IL7AhUKtqQKHeIrAJYQFnoECBcQAQ&url=https://www.isi.fraunhofer.de/content/dam/isi/dokumente/cct/2020/Faktencheck-Batterien-fuer-E-Autos.pdf&usg=AOvVaw19rUH_T_7nJ37sE1FzBIOP"
                target="_blank">Reichweiten</a> der <a
                href="https://www.blaetter.de/ausgabe/2019/november/produktiver-gruener-friedlicher"
                target="_blank">
              Rohstoffausbeute</a> (u.a. Lithium) gerechnet. <a
                href="https://link.springer.com/content/pdf/10.1007/978-3-658-29730-5.pdf"
                target="_blank">
              „Die heute bekannten Kupferreserven, 830 Mio. Tonnen, reichen bei kontinuierlich fortgeschriebenem
              Verbrauch für weitere 40 Jahre.“</a> In dieser Zeit ist -
            bei gleichbleibendem Anstieg der Nachfrage nach E-Autos - mit erheblichen Eingriffen in die Natur zu
            rechnen, die u.a. die lokale Versorgung mit Süßwasser gefährden kann. Der <b>Abbau des Lithiums</b> zum
            Beispiel,
            das im Moment noch notwendig für die Akkus benötigt wird, senkt auf weiten Flächen in Südamerika,
            insbesondere in Chile, den Grundwasserspiegel ab und gefährdet im Zusammenspiel mit der globalen Erwärmung
            eine durchgängig gesicherte Trinkwasserversorgung der in den Abbaugegenden lebenden Bevölkerung. Dies sind
            jedoch <b>Herausforderungen, denen mit sozialen und technischen Innovationen begegnet werden kann</b>. Dazu
            braucht
            es jedoch einen <b>gemeinsamen Willen</b> von Wirtschaft, Politik, Konsument:innen und Wähler:innen. </p>
          <h3>Flugreisen mit und ohne Flugscham </h3>
          <p>Durch die Medien geisterte mit dem Aufkommen der Fridays for Future-Bewegung der Begriff der
            <b>Flugscham</b>. Mit diesem Begriff wollte man einen Gefühlszustand während eines Fluges beschreiben.
            Dieser unangenehme
            Gefühlszustand schlägt sich jedoch nicht in einer Verhaltensänderung wieder. Waren es in Deutschland 1991
            noch 79.022.000 Fluggäste, konnte sich die Flugbranche im Jahre 2019 über <b>mehr als dreimal so viele
              Fluggäste</b> freuen. 2019 bestiegen in Deutschland 248.457.000 Menschen ein Flugzeug. 54% dieser Flüge
            überwanden eine Distanz von weniger als 1.000 Kilometer, hätten also mit nur wenig Zeitverlust mit der Bahn
            oder mit einem Pkw erledigt werden können. Die Lufthansa reagiert auf den öffentlichen Druck bereits und
            strich zumindest die Strecke zwischen München und Nürnberg (Entfernung Luftlinie: 150,44 km). Allerdings ist
            nicht davon auszugehen, dass die dafür verwendeten Maschinen am Boden bleiben. Um einerseits die Zirkulation
            des Kapitals nicht zu unterbrechen, die Wertschöpfungsketten stabil zu halten und das politisch gewollte
            Wirtschaftswachstum zu erreichen, sollte eine möglichst hohe Auslastung der Flugzeuge gegeben sein.
            Flugkilometer wirken sich auf der anderen Seite <a
                href="https://www.umweltbundesamt.de/bild/vergleich-der-durchschnittlichen-emissionen-0"
                target="_blank">
              enorm stärker</a> auf die Menge der Treibhausgasemissionen aus,
            denn z.B. Bahnfahren. </p>
          <p>Für die noch angebotenen Destinationen gilt: <b>Sich schämen reicht sicherlich nicht</b>, möchte man im
            Bereich
            der Luftfahrt Treibhausgasemissionen einsparen. Auch bringt eine Kosten-Nutzen-Rechnung keinen Klimaschutz:
            Oft wird bei Fernreisen argumentiert, dass es umso klimaschonender sei, je länger man am Zielort verbleiben
            würde. Dies ist nicht der Fall. Die Menge an Treibhausgasen pro Flug bemisst sich an Art des Treibstoffs,
            Flugstrecke, Jetstream, Auslastung des Flugzeugs, Geschwindigkeit und ändert sich nicht durch eine
            kürzere/längere Aufenthaltszeit am Reiseziel. Im Moment fliegen alle eingesetzten Flugzeuge aus Deutschland
            mit Kerosin. <b>Kerosin</b> wird im Gegensatz zum Sprit für das private oder gewerblich genutzte
            Kraftfahrzeug
            <b>nicht besteuert</b>. Daher sind die Anreize für die Flugzeughersteller geringer, alternative
            Antriebstechnologien für CO<sub>2</sub>-arme Treibstoffe zu entwickeln. <a
                href="https://www.faz.net/aktuell/wirtschaft/klima-nachhaltigkeit/airbus-verspricht-wasserstoff-flugzeug-als-kerosin-ersatz-17825842.html"
                target="_blank">
              Frühestens 2035</a> soll die <b>Entwicklung eines
              Wasserstoffflugzeugs</b> abgeschlossen sein. Aber auch solche Flugzeuge sind nur dann klimaneutral, wenn
            der
            Wasserstoff mit erneuerbaren Energien produziert worden ist. Und ob dies technisch realisierbar ist,
            ausreichend grüner Wasserstoff produziert werden kann und diese Motoren und der Treibstoff vom Markt
            angenommen wird, stellt noch eine Wette auf die Zukunft dar. </p>
          <h3>Wie kommt man nun mit möglichst geringer Umweltbelastung von A nach B?</h3>
          <p>Wer sich sozial und ökologisch fortbewegen möchte, der sollte wenn möglich auf <b>Muskelkraft</b>
            zurückgreifen,
            was sich übrigens auch gesundheitlich positiv auswirkt, oder auf <b>terrestrische Massenverkehrsmittel</b>
            umsteigen. Kants kategorischer Imperativ besteht in der Maxime, dass man stets so handeln solle, dass man
            wollen kann, dass das eigene Handeln zum allgemeinen Gesetz wird. Wäre Individualmobilität ein allgemeines
            Gesetz und alle etwa 8 Milliarden Menschen würden auf ein privates Kfz bestehen, dann wäre Klimawandel nicht
            mehr zu bremsen. Nun kann man sich selbst fragen, ob man das wollen kann. Können Sie sich dazu durchringen
            das zu wollen? </p>
          <p>In der Region geht die <a
              href="www.deutschlandfunkkultur.de"
              target="_blank">
            <b>Stadt Pfaffenhofen</b> mit einem kostenlosen öffentlichen Nahverkehr</a> voran. Dies
            befürworten in einer <a
                href="https://www.tuev-verband.de/?tx_epxelo_file%5Bid%5D=878103&cHash=058225fadc61f486b7a51dedcaa8b551"
                target="_blank">
              Umfrage des TÜV</a>Umfrage des TÜV 82% der Deutschen. Fragen Sie ruhig mal in Ihrer Kommune nach, ob
            dieses Vorbild Schule machen könnte?</p>
          <p>Überlegen Sie immer welche Wege mit einem Fahrrad oder sogar Lastenrad bewältigen könnten, ein bestehendes
            Fahrzeug emittiert nur dann CO<sub>2</sub>, wenn es betrieben wird. Falls Sie noch einen Benziner oder
            Diesel Ihr Eigen
            nennen, schaffen Sie diesen nicht ab, sondern fahren Sie diesen, bis sich die Reparaturen nicht mehr lohnen,
            denn ein Neubau eines Autos ist sehr energieintensiv. Überlegen Sie bei einer Neuanschaffung erstens, ob Sie
            sich diese Neuanschaffung durch <b>Car-Sharing-Modelle</b> verhindern lassen könnte und zweitens - falls auf
            Individualmobilität z.B. in Kleinstädten und auf dem Lande nicht verzichtet werden kann - wie viele
            Kilometer sie eigentlich tagtäglich zurücklegen, um so bei der Anschaffung eines E-Kfz die für Sie richtige
            <b>Akkugröße zu ermitteln. So können bei der Herstellung</b>, der Rohstoffbedarf auf ein notwendiges Maß
            begrenzt
            werden und auch zukünftige Generationen können noch Akkus bauen. </p>
          <p>Die meisten Menschen dieser Welt haben noch nie ein Flugzeug bestiegen und in der Geschichte der Menschheit
            ist der Gedanke, dass man jedes Jahr eine Urlaubsreise mit einem Flugzeug machen müsse, sehr neu. <b>Entdecken
              Sie Deutschland</b>, vielleicht sogar auf dem Fahrrad, und sehen Flugreisen als das, was sie sind, etwas
            sehr
            Besonderes.</p>
          <h3>Die grüne Revolution </h3>
          <p>Erdgeschichtlich betrachtet ist die Menschheit nur einen Fingerschnipp von der Ära entfernt, als nahezu
            alle Menschen in der Landwirtschaft beschäftigt waren. Durch die so genannte <b>grüne Revolution</b>, die
            spätestens in den 1960er Jahren begann, war es den Menschen durch Hochleistungssaatgut, spezialisierte
            Düngemittel, hochwirksame Schädlingsbekämpfungsmittel, den breiten Einsatz von schwerem Gerät,
            professionalisiertem Wassermanagement und Skaleneffekten durch die Akkumulation von Bodenkapital möglich,
            viele Arbeitskräfte für Industrie und Dienstleistungen freizusetzen, was ein wichtiger Faktor für
            Wirtschaftswachstum und Wohlstandszugewinn in den Ländern des globalen Nordens war. Für den globalen Süden
            bedeutete dies einen Etappensieg im Kampf gegen Hungersnöte und senkte die Kindersterblichkeit enorm.</p>
          <p>Diese positive Entwicklung blieb nicht ohne Nebenfolgen, z.B. stieg die Weltbevölkerung und der
            Flächenanspruch an, die Biodiversität nahm ab, die Stickstoffeinträge in Böden, Flüssen und in den Meeren
            erhöhte sich, als auch die prekäre Situation der Menschen in ärmeren Ländern verfestigte sich, obgleich der
            Reichtum an Nahrungsmitteln global zunahm, da die lokalen Landwirte, z.B. in afrikanischen Staaten mit den
            billigen Waren aus dem Ausland auf dem Markt oftmals nicht mehr mithalten konnten. Der Ökonom Joseph
            Schumpeter nennt dies „schöpferische Zerstörung“, bei der sich die besseren und beliebteren Waren auf dem
            Markt durchsetzen und die anderen entschwinden, wie in einem Selbstreinigungsprozess. Durch diese Zerstörung
            lokaler Wirtschaften, konnte eine Neuordnung stattfinden und die industriellen Innovationen setzten in der
            Ernährung der Menschen durch, doch auch hier ergaben sich unerwünschte Nebenfolgen, wie z.B. <a
                href="https://edoc.ub.uni-muenchen.de/24883/1/Sebelefsky_Christian.pdf"
                target="_blank">
              eine zunehmende Fettleibigkeit</a>, die sich im Moment am stärksten in Mexico zeigt. </p>
          <h3>Ernährung: Mehr von Allem</h3>
          <p>In der Ernährung zeigen sich immer stärkere Ausdifferenzierungen. Es gibt inzwischen sogar personelle
            Identitäten, die sich an Ernährungsstilen orientieren. Es gibt mehr hochverarbeitete Lebensmittel, es gibt
            mehr Bio-Lebensmittel, es gibt immer mehr vegane und vegetarische Fleischersatzprodukte, es gibt immer
            ausgefeiltere Grillapparaturen für den heimischen Garten oder Balkon. Gleichzeitig stammt, die große
            Mehrheit der in Deutschland verzehrten Tiere aus der Massentierhaltung und für deren Schlachtung werden
            oftmals Leiharbeiter aus den östlichen Staaten der EU nach Deutschland gebracht, <a
                href="https://duepublico2.uni-due.de/servlets/MCRFileNodeServlet/duepublico_derivate_00074107/04_Birke_Fleischindustrie.pdf"
                target="_blank">
              deren Arbeitsbedingungen kritisiert werden</a>. </p>
          <p>Von den 2020 in Deutschland gehaltenen 11.274.534 Rindern lebten und starben unter den Bedingungen
            ökologischer Wirtschaftsweise 861.272 Nutztiere, was ca. <b>7,6 Prozent</b> entspricht. In der Produktion
            von
            Schweinefleisch waren es nur <b>0,8%</b> von 26.299.994 Schweinen auf deutschen Höfen. Die Hühner liegen mit
            einem
            Prozentanteil von <b>5,2%</b> zwischen den beiden Spezies Schwein und Rind. <b>Über ökologische Tierhaltung
              wird viel
              geredet, aber an der Tagesordnung ist diese in Deutschland keineswegs</b>. Nur wenige Tiere kommen also in
            den
            Genuss eines einigermaßen erträglichen Lebens als Nutztier. </p>
          <p>Es wird viel über vegane Ernährung gesprochen, sich aber vorwiegend immer noch fleischlastig ernährt.
            Zwischen 2000 und 2018 hat sich die Erwähnung des Begriffs „Veganismus” in den von google.books gelisteten
            Büchern <b>um das 25fache gesteigert</b>. Im gleichen Zeitraum hat sich die Menge des verzehrten Fleisches
            pro Jahr
            <b>um ca. 400 Gramm von 61,5 kg auf 61,1 kg verringert</b>. Das ist etwa das Gewicht von 2,5 Scheiben eines
            Rindersteaks in einem guten Restaurant. Trotz aller innovativen Produkte, Ernährungs- und Werbetrends ist
            hier von Veränderung auf materiell messbarer Seite noch nicht ausreichend zu bemerken, um für Klima- und
            Tierwohl einen relevanten Unterschied zu machen. </p>
          <p>Paradoxerweise ist der Hang der Deutschen zu konventionell produzierten Fleischerzeugnissen <b>zumindest
            monothematisch für den Klimaschutz</b> von Vorteil: <a
              href="https://www.sciencedirect.com/science/article/abs/pii/S0048969716322410?via=ihub"
              target="_blank">
            Biologisch produziertes Rindfleisch erzeugt 6,41 kg mehr
            CO<sub>2</sub>-Äquivalente pro Kilogramm Lebendgewicht des Rinds über die gesamte Wertschöpfungskette als
            konventionell
            erzeugtes</a>. Bei der Milchproduktion ergeben sich ähnliche Werte: Laut dem <a
              href="https://www.ioew.de/fileadmin/_migrated/tx_ukioewdb/IOEW-SR_186_Klimawirkungen_Landwirtschaft_02.pdf"
              target="_blank">
            Institut für Ökologische Wirtschaftsforschung</a> verursacht konventionell erzeugte Milch pro Liter 1,18 kg
            CO<sub>2</sub>-Äquivalente, während in
            der ökologischen Landwirtschaft 1,21 kg anfallen, was u.a. auf die höhere Milchleistung pro konventionell
            gehaltene Kuh zurückzuführen ist. Bei einem Pro-Kopf-Verbrauch von 47,8 kg Milch im Jahre 2021 in
            Deutschland machen auch solch scheinbar kleine Differenzen einen Unterschied im Kampf gegen die globale
            Erhitzung des Planeten. Hier müssen sich die carnivoren Konsument:innen entscheiden, was ihnen wichtiger
            erscheint, <b>Tierwohl und Biodiversität</b>, die durch ökologische Landwirtschaft gestärkt wird, <b>oder
              Klimaschutz</b>.
            Es geht hier nicht ohne entsprechende Änderung des individuellen Ernährungsverhalten, um im
            Ernährungsbereich die CO<sub>2</sub>-Emissionen zu senken.</p>
          <p><b>Tipp:</b> Man kann sich auch nach veganen Alternativen umsehen, wenn einem das schmeckt. Das ist gut für
            Tier, Biodiversität <b>und</b> Klima.</p>
          <h3>Landwirtschaft: Abnehmende Stickstoffbelastung – Aber keine Entwarnung</h3>
          <p>Werfen wir einen Blick auf die <b>Stickstoffbelastung der Böden in Deutschland</b>. Es ist eine
            Verbesserung zu
            vermelden. Allerdings befindet man sich immer noch auf einem bedenklich hohen Niveau. Die ökologischen
            Belastungsgrenzen durch Stickstoffeinträge wurden <a
                href="https://www.umweltbundesamt.de/daten/flaeche-boden-land-oekosysteme/land-oekosysteme/ueberschreitung-der-belastungsgrenzen-fuer-0"
                target="_blank">
              2015 nur noch auf 68%</a> der vermessenen Flächen
            überschritten. Dennoch ist laut Aussagen des Umweltbundesamtes <b>nicht mit einer schnellen Erholung der
              Bodenflächen zu rechnen</b>: „Im Rückschluss ist auch die Erholung des Ökosystems auf vorindustrielles
            Niveau
            sehr langwierig, wenn nicht sogar eine irreversible Schädigung des Ökosystems vorliegt.” </p>
          <h3>Emissionen in der deutschen Industrie: Seit 2005 kaum Bewegung </h3>
          <p>Die Industrie betreibt viel Public Relation, was die Ökologisierung der Produktion angeht. Dies reicht von
            Werbespots im Fernsehen bis zur Gründung von Lobbyvereinen, die auf die öffentliche Meinung Einfluss nehmen
            sollen. Dr. Sonja Knobbe und ihre Kolleg:innen von der Ruhr-Universität Bochum haben die
            Kommunikationsstrategien von energieintensiven Unternehmen in Nordrhein-Westfalen untersucht und kommen <a
                href="https://www.degruyter.com/document/doi/10.1515/9783839450710-013/pdf"
                target="_blank">
              zu folgendem Schluss</a>:</p>
          <div class="tab"><i>“Bezüglich eigener strategischer Beiträge verweist die Industrie zumeist auf Aktivitäten
            in der Zukunft,
            führt diese aber nur selten konkret aus, zum Beispiel indem genaue Zahlen und/oder Zeithorizonte bzw.
            Umsetzungsfristen genannt werden. Zumeist geht es um Absichtserklärungen, weitere Einsparmaßnahmen oder
            Forschungsvorhaben durchzuführen bzw. neue Technologien einzusetzen. Darüber hinaus wird häufig auf
            kommunikative Arbeit, zum Beispiel über die Etablierung von Netzwerken, Forschungskreisen, Beratungs- und
            Austauschorganen gesetzt. Konferenzen und andere Austausch-Plattformen spielen in dieser Hinsicht eine
            vorgeordnete Rolle.” </i></div>
          <p>Dies spiegelt sich auch in den Zahlen wider, die auf der Website des Umweltbundesamtes abgerufen werden
            können: <b>2005 emittierte die deutsche Industrie 191 Millionen Tonnen CO<sub>2</sub>-Äquivalente</b>, 2010
            188
            Millionen
            Tonnen und 2013 179 Millionen Tonnen. Paradox erscheint: Mit der Zunahme von Beiträgen zum
            Nachhaltigkeitsdiskurs stiegen auch die materiell messbaren Treibhausgasemissionen an. 2015 waren es 187
            Millionen Tonnen und <b>2017 sogar 198 Millionen Tonnen</b>.</p>
          <p>Trotzdem sind die Ziele, die die Politik für die Wirtschaft vorsieht, sehr ambitioniert: Bereits 2023 – so
            schreibt <a
                href="www.gesetze-im-internet.de/ksg/KSG.pdf"
                target="_blank">
              das deutsche Klimaschutzgesetz</a> vor – sollen im Sektor Industrie nur noch 173 Millionen Tonnen
            freigesetzt werden. Will dies die deutsche Industrie schaffen, dann steht sie vor großen Herausforderungen.
            <b>Bis 2030 sollen nur noch 118 Millionen Tonnen ausgestoßen werden</b>, bei gleichzeitigem ökonomischen
            Wachstumswünschen der Politik und der Wirtschaft, um Refinanzierung, Schuldentilgung, Investitionen,
            Arbeitsplatzsicherung, Steuerzahlungen und Gewinnausschüttungen zu ermöglichen. Die Aushandlungsprozesse
            dieser Zielkonflikte könnten sich durch technischen Fortschritt auflösen. Wenn dies nicht der Fall sein
            sollte und es der Umsetzung innovativer Technologien, Schwierigkeiten geben sollte, da diese z.B. nicht
            marktfähig ist, dann werden strukturelle Umbauten des Wirtschaftssystems unter Umständen nicht zu umgehen
            sein, möchte man von den vier Säulen der Nachhaltigkeit keine vernachlässigen. </p>
          <p>Die momentan schwierige wirtschaftliche Situation durch Pandemie, Energiekrise und Ukraine-Krieg könnte
            dazu beitragen, dass die Klimaziele formal erreicht werden. Dies wird dann jedoch durch ungewollte exogene
            Faktoren erreicht und nicht durch eine strukturelle Transformation, die auch eine ökonomisch prosperierende
            Gesellschaft mit steigendem Wohlstand in Einklang mit der Einhaltung der planetaren Grenzen zu bringen
            vermag.</p>
          <p><b>Es gibt noch viel zu tun</b> und viele Expert:innen glauben, dass in der deutschen Industrie ein
            Umdenken im
            Schwange sei. Es gilt hier, dass man nicht zweifeln sollte, ohne zu hoffen und auch nicht hoffen, ohne zu
            zweifeln. </p>
          <h3>Energiewirtschaft: Der Sektor, in dem Einsparungen Realität geworden sind </h3>
          <p>Bei der Stromproduktion konnten große Erfolge erzielt werden. Lagen die Emissionen 1990 noch bei 460
            Millionen Tonnen CO<sub>2</sub>-Äquivalenten, konnten diese nahezu kontinuierlich auf 305 Millionen Tonnen
            im Jahre
            2018 abgesenkt werden. Der Ausstieg aus der Kohleverstromung, vor allem im Ruhrgebiet, im Rheinischen Revier
            und in der Lausitz haben dazu beigetragen. Der Anteil der Erneuerbaren Energien (Wind, Sonne, Wasserkraft,
            Biomasse) hat ebenfalls dazu beigetragen, <b>dass 2018 155 Millionen Tonnen von Treibhausgasen weniger als
              im
              Referenzjahr 1990 emittiert werden konnten</b>. Daraus erwächst eine Chance. In den Sektoren Verkehr und
            Industrie konnten die Emissionen - aus welchen Gründen auch immer - nicht gesenkt werden. Dies führt zu dem
            Schluss, <b>dass möglichst viele Abläufe elektrifiziert werden sollten</b>, um eine Reduktion von
            Treibhausgasen zu
            ermöglichen. Dafür braucht es erstens ein gut ausgebautes Netz an Ladestationen für Kraftfahrzeuge, zweitens
            eine technisch umsetzbare und wirtschaftlich lukrative Form der Energiespeicher (z.B. Wasserstoff) und
            drittens Stromtrassen, die den Windstrom aus dem Norden der Bundesrepublik Deutschland in den Süden bringen
            sowie viertens eine veränderte Gesetzeslage, um den Ausbau der Erneuerbaren Energien schneller
            voranzubringen, als dies in den letzten Jahren der Fall gewesen ist. Denn natürlich würde die Nachfrage nach
            Strom steigen, möchte man alle Individualmobilität, viele Prozesse in der Industrie und der Wärme- und
            Kälteproduktion elektrifizieren und den Luftraum mit Flugzeugen, betrieben mit grünem Wasserstoff, erobern,
            sowie weiterhin eine quantitativ wachsende Wirtschaft haben. </p>
        </div>
      </div>


      <div class="tipp">
        <div class="tipp-header" @click="climateProtection = !climateProtection">
          <i :class="{ expanded : climateProtection }" class="fa fa-chevron-right expand-icon"/>
          Wie ist der Stand des weltweiten Klimaschutzes?
        </div>
        <div v-show="climateProtection" class="tipp-content">
          <p>Auf der Klimakonferenz von Paris im Jahre 2015 einigten sich alle teilnehmenden Länder darauf, den
            Temperaturanstieg von vorindustrieller Zeit bis in das Jahr 2100 unter 2,0 Grad zu halten. Wenn möglich,
            sollte sogar eine 1,5 Grad-Grenze eingehalten werden. Dazu ist es unabdingbar, dass der Ausstoß des
            klimawirksamen Gases CO<sub>2</sub> eingeschränkt wird. Dies gelang bislang nicht. <b>2015 emittierte die
              Welt ca. 32,1
              Gigatonnen CO<sub>2</sub>. 2021 lagen die Emissionen bei ca. 36,3 Gt CO<sub>2</sub></b>. </p>
          <p>Die Ursachen dieses Anstiegs liegen unter anderem in der Stromproduktion, obgleich dort Chancen der
            Dekarbonisierung liegen würden, <a
                href="https://www.umweltbundesamt.de/sites/default/files/medien/384/bilder/dateien/8_tab_thg-emi-kat_2022.pdf"
                target="_blank">wie das Beispiel Deutschland verdeutlicht</a>. Jedoch hat sich zwischen 1980 und
            2020 der weltweite Verbrauch von Kohle zur Energiegewinnung fast verdoppelt. Auch der zunehmende Wohlstand
            in
            der kommunistischen Volksrepublik <b>China</b> hat großen Anteil daran, dass der weltweite Ausstoß zunimmt.
            China
            hatte 1998 einen Pro-Kopf-Ausstoß von zwei Tonnen CO<sub>2</sub> pro Jahr. <b>2020 waren es dann bereits
              10,1
              Tonnen</b>.
            Nun
            hat die Armut in China deutlich abgenommen, was anscheinend unvermeidlicher Weise mit einem verstärkten
            Konsum
            einhergeht. Armutsbekämpfung und Klimaschutz scheinen im momentanen Wirtschaftssystem und <i>mindset</i> der
            Menschen
            noch ein Zielkonflikt zu sein. Dies eröffnet das Paradox, <b>dass man meint, dass man für einen ökologischen
              Konsum ein hohes Einkommen benötigt, aber die Länder mit höherem Einkommen trotz aller finanziellen
              Möglichkeiten einen größeren ökologischen Fußabdruck haben</b>. </p>
          <p>Zwischen 1990 und 2014 sind die jährlichen Emissionen kontinuierlich gestiegen. Rechnet man noch die
            anderen
            Treibhausgase neben Kohlenstoffdioxid dazu, <b>dann nahmen die jährlichen Emissionen von 34 im Jahre 1990
              auf
              49
              Gigatonnen im Jahre 2014 zu (ca. +44%)</b>. Während die Erkenntnisse um die Gefahren des Klimawandels mit
            jedem
            Sachstandsbericht des IPCC zunahmen, nahm auch die Geschwindigkeit des Zuwachses an Emissionen zu. Zwischen
            1990 und 2000 nahmen die Emissionen um durchschnittlich 0,8% pro Jahr zu. <b>Zwischen 2000 und 2014 nahmen
              die
              Treibhausgasemissionen durchschnittlich um 2,3% zu</b>. Danach verlangsamte sich der Anstieg, weil die
            Volksrepublik China die Nutzung von Kohle verringerte und andere klimaschonende Maßnahmen durchführte. Ab
            2016
            beschleunigte sich der Anstieg wieder durch die Inbetriebnahme von Kohlekraftwerken in <b>Indien</b>, der
            <b>Türkei</b> und
            mehreren <b>afrikanischen Staaten</b>. Lange Zeit waren es die prosperierenden Staaten des globalen Nordens,
            die das
            meiste Kohlenstoffdioxid und andere Treibhausgase emittiert haben. Durch die Externalisierung schmutziger
            Industrien in den globalen Süden und postkolonialer wirtschaftlicher Aufholprozesse haben sich die
            Emissionen
            in Asien von 1990 bis 2014 mehr als verdoppelt, <b>während die Emissionen in den OECD-Ländern nahezu
              stagnierten</b>. </p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="nuclear = !nuclear">
          <i :class="{ expanded : nuclear }" class="fa fa-chevron-right expand-icon"/>
          Ist Kernenergie ein probates Mittel zum Schutz des Klimas?
        </div>
        <div v-show="nuclear" class="tipp-content">
          <p>Die Gefahren für die Bergarbeiter beim Uranabbau, den Eingriff in lokale Ökosysteme durch den Abbau, die
            Finanzierung autoritärer Systeme, die Treibhausgasemissionen durch Aufbau, Betrieb und Rückbau von
            Kraftwerken, den Widerstand der lokalen Bevölkerung beim Bau von End- und Zwischenlagern, die Risiken von
            Havarien und die Ewigkeitskosten für nachfolgende 40.000 bis 120.000 Generationen muss man bereit sein, in
            Kauf zu nehmen, möchte man die Energiegewinnung auf Kernkraft fokussieren. </p>
          <p>Lässt man sich auf diese Gefahren und Risiken ein, gewinnt man Strom, der als Momentaufnahme die
            Pro-Kopf-Emissionen im Bereich der Energiegewinnung enorm senkt. So hatte <b>Frankreich</b> 2019 einen
            Anteil von
            <a
                href="https://www.bundestag.de/resource/blob/869702/b81366a754e3172ac5677f79f2a4d6b2/WD-5-074-21-pdf-data.pdf"
                target="_blank">
              70,6% Energie aus Kernkraft</a> im Strommix und einen <a
                href="https://www.umweltbundesamt.de/sites/default/files/medien/384/bilder/dateien/3_abb_thg-emi-eu-vergleich-pro-kopf_2022-09-07.xlsx"
                target="_blank">
              CO<sub>2</sub>e-Ausstoß</a> von 5,8 Tonnen pro Kopf und Jahr und
            Deutschland von 8,8. Die Frage, ob Atomkraft als <a
                href="https://www.econstor.eu/bitstream/10419/262770/1/s10273-022-3079-6.pdf"
                target="_blank">
              nachhaltig</a> einzustufen sei, ist <a
                href="https://www.econstor.eu/bitstream/10419/248535/1/1783574801.pdf"
                target="_blank">
              hochumstritten</a>. </p>
          <h3>Der Treibstoff von Kernkraftwerken: Uran</h3>
          <p>Der <b>Kernbrennstoff Uran</b> zählt nicht zu den fossilen Brennstoffen. Uran ist eine <b>metallische
            Ressource</b>. Gemein
            mit den fossilen Brennstoffen und den Rohstoffen, die zur Produktion von elektrisch betriebenen Fahrzeugen
            gebraucht werden, hat Uran, dass dieser aus der Erdkruste gewonnen werden muss, was mit lokal umfangreichen
            Eingriffen und möglicherweise aufwändiger Renaturierung nach Versiegen der lokalen Quellen einhergeht. Im
            Gegensatz zu Wind, Sonne, Geothermie und Wasserkraft hat man es mit einer endlichen Ressource zu tun, die
            ungleich auf Staaten verteilt ist. Das meiste Uran findet sich in <b>Kasachstan</b>, einem autoritär
            geführten
            Staat.
            Kleinere Uranvorkommen finden sich auch in Deutschland, im Osten des Landes, im Erzgebirge und im Westen im
            Südschwarzwald. Inzwischen wird dort kein Uran mehr abgebaut, aber dennoch entstehen durch den Rückbau des
            Bergbaus noch immense Kosten. <b>Zehntausende Fälle von Lungenkrebs</b> sind als Berufskrankheit bei den
            Bergarbeitern anerkannt worden. In <b>Brasilien</b> wird geplant, den Uranabbau voranzutreiben. Wie sich
            dies
            auf die
            indigene Bevölkerung auswirken wird, die auf den zu erschließenden Gebieten lebt, ist noch nicht
            abzusehen. </p>
          <p>Kernenergie ist nicht klimaneutral, wenn man die gesamte Wertschöpfungskette betrachtet, die sich vom
            Uranabbau, Transport, Rückbau der Anlagen bis zur Endlagerung zieht. Die Berufskrankheiten, der
            CO<sub>2</sub>-Ausstoß,
            die Abhängigkeit von autoritären Staaten sind nicht die ausschlaggebenden Argumente gegen Klimaschutz durch
            die Energieerzeugung durch Kernkraftwerke. Die beiden Hauptprobleme sind erstens das Risiko von schweren
            <b>Havarien</b> von Atomkraftwerken und zweitens die <b>ungelöste Frage der Endlagerung</b> des strahlenden
            Atommülls.</p>
          <h3>Das Risiko schwerer Havarien</h3>
          <p>Die Havarien von Three Mile Island (1979, USA), Tschernobyl (1986, Sowjetunion) und Fukushima (2011, Japan)
            zeugen davon, dass es keine Technik gibt, auf die zu jederzeit Verlass ist und menschliche Fehler
            auszuschließen vermag. Jede Gesellschaft muss sich die Frage stellen, ob sie dieses Risiko bereit ist,
            eingehen zu wollen. <b>Frankreich</b> z.B. setzt bei der Energiegewinnung stark auf Atomkraft. 2020 hatte
            die
            Kernenergie in Frankreich einen Anteil von 67,1% im Strommix. Der Rest fiel auf Erneuerbare Energien.
            Frankreich ist bei der Stromerzeugung weder auf russisches Gas noch auf Kohleverstromung angewiesen. Man
            unterscheidet zwischen einem beherrschbarem GAU (<b>G</b>rößter <b>A</b>nzunehmender <b>U</b>nfall) und
            einem
            nicht beherrschbaren
            GAU (Super-GAU). </p>
          <p>Auf der Erde gibt es heute <b>432 betriebsfähige Reaktoren</b>. Die meisten davon in den <b>USA</b>,
            gefolgt
            von
            <b>Frankreich, China, Russland</b> und <b>Japan</b>. 2018 wurde in den Vereinigten Arabischen Emiraten der
            erste Atomreaktor
            in der arabischen Welt in Betrieb genommen. Geprägt vom deutschen energiepolitischen Diskurs erscheint
            Atomkraft als eine rückständige Form der Energiegewinnung. Aus globaler Sicht ergibt sich ein etwas anderes
            Bild und damit auch globale Gefahren und Herausforderungen, wenngleich es weltweit nur <a
                href="https://de.statista.com/statistik/daten/studie/28688/umfrage/anzahl-der-atomkraftwerke-weltweit/"
                target="_blank">
              ein sehr langsames Anwachsen der Atomreaktoren</a> gibt. </p>
          <h3>Die ungelöste Frage der Endlagerung des Atommülls</h3>
          <p>Die Frage der Endlagerung ist jedoch weder in Frankreich noch in Deutschland gelöst. Allein Finnland hat
            bereits ein Endlager für Atommüll realisiert, das in 100 Jahren versiegelt werden soll. <b>Einige Millionen
              Jahre</b>
            muss der Atommüll dort sicher gelagert werden. Zum Vergleich: Die ältesten bekannten gezeichneten Symbole
            von
            Menschen sind etwa 35.000 Jahre alt. Vor ca. zehn Millionen Jahren - so schätzt man - entwickelte sich der
            aufrechte Gang und vor ca. 11.700 Jahren begann das Erdzeitalter des Holozäns, das nun durch das Anthropozän
            abgelöst wird, weil die Spuren wie bald atomare Endlager, Plastikmüll, Ablagerungen von fossilen
            Brennstoffen
            den Planeten über geologische Zeiträume hinweg prägen werden. Bei einer Million Jahre spräche man von 40.000
            Generationen, denen man für kurzfristig vermeintlich günstige Energieproduktion, Kosten und Aufwand
            hinterlassen würde. Achim Brunnengräber spricht von <a
                href="https://www.bpb.de/shop/buecher/schriftenreihe/292236/ewigkeitslasten/"
                target="_blank">
              Ewigkeitslasten</a>. Für ihn steht fest: „Wie tief wir auch
            bohren, jeder Lösungsversuch wird mit Sicherheit zu neuen Problemlagen und Herausforderungen führen, an die
            zuvor nicht gedacht wurde – und die nicht denkbar waren.“</p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="overpopulation = !overpopulation">
          <i :class="{ expanded : overpopulation }" class="fa fa-chevron-right expand-icon"/>
          Hat die Klimaerwärmung etwas mit Überbevölkerung zu tun?
        </div>
        <div v-show="overpopulation" class="tipp-content">
          <p>Hier gilt es, einen differenzierten Blick zu bewahren. Allzu simpel ist die Überlegung, dass der Menschen
            einfach zu viele seien. Freilich ist es nicht falsch, dass die <b>knapp 800 Millionen Menschen, die um das
              Jahr
              1750</b> das Erdenrund bevölkerten, einen geringeren Impact auf Umwelt und Klima verursachten, denn heute,
            wenn
            ca. <b>zehnmal so viele Menschen</b> den Planeten bevölkern. Allerdings sind die Emissionen historisch
            gesehen
            und
            aktuell sehr ungleich verteilt. So beträgt der CO<sub>2</sub>-Ausstoß von ganz <b>Afrika</b>, ein Kontinent
            mit 54
            Staaten, in
            denen 1,2 Milliarden Menschen leben, ca. 650 Millionen Tonnen. <b>Deutschland</b>, ein einzelner Staat mit
            nur
            6,83
            Prozent der Bevölkerung Afrikas, stößt hingegen 800 Millionen Tonnen Kohlenstoffdioxid aus. <b>Bayern, mit
              seinen
              nur etwa 12 Millionen Einwohner:innen, kommt auf ca. 75 Millionen Tonnen CO<sub>2</sub></b>. </p>
          <p>Es scheint also nicht zwingend an der Masse der Menschen zu liegen, wie nichtnachhaltig ein Staat oder eine
            Region hinsichtlich der Treibhausgasemissionen wirtschaftet, sondern an individuellen Lebenschancen und
            Wohlstandsniveau. Die große Mehrheit der Menschen der Welt hat noch kein Flugzeug betreten. Der großen
            Mehrheit der Bayern ist dies finanziell möglich. Das erscheint paradox, da die Armen dieser Welt nicht die
            globale Erwärmung verursachen, aber sehr häufig geäußert wird, dass man ein hohes Einkommen bräuchte, um ein
            nachhaltiges Leben führen zu können. </p>
          <p>Der Anstieg der Lebenserwartung im globalen Norden und die hohe Fertilität der Menschen im globalen Süden
            stellen die Menschen jedoch vor andere Herausforderungen. Der Wissenschaftler Mike Davies, Professor an der
            Universität Kalifornien, sieht in der Zukunft überbevölkerte Slumstädte auf uns zukommen. Dies führt dazu,
            dass die Menschen immer weiter in die zuvor unberührte Natur eindringen. Das <b>erhöht die Chance auf
              Zoonosen</b>,
            also den Übertrag für den menschlichen Körper unbekannter Krankheiten vom Tier auf den Menschen. Auch die
            Corona-Pandemie startete vermutlich auf diese Weise. Der große Umzug der Menschen in die Städte kann planlos
            ablaufen und nur den Gesetzen des Marktes folgen, dann aber muss die Menschheit sich mit entsprechenden
            Folgeproblemen auseinandersetzen und Methoden der Resilienz und Adaption entwickeln, sodass die Auswirkungen
            beherrschbar bleiben. </p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="consumption = !consumption">
          <i :class="{ expanded : consumption }" class="fa fa-chevron-right expand-icon"/>
          An der Ladenkasse die Welt retten. Geht das?
        </div>
        <div v-show="consumption" class="tipp-content">
          <p>Nachhaltigkeit ist hinsichtlich der Energieeffizienz und der Wiederverwertbarkeit der Werkstoffe ein
            schlagendes Verkaufsargument geworden. Doch was kann man als einzelner Supermarktkunde schon ausrichten? Das
            werden wir oft gefragt und wollen darauf eine differenzierte Antwort geben. Letzten Endes gilt – wie so oft
            –
            rational zu handeln, selbst zu denken und Verantwortung für die Zukunft zu übernehmen erscheint allen
            anderen
            Herangehensweisen überlegen. Dies gilt auch im Supermarkt.</p>
          <h3>Verschiebung der Verantwortung auf die einzelnen Bürger:innen oder Konsum als Chance der
            Veränderung? </h3>
          <p>Auch auf dieser Website war schon öfter die Rede von Pro-Kopf-Emissionen. Diese Art, Emissionen auf das
            Individuum herunterzurechnen ist keine Erfindung von demokratisch gewählten Vertreter:innen oder
            Wissenschaftler:innen, sondern wurde der Ölfirma <b>British Petrol (BP)</b> von einer Marketing-Firma
            vorgeschlagen,
            um die Handlungsmacht von dem Ölkonzern auf die Menschen diskursiv umzulenken. Die Handlungsfähigkeit sollte
            nicht mehr in erster Linie bei denen gesucht werden, die über den Verkauf von Benzin, Dieseln und Heizöl,
            Profite generierten, sondern bei den Konsument:innen, die ihnen die fossilen Produkte abkauften. Die
            Unternehmen sollten als passiv erscheinen, die sich der so genannten Konsument:innensouveränität beugen
            müssen, ob sie das wollen oder nicht. Was auf welche Art, zu welchem Preis und mit welchen Folgen produziert
            wird, entscheiden die Kund:innen, eingehegt von Gesetzen und Verordnungen der Politik. Dies ist eine sehr
            einseitige Sichtweise, setzt vollinformierte Konsument:innen voraus und lässt die Interessen der zukünftig
            lebenden Menschen außer Acht. Dies ändert jedoch nichts daran, <b>dass die Konsumentscheidung jeder
              einzelnen
              Person an jedem einzelnen Tag Einfluss nimmt</b> auf die Zukunftsfähigkeit der von den Menschen geteilten
            Welt. </p>
          <p>Es gibt <b>zahlreiche Start-Ups, die stark vom Nachhaltigkeitsdenken gelenkt werden</b> und dies als
            entscheidendes
            Verkaufsargument ins Feld führen. Viele Entscheider:innen in diesen Unternehmen wissen, dass sie ihren
            Erfolg
            gefährden würden, wenn sie nicht ökologisch nachhaltig wirtschaften. Neben den <b><a
                href="hhttps://www.researchgate.net/publication/229931054_Boycott_or_Buycott_Understanding_Political_Consumerism"
                target="_blank">
              Boykott</a></b> von Waren ist auch der
            Buykott eine Möglichkeit für die Konsument:innen den Markt in Richtung Nachhaltigkeit zu lenken, indem man
            nachhaltiges Verhalten von Unternehmen gezielt durch Kauf bzw. Inanspruchnahme der Dienstleistung
            unterstützt </p>
          <h3>Werbung – ein schlechter Ratgeber</h3>
          <p>Viele Produkte werden als nachhaltig beworben, obschon es nachhaltigere Alternativen gibt. Kein stilles
            Mineralwasser – sei es aus den Vogesen oder regional abgebaut – kann es zum Beispiel mit der Nachhaltigkeit
            von Leitungswasser aufnehmen. Die Forscher:innen Adriane Schmidt und Wolfgang Donsbach untersuchten alle
            Werbeanzeigen mit grünem Inhalt in den deutschen Zeitungen DER SPIEGEL und Focus sowie den britischen
            Zeitungen New Statesman und The Spectator zwischen den Jahren 1993 und 2009 und stellten fest, <b>dass 85%
              der
              grünen Werbung in den deutschen Zeitschriften und 68% in den britischen Zeitungen irreführende Botschaften
              enthielten</b>. <br>
            Auf Werbung sollte man sich also nicht verlassen. <b>Ziel von Werbung ist stets der Kaufimpuls</b> und nicht
            Klima-
            und Umweltschutz. Dessen sollte man sich immer bewusst sein. Die Waren werden angeboten, um <b>aus Kapital
              mehr
              Kapital</b> und nicht, um die Welt zukunftsfähig zu machen und wer diesem ökonomischen Paradigma als
            Unternehmerin
            oder Unternehmer nicht folgt und beständig rote Zahlen schreibt, dessen Unternehmen gilt nicht als
            marktfähig,
            sei das Unternehmensziel noch so nachhaltig. </p>
          <p>Die Waren im Einzelhandel sind alle schon da, wenn wir unsere Verkaufsentscheidung treffen. Mit der
            Kaufentscheidung können wir den Unternehmen nur kommunizieren, welche Waren wir bevorzugen und darauf
            hoffen,
            dass dies in die Marktanalysen der Unternehmen einfließt. Diese orientieren sich nicht an den gegenwärtigen
            Konsument:innen, sondern konstruieren sich aus zahlreichen Daten und Einschätzungen die Konsument:innen der
            Zukunft und versuchen über <b>Werbung, Bedürfnisse zu wecken, von denen man gar nicht wusste, dass man sie
              hatte.</b></p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="individual = !individual">
          <i :class="{ expanded : individual }" class="fa fa-chevron-right expand-icon"/>
          Was kann ich als Einzelne/r abgesehen von einem verantwortungsbewussten Konsum tun?
        </div>
        <div v-show="individual" class="tipp-content">
          <p>Das veränderte Verhalten eine Einzelperson, wenn sie die Konsumgewohnheiten umstellt, hat keine messbare
            Auswirkungen im globalen Maßstab. Da braucht man sich keine Hoffnungen zu machen. Doch aus einem oder einer
            können viele werden. Es gibt Soziolog:innen, die meinen, es bräuchte nur 5%, um eine Gesellschaft zu
            verändern
            und selbst, wenn eine Veränderung der Gesellschaft über tausende und Millionen Einzelentscheidungen nicht
            gelingen sollte, zählt jeder noch so kleine Beitrag, um diesen Planeten für Flora, Fauna und Menschen
            zukunftsfähig zu machen. </p>
          <p>Auch, wenn es der Menschheit nicht gelingen sollte, z.B. die Erderwärmung unter zwei Grad Celsius Erwärmung
            zwischen 1850 und dem Jahr 2100 zu halten, <b>macht es einen gehörigen Unterschied, ob wir in einer Welt
              leben,
              in der es 2,2°C oder 2,1°C wärmer ist</b>, insbesondere, wenn es um die Frage irreversibler <a
                href="https://www.umweltbundesamt.de/publikationen/kipp-punkte-im-klimasystem"
                target="_blank">
              Kipppunkte</a> geht. Daher
            spielen auch Ihre alltäglichen Entscheidungen keine untergeordnete Rolle. Jedoch <b>wird ein veränderter
              Konsum
              allein nicht reichen</b>. Die <a
                href="https://www.mcc-berlin.net/forschung/co2-budget.html"
                target="_blank">
              geringe Zeitmenge</a>, die den Menschen noch bleibt, um die planetaren Grenzen
            einzuhalten, braucht den Menschen nicht nur als umsichtigen Verbraucher oder Verbraucherin, sondern auch als
            Bürgerin oder Bürger, die/der dafür sorgt, Politik, Wirtschaft, Journalismus und soziale Bewegungen zu
            verändern. Wer sich nur im Kreislauf von Produktion und Konsumtion bewegt, ist - so meint Hannah Arendt -
            kein
            Mensch, sondern ein <i>animal laborans</i>, ein arbeitendes Tier, das sich nur zwischen Frust und Lust
            bewegt,
            aber
            nicht reflektiert, nichts Neues schafft und vor allem nicht handelt, sondern zum Verhalten getrieben wird,
            sei
            es bei der Produktion oder Konsumtion. </p>
          <p><b>In einer liberalen Demokratie gibt es viele Möglichkeiten, aktiv zu werden</b>. Seien Sie auch aktiv,
            machen Sie
            mit, wenn in ihrer Gegend, <a
                href="https://www.youtube.com/watch?v=PKtunMSDd2Q"
                target="_blank">
              Plogging</a> angeboten wird oder denken Sie darüber nach, wie insektenfreundlich Sie
            ihren Garten gestalten möchten. Ein kleiner Schritt hilft immer mehr, als keinen Schritt zu wagen und aus 82
            Millionen Schritten kann eine große Bewegung entstehen. Die demokratischen Parteien bieten ebenso
            Möglichkeiten den Wandel zu einer nachhaltigeren Gesellschaft mitzugestalten, wie auch die Arbeit in
            Vereinen,
            Aktivismus in sozialen Bewegungen oder aber auch in Gewerkschaften. Besuchen Sie demokratisch gesinnte
            Demonstrationen, zeigen Sie Ihre Haltung, besuchen Sie Stadtratssitzungen, reichen Sie Petitionen ein oder
            spenden Sie Geld an diejenigen, die sie nicht mit Ihrer Zeit unterstützen können. Demokratie lebt nur, wenn
            mitgemacht wird und Mitwirkung ermöglicht wird. Ohne der Beteiligung von Bürger:innen geht die Demokratie zu
            Grunde. <b>Demokratie braucht Demokrat:innen!</b></p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="hydrogenCars = !hydrogenCars">
          <i :class="{ expanded : hydrogenCars }" class="fa fa-chevron-right expand-icon"/>
          Können wir alle mit grünem Wasserstoff Auto fahren?
        </div>
        <div v-show="hydrogenCars" class="tipp-content">
          <p>Der Verkehrssektor produziert enorm viel klimaschädliches CO<sub>2</sub> und dies tendenziell sogar eher
            mehr, denn
            weniger, da die Menschen sich augenscheinlich lieber mit dem privaten Pkw fortbewegen wollen als mit
            Massenverkehrsmitteln wie Bus oder Bahn. Daher müssen sich Wissenschaft und Unternehmen der Frage widmen,
            wie
            der motorisierte Individualverkehr nachhaltiger gestaltet werden kann, selbst wenn die Lösungen mit dem Nah-
            und Fernverkehr mit Bussen und Bahn schon auf dem Tisch liegen würden. </p>
          <h3>Wie wird Wasserstoff produziert?</h3>
          <p>Wasserstoff ist ein so genannter <b>sekundärer Energieträger</b>. Das bedeutet, dass dieser nicht abgebaut
            werden
            kann, wie Uran, Erdöl oder genutzt wie Wasserkraft oder Sonnenergie, sondern produziert werden muss. Durch
            den
            Einsatz von Energie wird Wasser (H2O) mittels z.B. Elektrolyse in Sauerstoff (O) und farblosen gasförmigen
            Wasserstoff (H2) <b>aufgespalten</b>. Dieser kann später wieder in Energie umgewandelt werden, wobei als
            Reststoff
            nur ungefährliches Wasser (H2O) bleibt. Die Energie zur Erzeugung kann aus unterschiedlichen Quellen
            stammen.
            Je nach Quelle gibt man dem Wasserstoff eine fiktive Farbe. <b>Grauer Wasserstoff</b> wird aus Erdgas
            gewonnen
            und
            wird <b>blau</b> genannt, wenn man die daraus entstehenden Emissionen in der Erde speichert, damit kein
            Treibhausgas
            in die Atmosphäre dringen kann. Diese Technik nennt man <a
                href="https://www.umweltbundesamt.de/themen/wasser/gewaesser/grundwasser/nutzung-belastungen/carbon-capture-storage"
                target="_blank">
              Carbon Capture and Storage</a>. <b>Brauner Wasserstoff</b> wird
            aus Kohle gewonnen. </p>
          <p>Weitestgehend CO<sub>2</sub>-neutral ist die Produktion von <b>grünem Wasserstoff</b>. Dieser wird aus
            Erneuerbaren
            Energien
            gewonnen, also durch Photovoltaikanlagen, Windkraft, Wasserkraft oder aus Biomasse. Wasserstoff kann helfen,
            Energie zu speichern, um die Volatilität der Erneuerbaren Energien auszugleichen: Nachts kann man keine
            Sonnenenergie ernten und manchmal weht nachts auch kein Wind (so genannte <a
                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjHh4fRh_H6AhXcX_EDHRwaAxYQFnoECBgQAQ&url=https://stories.umweltbundesamt.de/system/files/document/20210527_Themenkompass_Energiewende.pdf&usg=AOvVaw3c_KI5zCmtnWxL28ZOr-e4"
                target="_blank">
              Dunkelflaute</a>) und trotzdem verlangen
            Bürger:innen, Wirtschaft, Behörden und die Kritischen Infrastrukturen nach Strom. Hingegen ist bei grellem
            Sonnenschein und starken Winden zu viel Strom in manchen Netzregionen, sodass sich die Bürger:innen manchmal
            wundern, warum die ganzen Windräder stillstehen. Wasserstoff könnte helfen, diese Strommengen für spätere
            Verwendung zugänglich zu machen. Man hat quasi eine Lösung und sucht nun nach einem Problem, das man damit
            lösen kann. <b>Ist der Individualverkehr ein Problem für die Lösung Wasserstoff?</b></p>
          <h3>Reicht der grüne Wasserstoff für die Nachfrage nach motorisiertem Individualverkehr?</h3>
          <p>1kg H2 hat so viel Energie wie 2,75 Liter Super-Benzin. Es hat also eine <b>wesentlich höhere
            Energiedichte</b>. Im
            Moment erzeugen Deutschlands etwa 30.000 Windkraftanlagen ca. 112 Terawattstunden jährlich. In Deutschland
            sind <b>48,2 Millionen Pkw angemeldet</b>. Bildet man den Durchschnitt aus den Angaben von statista.de,
            Check24 und
            dem Kraftfahrbundesamt, dann legen diese Kraftfahrzeuge im Jahresschnitt <b>12.700 Kilometer</b> zurück. Der
            <b>Toyota
              Mirai</b> (dtsch: Zukunft) verbraucht laut Hersteller 0.7 kg/100km, laut Test 1,5kg Wasserstoff. Legt man
            nun
            einen Durchschnitt von 1,1 kg/100km zu Grunde und einen notwendigen <b>Stromaufwand von 66 kWh pro Kilo
              Wasserstoff (Gesamtwirkungsgradannahme von 50 %)</b>, dann wird schnell klar, dass es beim momentanen
            Stand
            der
            Nachfrage nach privater Mobilität ohne enormen Zukauf von Wasserstoff aus dem Ausland nicht möglich ist, die
            privaten Pkw mit Wasserstoff zu betreiben. Würden alle Pkw mit Wasserstoff angetrieben, bräuchte man bei
            stagnierendem Bestand und gleichbleibender Nutzung – je nach Erzeugungsform des Wasserstoffs <b>ca. 444
              Terawattstunden Strom</b>. <br>
            Wesentlich effizienter ist die Nutzung von E-Autos (wären ca. 115 TWh Strom notwendig, bei ca. 19
            kWh/100km),
            wenngleich öffentliche Verkehrsmittel und Muskelkraft sicherlich die ökologisch nachhaltigeren Varianten der
            Fortbewegung wären. Dies ist aber nicht immer und überall möglich oder aber auch erwünscht. Polizei,
            Feuerwehr
            und die medizinische Versorgung von Unfallopfern kann man, ethisch gut begründet, Vorrang vor ökologischen
            Erwägungen zugestehen</p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="eCars = !eCars">
          <i :class="{ expanded : eCars }" class="fa fa-chevron-right expand-icon"/>
          Können wir alle mit elektrisch angetriebenen Kraftfahrzeugen fahren?
        </div>
        <div v-show="eCars" class="tipp-content">
          <p>Von der gewonnenen Kilowattstunde Strom bis zur Pkw-Antriebsachse kann der reinelektrische Pkw, verglichen
            mit anderen Antriebsarten (Wasserstoff, E-Fuels usw.), den besten Wirkungsgrad und damit die höchste
            Effizienz
            vorweisen. Durch die steigende Anzahl an Elektrofahrzeugen und den damit steigenden Strombedarf ergeben sich
            dennoch Veränderungen und Herausforderungen für das Energieversorungssystem. Bisher müssen jährlich rund 500
            Terawattstunden Strom zur Bedarfsdeckung vom deutschen Energiesystem bereitgestellt werden. Durch die von
            der
            Bundesregierung angestrebte Zahl von 15 Millionen Elektrofahrzeuge bis zum Jahr 2030 würde der
            Stromverbrauch
            um 36 Terawattstunden bzw. 7% ansteigen. Würden alle 48,2 Millionen gemeldeten Pkw rein elektrisch
            betrieben,
            hätte das eine Stromverbrauchssteigerung von 115 Terawattstunden bzw. 23% zur Folge. Die Anschaffung eines
            Elektrofahrzeugs, mit einem jährlichen Energiebedarf von 2400 Kilowattstunden, würde für einen
            durchschnittliche 2-Personen Haushalt in etwa eine Verdopplung des Stromverbrauchs bedeuten. Der
            durchschnittliche Verbrauch von Pkw in Deutschland beträgt 7,4 Liter. Am 27.10.2022 um 07:00 Uhr betrug der
            durchschnittliche Preis für einen Liter Super-Benzin 2,053 Euro. Geht man von jährlich 12.700 gefahrenen
            Kilometern aus, dann hätte man Ausgaben von 1.929,41 € allein für den Treibstoff des Pkw. Geht man von einem
            Strompreis von <a
                href="https://www.destatis.de/DE/Themen/Wirtschaft/Preise/Erdgas-Strom-DurchschnittsPreise/_inhalt.html"
                target="_blank">
              32,87 Cent pro kW/h</a> aus, dann würde dies bedeuten, dass man für den energetischen Antrieb nur
            788,88 Euro zu bezahlen hätte. Das entspricht einer <b>Ersparnis von 1.140,53 Euro</b> pro Jahr. Diese
            Ersparnis kann
            sich weiter erhöhen, wenn man eine PV-Anlage auf dem Dach sein Eigen nennt. Eine PV-Anlage auf dem Dach
            entspricht gleichsam eines Ölfunds im hauseigenen Garten und einer Raffinerie und einer Tankstelle. Auch
            hier
            ist allerdings auch die <a
                href="https://www.youtube.com/watch?v=9E02lvlAiA8"
                target="_blank">
              Carbon Capture and Storage</a> Gefahren von Rebound-Effekten zu achten. </p>
          <p><b>Reichweitenangst ist statistisch gesehen nicht rational</b>, da sich die alltäglichen Wege der Menschen
            selten
            über 100km bewegen. Im Schnitt steht ein Pkw 23 Stunden am Tag auf der Straße rum. Zeit zum Laden ist also
            vorhanden und mit mehr E-Autos wird es auch mehr Ladesäulen geben. Die Infrastruktur dafür ist bereits im
            Aufbau. <b>Die Reichweitenangst betrifft vielmehr Diesel-Fahrer</b>, die in manche Städte gar nicht mehr
            einfahren
            dürfen, da die Feinstaubbelastung zu hoch ist. Deren Reichweite wird über die Straßenverordnung immer mehr
            eingeschränkt. </p>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="plastics = !plastics">
          <i :class="{ expanded : plastics }" class="fa fa-chevron-right expand-icon"/>
          Aus welchen Quellen werden Kunststoffe in die Umwelt ausgebracht?
        </div>
        <div v-show="plastics" class="tipp-content">
          <p>Kunststoffe genossen noch nie einen guten Ruf. Dass man sie gleichzeitig gut gebrauchen und einen
            misslichen
            Umgang mit ihnen pflegen kann, wollen wir in diesem Kapitel herausstellen. </p>
          <h3>Wozu sind Kunststoffe gut? </h3>
          <p>Plastik erscheint in der deutschen Sprache als wäre es nur ein bestimmter Werkstoff, da man aus dem Wort
            Plastik nicht ohne Bedeutungsverschiebung den Plural bilden kann. Plastiken stehen in Parks und müssen gar
            nicht aus Kunststoffen gefertigt sein. Die Werkstofffamilie der Kunststoffe besteht aus etwa <b>15.000
              Arten</b>, die unter ca. 20.000 Handelsnamen vertrieben werden. </p>
          <p>Kunststoffe sind relativ neu. Erst zum Ende des 19. Jahrhunderts wurden die ersten halbsynthetischen
            Kunststoffe entwickelt und zu diesem Zeitpunkt noch als Ersatzstoffe bezeichnet. Als Ausgangsstoffe dienten
            Milch wie z.B. beim Galalith oder aber auch Abfallstoffe aus der Schlachtung wie Knochen oder aber auch
            Blut.
            Erst mit dem Bakelit fand der erste vollsynthetische Kunststoff Verwendung. Nach dem Zweiten Weltkrieg, in
            den
            goldenen Jahren des Wirtschaftswachstums, <b>machten Kunststoffe den Massenkonsum möglich</b> und
            verschafften
            insbesondere in den privaten Haushalten mehr freie Zeit, indem es auch Arbeiter:innen möglich geworden ist,
            vom technischen Fortschritt wie Waschmaschinen und Kühlschränken zu profitieren. <b>Ein Wohlstand, wie er im
              Moment in Deutschland und in der Region 10 Bayerns herrscht, ist ohne die Erfindung von Kunststoffen nicht
              vorstellbar.</b></p>
          <p><b>Nur mit Kunststoffen ist eine Leichtbauweise möglich</b>. Auch ein flächendeckendes Impfangebot in der
            Geschwindigkeit, wie es in den Pandemiejahren seit 2020 aufgebaut wurde, wäre ohne Kunststoffe nicht
            vorstellbar. </p>
          <h3>Kunststoffe sind wertige Stoffe - Zum Verschwenden viel zu schade! </h3>
          <p>Kunststoffe sind Werkstoffe von hoher Wertigkeit und so sollten wir sie auch behandeln. Jedoch sollte man
            sich vor Augen halten, dass die heutigen Rohstoffe in ihrer überwiegenden Zahl aus Erdöl hergestellt werden
            und Jahrmillionen vonnöten waren, um aus Lebewesen Öl entstehen zu lassen. Mit großem Aufwand an Arbeit und
            Energie wurde dieses gefördert, transportiert, raffiniert, weitertransportiert und letztlich in zahlreichen
            chemischen Prozessen, z.B. zu einem Plastiklöffel verarbeitet, der von einer Fabrik in den Großhandel
            gebracht
            wurde, von dort in den Einzelhandel, wo er in perfektem Licht und mit umfassender Beratungsmöglichkeit
            präsentiert wird. Mit diesem Produkt zweimal in einem Becher umzurühren, ist eine unglaubliche Verschwendung
            an endlichen Naturressourcen, Arbeitskraft und Energie. </p>
          <p>Es geht also um eine Frage der <b>Wertschätzung dieser Werkstofffamilie</b> und damit die Frage, ob der
            Kunststoffeinsatz hier ökologisch, ökonomisch, sozial und kulturell rechtfertigbar ist oder nicht.
            Kunststoffe
            sind nämlich zugleich ein ästhetisches und - noch schlimmer - ein Umweltproblem. <b>Zwischen 1950 und 2015
              sind
              8,3 Milliarden Tonnen Kunststoffe produziert worden</b>. Und insofern diese Produkte nicht verbrannt
            worden
            sind,
            sind sie von diesem Erdball noch nicht verschwunden und werden auch noch bleiben, wenngleich man nicht sagen
            kann, wie lange die so genannte Persistenz der Materialien sein wird. Klar ist jedoch: Kunststoffe zerfallen
            in immer kleinere Stücke, was es immer schwerer macht, diese Stoffe wieder aus der Umwelt herauszuholen, da
            sie bis auf Größe von Mikroplastik und noch kleiner zerfallen. </p>
          <p>Kunststoffe in der Umwelt sind besonders für Meeresbewohner und Vögel ein großes Problem. Diese Tiere sind
            wenig wählerisch, was Aussehen, Geruch und Textur ihrer Nahrung betrifft und schlucken die nährstofflosen
            Kunststoffe, die sie in ihrer natürlichen Umgebung finden. Sie verhungern bei vollem Magen. Das Narrativ,
            dass
            die Kunststoffe so auf den Tellern der Menschen landen würden, ist jedoch falsch. <b>Menschen ernähren sich
              in
              der Regel nicht von Fisch- oder Vogelmägen</b>. Dennoch hat man es hier mit einem Eingriff in die Natur zu
            tun,
            mit welchem Tieren vollkommen unnötig Leid zufügt und die Biodiversität und damit die überlebensnotwendige
            Umwelt von Menschen gefährdet wird. </p>
          <p>Es gibt <a
              href="https://www.umsicht.fraunhofer.de/content/dam/umsicht/de/dokumente/publikationen/2018/kunststoffe-id-umwelt-konsortialstudie-mikroplastik.pdf"
              target="_blank">
            unterschiedliche Eintragswege von Kunststoffen</a>. Über die alltägliche Autofahrt, aber auch z.B. über
            den Klärschlamm von Kläranlagen, der in Deutschland zur Düngung in der landwirtschaftlichen Produktion
            genutzt
            wird. Dieser enthält oft noch große Mengen an anthropogenen Polymeren. Pro Kopf und Jahr werden <b>131,9
              Gramm
              Mikroplastik in Deutschland über Verwehungen von Sport- und Spielplätzen</b> in die Umwelt eingebracht;
            allein von
            Fußballplätzen mit Kunstrasen 96,6 Gramm. 109 Gramm sind es pro Kopf und Jahr über den <b>Abrieb unserer
              Schuhsohlen</b> und <b>„nur“ 19 Gramm</b> sind es durch Kosmetika, die in die Umwelt eingetragen werden.
          </p>
          <p><b>Eine Plastikflasche im Park stellt in einem ersten Schritt nur ein ästhetisches Problem dar</b>. Es ist
            einfach
            nicht schön, wenn die Menschen, die Dinge, von denen sie glauben, sie würden sie nicht mehr brauchen, an Ort
            und Stelle entledigen (Fachbegriff: Littering), aber eine Umweltbelastung liegt hier noch nicht vor.
            Problematisch wird es erst dann, wenn dies in Gegenden geschieht, in denen Tiere dazu neigen, Kunststoffe
            mit
            Nahrung zu verwechseln. In einem zweiten Schritt fragmentiert der Kunststoff, d.h. er zersetzt sich in immer
            kleinere Teile. </p>
          <h3>Sind Kunststoffe gesundheitsschädlich? </h3>
          <p>Kunststoffe selbst sind für den Menschen gesundheitlich meist nicht bedenklich. Es sind die Additive, also
            Chemikalien, die den Polymeren zusätzliche Eigenschaften verleihen, wie Verformbarkeit, Geschmeidigkeit oder
            aber auch Festigkeit. Sehr bekannt ist z.B. das Bisphenol A, das bei PVC (Polyvinylchlorid) hinzugegeben
            wird
            und als Weichmacher fungiert. Insbesondere die Zusatzstoffe, die in ihrer Zusammensetzung menschlichen
            Hormonen sehr stark ähneln, können u.a. die Fähigkeit zur Fortpflanzung einschränken. Leider müssen - im
            Gegensatz zu Lebensmitteln - diese Stoffe in Gebrauchsgegenständen nicht deklariert werden. Wenn nun die
            Konsument:innen - in ihrer Rolle als souveräne König:innen des Marktes - die Produktion nachhaltiger machen
            sollen, dann wird das ohne mündige und gut informierte Endverbraucher:innen nicht gelingen. </p>
        </div>
      </div>

      <br>

      <div class="tipp">
        <div class="tipp-header" @click="literature = !literature">
          <i :class="{ expanded : literature }" class="fa fa-chevron-right expand-icon"/>
          Kommentierte Literaturliste
        </div>
        <div v-show="literature" class="tipp-content">
          Für diejenigen, die mehr erfahren wollen, haben wir hier eine kommentierte Literaturliste zusammengestellt.
          Diese ist bei Weitem nicht erschöpfend, aber bietet einen kleinen Einblick über die zahlreichen Themen zu
          Klima-
          und Umweltpolitik. Für eine bessere Übersichtlichkeit haben wir eine Kategorisierung vorgenommen.
          <h3>Einblick in die Geschichte</h3>
          Behringer, Wolfgang: Kulturgeschichte des Klimas. Von der Eiszeit bis zur globalen Erwärmung Bonn: bpb.
          <div class="tab">
            Behringer, Professor für Frühe Neuzeit an der Universität des Saarlandes, gibt einen Überblick über den
            Einfluss des Klimas auf die Menschheitsgeschichte und endet sehr optimistisch. Geleitet von der Sicherheit,
            dass die Menschen sich immer anzupassen verstanden, geht er davon aus, dass dies auch in dieser Klimakrise
            so
            sein wird.
          </div>
          <p>&nbsp;</p>
          Chakrabarty, Dipesh (2022): Das Klima der Geschichte im planetarischen Zeitalter. Berlin: Suhrkamp.
          <div class="tab">
            Der Professor für Südasiatische Sprachen und Kulturen an der <a
              href="https://de.wikipedia.org/wiki/University_of_Chicago"
              target="_blank">
            University of Chicago</a> schafft es, dass man als
            Leser oder Leserin aus dem Denken im menschlichen Zeitrahmen ausbricht und einen Blick auf die langen,
            Jahrtausende dauernden Zusammenhänge wagt. Besonders deutlich wird dies im Begriff der erneuerbaren
            Energien.
            Auch die fossilen Energien erneuern sich, aber eben in Zeiträumen, die dem Menschen auf Grund seiner kurzen
            Verweildauer auf Erden nicht zugänglich sind.
          </div>
          <p>&nbsp;</p>
          Uekötter, Frank (2019): Kleine Geschichte der Klimadebatte. Online unter: <a
            href="https://www.bpb.de/shop/zeitschriften/apuz/300412/kleine-geschichte-der-klimadebatte/"
            target="_blank">
          https://www.bpb.de/shop/zeitschriften/apuz/300412/kleine-geschichte-der-klimadebatte/</a>
          <div class="tab">
            Uekötter, der an der Birmingham University lehrt, zeichnet die Diskurse in Wissenschaft und
            Politik von der Zeit um 1900 bis zum heutigen „Boom“ der Klimaforschung nach. Insbesondere interessiert er
            sich für das Zusammenspiel von Wissenschaft und Politik.
          </div>
          <p>&nbsp;</p>
          <b>Uekötter, Frank (2021): Im Strudel. Eine Umweltgeschichte der modernen Welt. Bonn: bpb.</b>
          <div class="tab">
            Uekötter zeichnet das Mensch-Umwelt-Verhältnis seit dem Ende des Mittelalters auf 682 Seiten nach. Am Ende
            plädiert er für eine „Ethik des Suchens, die uns Aufschluss darüber gibt, wie man ein gerechtes Leben
            führt“.
            Er beschäftigt sich weniger mit Strukturen der Gesellschaft, sondern mit den Helden und Bösewichtern der
            Umweltgeschichte.
          </div>
          <p>&nbsp;</p>
          Lepenies, Philipp (2013): Die Macht der einen Zahl. Eine politische Geschichte des Bruttoinlandsprodukts.
          Berlin: Suhrkamp.
          <div class="tab">Lepenies ist Ökonom und Professor für Politikwissenschaft an der Freien Universität Berlin.
            Er
            zeichnet in diesem schmalen Band nach, wie diese eine Zahl so eine enorme Wirkkraft entfalten konnte, dass
            sich ganze Politiken nach ihr richten und internationale Ranglisten erstellt werden, bei denen man den
            Eindruck gewinnen könnte, es ginge bei der Produktion von Waren nicht um die Befriedigung von Bedürfnissen,
            sondern um eine Sportveranstaltung, bei der Punkte für die Nationalstaaten vergeben je mehr Waren und
            Dienstleistungen sie inländisch herstellen. Sein Ergebnis ist ernüchternd: „Ein Wissen um die Entstehung des
            BIP könnte all diejenigen zum Nachdenken bringen, die reflexartig glauben, das BIP-Wachstum sei ein
            geeigneter
            Lösungsansatz für die Herausforderungen des 21. Jahrhunderts. Gleichzeitig zeigt die Erfolgsgeschichte des
            BIP
            aber auch allen, die darauf hoffen, dass sich ein Alternativmodell zum BIP durchsetzen lässt, wie schwierig
            es
            sein wird, dessen Siegeszug zu wiederholen und die Macht der einen Zahl zu brechen.“
          </div>
          <h3>Zahlen, Daten, Fakten</h3>
          Rahmstorf, Stefan; Schellnhuber Hans-Joachim (2007): Der Klimawandel: Diagnose, Prognose, Therapie. 5.
          Auflage.
          München: C.H. Beck.
          <div class="tab">Wer eine wirklich kurze Einführung sucht, die die Kernaussagen über die Ursachen des
            Klimawandels
            sucht, weil er oder sie z.B. ein Impulsreferat für den Schulunterricht vorbereiten muss, ist mit diesem
            dünnen
            Band sehr gut gerüstet. Nachteilig ist jedoch das Alter des Bandes. Wenn man jedoch wissen will, was man
            alles
            seit 2007 schon hätte machen müssen und unterblieben ist, hat mit diesem Buch gleichsam ein historisches
            Dokument. Stefan Rahmstorf wurde für seine wissenschaftliche Arbeit bereits mehrfach ausgezeichnet und hat
            zahlreiche Fellowships an unterschiedlichen Universitäten. Hans-Joachim Schellnhuber ist Gründer des
            Potsdam-Instituts für Klimafolgenforschung (PIK) und war zwischen 1992 und 2018 dessen Direktor.
          </div>
          <p>&nbsp;</p>
          Buhofer, Stephan (2017): Der Klimawandel und die internationale Klimapolitik in Zahlen. Eine Übersicht.
          München:
          oekom.
          <div class="tab">Der Journalist Buhofer gibt zuerst eine prägnante Übersicht die physikalischen Grundlagen und
            die
            Emissionsquellen von Treibhausgasen, um die Klimaverträge von Kyoto und Paris, deren Entstehung und Wirkung
            darzulegen.
          </div>
          <p>&nbsp;</p>
          Brunnengräber, Achim (2019): Ewigkeitslasten. Die `Endlagerung´ radioaktiver Abfälle als soziales, politisches
          und
          wissenschaftliches Projekt. Bonn: bpb.
          <div class="tab">Brunnengräber, Privatdozent am Fachbereich Politik- und Sozialwissenschaften der FU Berlin,
            zeigt
            in sechs Kapiteln auf, welche Aufgaben und Herausforderungen und zuweilen auch Unmöglichkeiten sich aus der
            kurzen Phase der Atomenergie ergeben haben. Besonders beeindruckend sind dabei die immensen Zeiträume:
            „Radionuklide mit einer Halbwertszeit von etwa 30 Jahren werden als kurzlebig angesehen, eine besonders
            lange
            Halbwertszeit hat dagegen Plutonium-239 mit etwa 24.110 und Plutonium-242 mit etwa 376.000 Jahren. Uran-235
            hat
            erst nach 704 Millionen Jahren seine Halbwertszeit erreicht.“ Dabei darf man den Begriff der Halbwertszeit
            nicht
            mit dem Begriff der Halbzeit aus dem Fußball verwechseln. Nach Verstreichen einer Halbwertszeit sinkt die
            Konzentration auf die Hälfte, nach zwei Halbwertszeiten auf ein Viertel etc.
          </div>
          <p>&nbsp;</p>
          Edenhofer, Ottmar; Jakob, Michael (2019): Klimapolitik. Ziele, Konflikte, Lösungen. 2. Aktualisierte und
          erweiterte Auflage München: C.H. Beck.
          <div class="tab">Edenhofer, Chefökonom des Potsdam-Instituts für Klimafolgenforschung (PIK) und Professor für
            die
            Ökonomie des Klimawandels an der Technischen Universität Berlin, und Jakob, Senior Researcher am Mercator
            Institute on Global Commons and Climate Change, geben auf nur 138 Seiten eine kompakte Einführung in die
            Ziele,
            Wege, Instrumente und Institutionen der Klimapolitik. Die kurze Bestandsaufnahme und die Rolle der
            Wissenschaft
            in der Klimapolitik runden das kurze Einführungswerk ab.
          </div>
          <p>&nbsp;</p>
          <h3>Einblick in die Ethik der Nachhaltigkeit </h3>
          Gesang, Bernward (2011): Klimaethik. Berlin: Suhrkamp
          <div class="tab">Der Philosophieprofessor der Universität Mannheim gibt eine kurze Einführung in den Stand der
            Forschung hinsichtlich des anthropogenen Klimawandels. Des Alters der Publikation wegen ist dies jedoch
            nicht
            der ausschlaggebende Grund, um zu dieser Lektüre zu greifen. Heute – mehr als zehn Jahre nach der
            Ersterscheinung – ist jedoch die Gegenüberstellung von neun Gerechtigkeitsprinzipien auf weniger als 30
            Seiten
            sehr studierendenfreundlich. Diese Kategorisierung, die auch die Vertreter:innen benennt, verschafft einem
            einen
            ersten Überblick über die Ethik des Klimaschutzes. Nicht nur für die Ausarbeitung z.B. eines Referats ist
            dies
            eine sehr empfehlenswertes Buch, sondern auch um ein elaborierteres Verständnis für die eigene
            Moralvorstellung
            und auch die von anderen Menschen und Institutionen zu bekommen.
          </div>
          <p>&nbsp;</p>
          Ott, Konrad (2010): Umweltethik zur Einführung. Hamburg: Junius.
          <div class="tab">Obgleich dieses Buch den Begriff der „Einführung“ im Titel trägt, kann es nur
            Fortgeschrittenen
            im Bereich der Umweltethik empfohlen werden. Einen besseren Einstieg findet man bei Christoph Sebastian
            Widdau.
            Ott steigt tiefer ein und setzt bereits Kenntnisse in der Deontologie, Sprachphilosophie und politischer
            Philosophie voraus. Konrad Ott ist Professor für Philosophie und Ethik der Umwelt an der Universität Kiel.
          </div>
          <p>&nbsp;</p>
          Horn, Eva; Bergthaller, Hannes (2019): Anthropozän zur Einführung. Hamburg: Junius.
          <div class="tab">Nach dem Erdzeitalter des Holozäns soll nach einer vieldiskutierten These von <a
              href="http://ndl.ethernet.edu.et/bitstream/123456789/74935/1/Eckart%20Ehlers.pdf#page=25"
              target="_blank">
            Paul Crutzen</a> der Planeten in die Phase des Anthropozäns eingetreten sein. Diese These ist nicht
            unumstritten. Manche sagen, es
            sei menschliche Hybris, die zu dieser Einteilung führe, <b>manche wie Prof. Dr. Harald Lesch wollen lieber
              vom
              <a
                  href="https://www.youtube.com/watch?v=6wLlWWp8Vcg"
                  target="_blank">
                Kapitalozän</a> sprechen</b>, da die geologischen Veränderungen, die auch in geologischen
            Zeitdimensionen
            weiter messbar
            bleiben werden, nicht vor 40.000 Jahren, sondern mit Beginn des Industriezeitalters in Europa begannen.
            Donna
            Haraway bevorzugt den Begriff des <b>Chthulucene</b>, das die nichtmenschlichen Akteure stärker ins Spiel
            bringen soll.
            Horn und Bergthaller plädieren für den Begriff Anthropozän. Sie konstatieren „eine ökologische
            Schwellensituation“. Wenn man sich für die Argumente eines Für uns Widers der Bezeichnung einer neuen Epoche
            interessiert, bekommt hier einen sehr belesenen Eindruck. Eva Horn ist Universitätsprofessorin an der
            Universität Wien. Hannes Bergthaller ist Professor am Department of Foreign Literatures and Languages der
            National Chung-Hsing University in Taichung, Taiwan.
          </div>
          <p>&nbsp;</p>
          Widdau, Christoph Sebastian (2021): Einführung in die Umweltethik. Stuttgart: reclam.
          <div class="tab">
            Ethik ist die Wissenschaft von der Moral. Sie beschreibt welche Moralformen in der Gesellschaft zu finden
            sind und wie man sie begründen könnte. Widdau gibt eine studierendengerechte Einführung in das Spezialgebiet
            der
            Umweltethik und legt dar, wie Umweltethiker moralische Gründe für den Umweltschutz und für dessen Gegenteil
            suchen. Für Fortgeschrittene bietet es eine Verfestigung des Wissens und für Neulinge auf dem Gebiet der
            Umweltethik einen übersichtlichen Überblick in gewohntem gelben reclam-Format. Widdau ist promovierter
            wissenschaftlicher Mitarbeiter an der Universität in Magdeburg.
          </div>
          <p>&nbsp;</p>
          Birnbacher, Dieter (1997, Hrsg.): Ökophilosophie. Stuttgart: reclam.
          <div class="tab">Anhand von kurzen Ausschnitten aus einschneidenden wissenschaftlichen Werken, die
            Ökophilosophie
            zum Gegenstand haben, kann die Entwicklung dieses Teilgebiets der Philosophie gut nachvollzogen werden. Die
            Themengebiete sind die Tiefenökologie, das ökologische Gleichheitsprinzip, Artenschutz und Birnbacher selbst
            und
            Holmes Rolston stellen sich die Frage, ob die Natur dem Menschen als Vorbild taugt. Birnbacher ist
            emeritierter
            Professor für Ethik an der Universität Düsseldorf. Holmes Rolston ist Professor der Philosophie an der
            Colorado
            State University.
          </div>
          <p>&nbsp;</p>
          <h3>Analyse und Kritik</h3>
          Schellnhuber, Hans-Joachim (2015): Die fatale Dreiecksbeziehung zwischen Klima, Mensch und Kohlenstoff.
          München:
          Bertelsmann.
          <div class="tab">Der sonst so kühle Naturwissenschaftler aus dem Feld der Klimaforschung gibt in diesem Buch
            auch
            einen Einblick darauf, wie sehr sich der Planet in seiner Lebenszeit klimatisch verändert hat und was das
            mit
            ihm gemacht hat und immer noch macht. Den Klimawandel bezeichnet Schellnhuber als „ein profanes Desaster auf
            das
            die Menschheit starrsinnig zusteuert“. In drei großen Abschnitten (betitelt mit Haut, Fleisch, Mark)
            vermengt
            er
            biographische Erzählungen, umfangreiche wissenschaftliche Fakten und normative Aussagen.
          </div>
          <p>&nbsp;</p>
          Papst Franziskus (2015): Laudato si´. Über die Sorge für das gemeinsame Haus. Verlautbarungen des
          Apostolischen
          Stuhls. Online unter: <a
            href="https://www.dbk-shop.de/de/publikationen/verlautbarungen-apostolischen-stuhls/enzyklika-laudato-si-papst-franziskus-sorge-gemeinsame-haus.html?dl_media=34572"
            target="_blank">
          https://www.dbk-shop.de/de/publikationen/verlautbarungen-apostolischen-stuhls/enzyklika-laudato-si-papst-franziskus-sorge-gemeinsame-haus.html?dl_media=34572</a>
          <div class="tab">Auch das System Religion kommt am Thema planetare Grenzen nicht mehr vorbei. Papst Franziskus
            nimmt sich diesem Thema an und schreibt: „Die Menschheit ist aufgerufen, sich der Notwendigkeit bewusst zu
            werden, Änderungen im Leben, in der Produktion und im Konsum vorzunehmen, um diese Erwärmung oder zumindest
            die
            menschlichen Ursachen, die sie hervorrufen und verschärfen, zu bekämpfen“
          </div>
          <p>&nbsp;</p>
          Peukert, Helge (2021): Klimaneutralität jetzt! Politiken der Klimaneutralität auf dem Prüfstand:
          IPCC-Berichte,
          Pariser Abkommen, europäischer Emissionshandel und Green Deal, internationale freiwillige
          Klimakompensationsprojekte und die deutsche Klimapolitik. Mit einem Vorwort von Niko Paech. Marburg:
          Metropolis.
          <div class="tab">Der Professor für Wirtschaftswissenschaften der Universität Siegen möchte mit diesem Buch ein
            Nachschlagewerk für Klimaaktivist:innen bieten. Mit einer hohen Zahlen- und Faktendichte zeigt er Politik-
            und
            Marktversagen auf. Besonders lesenswert ist sein Selbstversuch seine Flüge mittels Geldzahlungen
            CO<sub>2</sub>-neutral
            zu
            stellen. Er stellt dabei fest, dass es preisgünstig möglich ist, „sich in ein grünes Feigenblättchen [zu]
            kleiden“. Für lediglich 12 Euro pro Jahr kann er 17 Tonnen CO<sub>2</sub> ohne große Sorgen verfliegen
            (lassen). Die
            internationalen Übereinkünfte, die über Institutionen wie die UN oder die EU geschlossen werden, sei es das
            Kyoto-Protokoll, das Pariser Klimaabkommen oder die EU-Klimapolitik überzieht Peukert mit einer
            faktengesättigten Kritik: „Um das Ziel des [Pariser] Abkommens zu erreichen, müssten 80% der förderbaren
            fossilen Ressourcen im Boden bleiben. Stattdessen läuft ungeachtet des Abkommens nicht nur die Ölförderung
            auf
            Hochtouren […] Der Klimawandel und seine Folgen werden immer deutlicher, die Weltstaatengemeinschaft ist
            nicht
            in der Lage oder willens, ihm zu begegnen.“ lautet ein Zwischenfazit des Wirtschaftswissenschaftlers.
          </div>
          <p>&nbsp;</p>
          Nassehi, Armin (2020): Klima, Viren, Kurven. Was heißt es, auf die Wissenschaft zu hören? In: Kursbuch Ausgabe
          202: Donner. Wetter. Klima. Herausgegeben von Armin Nassehi und Peter Felixberger.
          <div class="tab">„Follow the science“ und „Unite behind the science sind Sprüche, die man auf vielen Plakaten
            auf
            den freitäglichen Demonstrationen der 2018 entstandenen Klimabewegung „Fridays for Future“ lesen kann. Diese
            Haltung kritisiert der Soziologieprofessor der Ludwigs-Maximilians-Universität deutlich: „Auf die
            Wissenschaft
            zu hören ist kein wissenschaftlicher Sachverhalt, sondern ein politischer, ein ökonomischer, ein
            rechtlicher,
            also genau genommen ein gesellschaftlicher Sachverhalt. […] Wissenschaft kann nicht jene Versöhnerin sein,
            die
            in dem Diktum [unite behind the science] aufscheint – selbst dann nicht, wenn ihre selbst erzeugte
            Debattenlage
            Klarheit und Eindeutigkeit präsentiert und nahelegt.“ Eine ähnliche Kritik äußert auch Professor Norbert
            Bolz
            (TU Berlin) in dem Essay „Keine Macht der Moral. Politik jenseits von Gut und Böse“ der im Verlag Matthes
            und
            Seitz erschienen ist.
          </div>
          <p>&nbsp;</p>
          Blühdorn, Ingolfur (2020): Nachhaltige Nichtnachhaltigkeit. Warum die ökologische Transformation der
          Gesellschaft
          nicht stattfindet. Bielefeld: transcript.
          <div class="tab">Blühdorn, Professor für Soziale Nachhaltigkeit an der Wirtschaftsuniversität Wien, und seine
            Mitarbeiter:innen holen zu einer umfassenden Kritik der Transformationsbemühungen in Wissenschaft und
            Politik
            aus. Sie bemängeln, dass selbst die „große Transformation“ sich auf die „bekannten Akteure und
            Institutionen“
            verlässt und auf die bekannten Instrumente wie die Wissenschaft, den Markt und die technologische
            Innovation“
            vertraut. Blühdorn: „Inner- und zwischengesellschaftliche Umverteilung, konkrete Maßnahmen zur Begrenzung,
            Reduktion oder Rationierung gerade in den wohlhabenderen Ländern des globalen Nordens, sowie das zumindest
            partielle Aussetzen der Logik des Wachstums und der Vermarktlichung spielen […] in den schwachen
            Interpretationen der großen Transformation keine nennenswerte Rolle“.
          </div>
          <p>&nbsp;</p>
          Luhmann, Niklas (2008): Ökologische Kommunikation. Kann die moderne Gesellschaft sich auf ökologische
          Gefährdungen
          einstellen? 5. Auflage. Wiesbaden: VS.
          <div class="tab">
            <p>Dieses Buch verlangt den Leser:innen einiges ab. Auch wenn zu Beginn eine kurze Einführung in
              die Luhmann´sche Systemtheorie geboten wird, ist das Buch ohne erweitertes Vorwissen schwer zu verstehen.
              Auf
              die Frage im Titel antwortet Luhmann im Jahre der Ersterscheinung (1985) des Buches bereits sehr
              pessimistisch:
              „Aus evolutionärer Sichtweise werden Systeme eliminiert, die dem Trend der ökologischen Selbstgefährdung
              folgen.“
            </p>
            <p>Luhmann starb 1998. Es wäre sehr herausfordernd ihn zu überzeugen, dass sich die Welt auf dem Wege der
              Nachhaltigkeit befände, müsste man ihm gegenüber eingestehen, dass seit 1985 die CO<sub>2</sub>-Konzentration
              in der
              Atmosphäre des Planeten Erde von etwa 345 ppm auf heute ca. 440 ppm zugenommen hat. Er würde dann
              vielleicht
              sich selbst zitieren und sagen, dass „die Gesellschaft [nur] unter sehr beschränkten Bedingungen ihrer
              eigenen
              Kommunikationsmöglichkeiten auf Umweltprobleme reagieren“ kann. Doch wer diesen Gedankenweg bis zu dieser
              Erkenntnis nachzeichnen will, muss sich erst in das komplexe Theoriegebäude der Systemtheorie
              einarbeiten. </p>
          </div>
          <p>&nbsp;</p>
          Klein, Naomi (2015): Die Entscheidung: Klima vs. Kapital. Frankfurt am Main: S. Fischer.
          <div class="tab">
            Die Publizistin Klein sieht in der Wachstumsorientierung des Kapitalismus den Grund für die
            Klimakrise. Sie schreibt sehr persönlich auch über ihre Schwangerschaft und wie diese ihre Sicht auf die
            ökologischen Herausforderungen verändert hat. In einer Mikroerzählung über <a
              href="https://www.spiegel.de/geschichte/pazifikinsel-nauru-a-947212.html"
              target="_blank">
            die Geschichte der kleinen Pazifikinsel Nauru</a> führt Klein vor, wie wirtschaftliches Wachstum erst zu
            unglaublichem Reichtum, aber nach Ende
            der lokal auffind- und abbaubaren Ressourcen zu Armut und Abhängigkeit von internationalen Geldgebern führt.
          </div>
          <p>&nbsp;</p>
          Konicz, Tomasz (2020): Klimakiller Kapital. Wie ein Wirtschaftssystem unsere Lebensgrundlagen zerstört.
          Wien/Berlin: Mandelbaum.
          <div class="tab">Der Journalist und Autor Konicz stellt vorab unmissverständlich klar: „Wirtschaftswachstum
            und
            Klimaschutz, kapitalistische `Arbeitsplätze´ und ökologisch intakte Umwelt sind unvereinbar.“ Konicz beruft
            sich
            auf die Theorien der Kritik der politischen Ökonomie von Karl Marx und sieht im Wirtschaftssystem des
            Kapitalismus, die Wurzel der Klimakrise. Dies erscheint als sehr einseitig, mehr oder minder ideologisch und
            seine Quellenarbeit erweist sich als nicht besonders tiefgängig. Sein Lösungspfad besteht in den üblichen
            Lösungsvorschlägen von marxistischer Seite: Enteignung, Räte und einem Ende des Warencharakters der
            Gebrauchsgegenstände. Am Ende jedoch gibt er auch zu, dass er kein Patentrezept für ein neues
            Wirtschaftssystem
            hat und man auch allen gegenüber skeptisch sein solle, die solch eins ihr Eigen nennen. Dieses Buch wird
            hier
            aufgeführt, weil es sich dezidiert mit der analytischen Seite der marxistischen Theorie beschäftigt und
            nicht
            mit revolutionären Thesen.
          </div>
          <p>&nbsp;</p>
          Mahnkopf, Birgit (2022): Der große (Selbst-)Betrug "Klimaneutralität" durch "grünes Wachstum" – Essay. Online
          unter: <a
            href="https://www.bpb.de/shop/zeitschriften/apuz/green-new-deals-2022/345736/der-grosse-selbst-betrug/"
            target="_blank">
          https://www.bpb.de/shop/zeitschriften/apuz/green-new-deals-2022/345736/der-grosse-selbst-betrug/</a>
          <div class="tab">
            Die Professorin für Europäische Gesellschaftspolitik der Hochschule für Wirtschaft und Recht Berlin
            kritisiert
            in diesem Essay die europäische Klimapolitik. Ihre Kritik setzt an vier Punkten an: Der „New Green Deal“ der
            Europäischen Union rüttelt erstens nicht am eigenen ökonomischen Wachstumsziel. Zweitens, dass vor allem die
            Output-Seite in Form von Emissionen von Treibhausgasen in den Blick genommen wird, jedoch kaum die
            Input-Seite,
            also z.B. die Umweltschäden durch die Petrochemie. Drittens die geopolitische Ausrichtung der
            EU-Klimapolitik,
            die auf Machtzuwachs durch Unabhängigkeit von außereuropäischen Energielieferanten zielt und viertens den
            Begriff der Klimaneutralität, der dazu führt, „dass anderswo (allzu häufig in den armen Ländern des Globalen
            Südens) verrechnet werden. Dabei ist es egal, ob technische Lösungen (wie die hochproblematische Abscheidung
            von
            CO<sub>2</sub> und deren Verbringung in tiefere Erdschichten) zum Einsatz kommen oder ein `Ausgleich´ für
            Europas
            Emissionen erfolgt – etwa durch Aufforstung zur Absorption von CO<sub>2</sub> an einem anderen Ort auf dem
            Planeten.
            Insofern stellt der Green Deal keinen Wandel in Aussicht. Denn ein solcher Wandel würde – inzwischen selbst
            nach
            Einschätzung der International Energy Agency – verlangen, dass die noch vorhandenen fossilen Ressourcen im
            Boden
            bleiben.“
          </div>
          <p>&nbsp;</p>
          Franzen, Jonathan (2020): Wann hören wir auf uns etwas vorzumachen? Gestehen wir uns ein, dass wir die
          Klimakatastrophe nicht verhindern können. Hamburg: Rowohlt.
          <div class="tab">
            Franzen ist kein Wissenschaftler, sondern ein Bestseller-Autor und legt hier einen sehr kurzen Essay vor,
            den
            man leicht in einer Stunde lesen kann. Wenn man sich zu dieser Lektüre entscheidet, sollte man gewarnt sein,
            dass dies der pessimistischste Debattenbeitrag ist, den man auf dem Büchermarkt finden kann. Bei Franzen ist
            die
            menschliche Welt bereits fast untergegangen und wir müssen nun sehen wie wir unter den veränderten
            Bedingungen
            zurechtkommen können.
          </div>
          <p>&nbsp;</p>
          Science Busters (2020): Global Warming Party. Wir wir uns das Klima schönsaufen können und andere
          wissenschaftlich
          überprüfte Anregungen zur Rettung der Menschheit. München: Carl Hanser.
          <div class="tab">Die Lage um die Umwelt und Klimakrise ist ernst, unbezweifelt. Doch das ist für die Science
            Busters aus Österreich kein Grund, Wissenschaftsvermittlung nicht humoristisch zu gestalten. Und es wirkt
            auch.
            Über das, was einen zum Lachen gebracht, kann man sich besser erinnern. Der Mitbegründer Prof. Dr. Heinz
            Oberhummer ist 2015 verstorben. Die <a
                href="https://sciencebusters.at/"
                target="_blank">
              Science Busters</a> arbeiten heute aber bis heute weiter.
          </div>
          <p>&nbsp;</p>
          Brand, Ulrich; Wissen, Markus (2017): Imperiale Lebensweise. Zur Ausbeutung von Mensch und Natur im globalen
          Kapitalismus. 7. Auflage. München: oekom.
          <div class="tab">
            Brand, Universitätsprofessor für Internationale Politik an der Universität Wien, und Wissen, Professor für
            Gesellschaftswissenschaften mit dem Schwerpunkt sozial-ökologische Transformationsprozesse an der Hochschule
            für
            Wirtschaft und Recht in Berlin, sehen die Ursachen der Naturausbeutung nicht anthropologisch im Charakter
            des
            Menschen angelegt, sondern in den „Produktions-, Distributions- und Konsumnormen, die tief in die
            politischen,
            ökonomischen und kulturellen Alltagsstrukturen und -praxen der Bevölkerung im globalen Norden eingeschrieben
            und
            zunehmend auch den Schwellenländern eingeschrieben sind“. Der Vorstellung, dass mit einer ökologischen
            Modernisierung des kapitalistischen Systems, inklusive eines grünen Wachstums, ein praktikabler Lösungspfad
            gegeben sei, erteilen Sie eine Absage und fordern, dass Gesellschaft „ganz anders gedacht und gemacht“
            werden
            müsse. Dazu bräuchte es eine hegemoniefähige solidarische Lebensweise, die von „Mitte-unten-Bündnissen“
            getragen
            werden.
          </div>
          <p>&nbsp;</p>
          Foster, John Bellamy; Clark, Brett; York, Richard (2011): Der ökologische Bruch. Der Krieg des Kapitals gegen
          den
          Planeten. Hamburg: Laika.
          <div class="tab">
            Foster, Professor für Soziologie an der University of Oregon in Eugene, Clark, Soziologieprofessor an der
            University of Utah in Salt Lake City und York, Professor für Soziologie und Environmental Studies an der
            University of Oregon in Eugene, interpretieren die Hoffnung auf eine technologische Lösung innerhalb eines
            kapitalistischen Systems als eine Form der „Geisterbeschwörung“. Sie behaupten: „Technologischer Wandel wird
            propagiert mit dem Versuch zu argumentieren, dass die gesellschaftlichen Beziehungen (von Macht und
            Eigentum)
            die gleichen bleiben könnten – während es bloß Werte, Bewusstsein und Wissen seien, die sich veränderten und
            die
            technologische Erneuerung lenkt.“
          </div>
          <h3>Zukunftsszenarien</h3>
          Götze, Susanne (2019): Land unter im Paradies. Reportagen aus dem Menschenzeitalter. Bonn: bpb.
          <div class="tab">
            Die Journalistin Götze (u.a. DER SPIEGEL, DIE ZEIT) nimmt uns mit auf ihre Reise durch mehr als zehn Länder.
            Sie
            zeigt uns wie sich die Arbeit der Winzer in Frankreich durch die globale Erhitzung verändert, wie sich das
            Tote
            Meer sich wandelt, weil das Klima sich erwärmt: „Mittlerweile sinkt der Wasserspiegel um einen bis
            anderthalb
            Meter pro Jahr“. Es wird einem so klar, dass das Wasser für den Anstieg des Meeresspiegels auch irgendwo
            herkommen muss, eben z.B. aus dem Toten Meer. Der Meeresspiegel steigt an, weil anderswo mehr verdunstet.
            Das
            führt zu Dürren da und Überflutungen dort. Besonders übersichtlich sind die einzelnen Länderdaten. Die
            einzelnen
            Reportagen sind journalistisch geschrieben und flüssig zu lesen. Am Ende des Buches wird einem klar: Der
            Klimawandel ist kein Szenario der Zukunft und somit in dieser Kategorie vielleicht nicht ganz korrekt
            eingeordnet. Die Auswirkungen des Klimawandels sind bereits jetzt spür- und messbar. Diese Spür- und
            Messbarkeit
            aber entwickelt sich in unterschiedlicher Geschwindigkeit an unterschiedlichen Orten und manchmal ist ein
            Blick
            in die räumliche Ferne ein Blick die nahende Zukunft.
          </div>
          <p>&nbsp;</p>
          Wallace-Wells, David (2019): Die unbewohnbare Erde. Leben nach der Erderwärmung. München: Ludwig.
          <div class="tab">Wallace-Wells, Journalist und Autor, unterteilt seine Kapitel nicht immer stringent mit
            Szenarien
            einer 12-Grad-Welt, 7-Grad-Welt, 6-Grad-Welt, 5-Grad-Welt, 4-Grad-Welt und 2-Grad-Welt. Wie auch Reimer und
            Staud, die sich auch in der kommentierten Literaturliste finden lassen, orientiert Wallace-Wells eher an
            Worst-Case-Szenarien. Wallace-Wells kritisiert die Marktgläubigkeit und wird an vielen Stellen arg
            polemisch:
            „Der arrogante Westen hat fünf Jahrhunderte lang verächtlich auf diejenigen herabgeschaut, die von
            tropischen
            Krankheiten heimgesucht wurden, und man fragt sich, wie sich das verändern wird, wenn die Malaria und
            Denguefieber übertragenden Moskitoarten auch in Kopenhagen und Chicago umherschwirren.“ Es scheint fast so,
            als
            ob Wallace-Wells es an manchen Stellen genießt sich eine dystopische Welt auszumalen. Das Schlimme daran ist
            nur: An vielen Stellen könnte er Recht behalten.
          </div>
          <p>&nbsp;</p>
          Reimer, Nick; Staud, Toralf (2021): Deutschland 2050. Wie der Klimawandel unser Leben verändern wird. 7.
          Auflage
          Köln: Kiepenheuer & Witsch.
          <div class="tab">
            Die beiden Journalisten zeigen in 14 Kapiteln auf, welche Folgen ein Überschreiten der 2-Grad-Grenze hätte.
            Sie
            orientieren sich jedoch in vielen Fällen am worst-case-Szenario, um möglichst eindrücklich zeigen zu können,
            wie
            sich dieses Land verändern könnte. Das kann man Ihnen als Kenner oder Kennerin der Sachstandsberichte des
            IPCC
            vorwerfen. Allerdings ist auch richtig, dass die Szenarien des IPCC als sehr konservativ in ihren
            Voraussagen
            gelten und es zum Teil schon schlimmer gekommen ist als vorausgesagt. Wer also wissen will, wie es im
            schlimmsten Falle kommen könnte, ist mit diesem Buch sehr gut versorgt.
          </div>
          <h3>Lösungspfade</h3>
          Schneidewind, Uwe; Palzkill-Vorbeck, Alexandra: Suffizienz als Business Case-: nachhaltiges
          Ressourcenmanagement
          als Gegenstand einer transdisziplinären Betriebswirtschaftslehre. Online unter: <a
            href="https://epub.wupperinst.org/files/3955/ImpW2.pdf"
            target="_blank">
          https://epub.wupperinst.org/files/3955/ImpW2.pdf</a>.
          <div class="tab">
            Der ehemalige Präsident des Wuppertal Instituts, Professor für Betriebswirtschaftlehre an der Bergischen
            Universität Wuppertal und jetzige Oberbürgermeister von Wuppertal und seine Mitarbeiterin zeigen auf, dass
            suffizientes Handeln nicht bedeuten muss, dass Unternehmen keine Gewinne machen können und die Kosten
            steigen.
            Anhand mehrerer Beispiele zeigen die Autor:innen auf, dass suffizientes Handeln zu höheren statt geringeren
            Umsätzen und überraschenden Gewinnchancen führen kann. Sie schlagen hier eine Brücke zwischen
            Wachstumskritiker:innen und Vertreter:innen einer ökologischen Modernisierung des Kapitalismus.
          </div>
          <p>&nbsp;</p>
          Bundeszentrale für politische Bildung (2021): Abschied von der Kohle. Struktur- und Kulturwandel im Ruhrgebiet
          und
          in der Lausitz. Bonn: bpb.
          <div class="tab">
            Die Bundeszentrale für politische Bildung hat hier die Aufsätze zum Thema Kohleausstieg zusammengefasst, die
            in
            der Zeitschrift <a
              href="https://www.bpb.de/shop/zeitschriften/apuz/?field_filter_thema=all&field_date_content=all&d=1"
              target="_blank">
            Aus Politik und Zeitgeschichte</a> erschienen sind. Der Klimawandel bedingt die Energiewende und die
            Energiewende bedingt damit auch eine Änderung von lange bedienten Arbeits- und Wertschöpfungsstrukturen. Ein
            Strukturwandel beinhaltet viele Herausforderungen. In diesem Sammelband finden sich Artikel zur Geschichte
            des
            Kohleabbaus in beiden Teilen Deutschlands. Es werden kulturelle Querverbindungen erläutert wie z.B. der
            Zusammenhang von Fußballkultur und Wirtschaftsstruktur im Ruhrgebiet und auch die Chancen des
            Strukturwandels
            angesprochen.
          </div>
          <p>&nbsp;</p>
          Fücks, Ralf (2016): Intelligent wachsen. Die grüne Revolution. Bonn: bpb.
          <div class="tab">
            Der geschäftsführende Gesellschafter des Zentrums liberale Moderne sieht eine Veränderung in den Unternehmen
            herannahen. In vielen Unternehmen – so Fücks – findet „ein technologischer und kultureller Wandel statt. Sie
            richten sich auf eine Zukunft mit radikal reduzierten CO<sub>2</sub>-Emissionen aus.“ Ganz im Gegensatz zu
            Maja Göpel ist
            Fücks sich sicher: „Innovative Lösungen, neue Produkte und Technologien kommen nicht aus der Verwaltung“. Er
            geht davon aus, dass eine „ökologische Transformation des Kapitalismus“ ansteht, das getrieben wird durch
            ein
            verändertes Verbraucherbewusstsein, Umweltbehörden, einem dichten Gewebe von Gesetzen und Verordnungen sowie
            umweltbezogene Abgaben und Steuern und dem Druck neuer politischer Formationen. Auch die Wissenschaft spielt
            bei
            dieser Transformation des Kapitalismus eine große Rolle, da sie ökologisches Wissen in die Bevölkerung
            transferiert und so das Kaufverhalten entscheidend beeinflusst.
          </div>
          <p>&nbsp;</p>
          Radtke, Jörg (2016): Bürgerenergie in Deutschland. Partizipation zwischen Gemeinwohl und Rendite Wiesbaden:
          Springer.
          <div class="tab">
            Wer sich ein Grundwissen über Bürgerenergie aneignen, dem sei diese Fachpublikation sehr empfohlen.
            Wenngleich
            in wissenschaftlichen Duktus verfasst, sind die ausgearbeiteten Thesen durch eine akribische Analyse von
            Fallbeispielen und Umfragen auch bis heute als sehr stabil zu bezeichnen und erschließen bei ruhiger Lektüre
            sehr gut. Das Phänomen der Bürgerenergie ist noch relativ neu, aber auch ein Aufschein einer möglichen
            Zukunft
            der Produktion von Energie, unabhängig von großen Konzernen oder einer Abhängigkeit von fossilen
            Energieträgern
            aus dem Ausland. Jörg Radtke ist promovierter Politikwissenschaftler und arbeitet an der Universität Siegen.
          </div>
          <p>&nbsp;</p>
          Latif, Mojib (2022): Countdown. Unsere Zeit läuft ab – was wir der Klimakatastrophe noch entgegensetzen
          können.
          Freiburg/Basel/Wien: Herder.
          <div class="tab">
            Es ist überraschend wie Latif nach vielen Seite von Zahlen und Fakten zum Klimawandel doch noch Hoffnung zu
            wecken vermag. Damit sich diese Hoffnung verwirklichen lässt, spricht Latif die Leser:innen auch direkt an:
            „Bei
            der Effizienzoffensive kann aber auch jeder und jede mitmachen, liebe Leserinnen und Leser“. Er vertraut auf
            Marktmechanismen wie z.B. ein hoher CO<sub>2</sub>-Preis und auf Optimismus und Enthusiasmus der Menschen.
          </div>
          <p>&nbsp;</p>
          Latif, Mojib (2020): Heißzeit. Mit Vollgas in die Klimakatastrophe – und wie wir auf die Bremse treten. Bonn:
          bpb.
          <div class="tab">
            Latif, Präsident der Akademie der Wissenschaften in Hamburg, legt hier nochmal Grundwissen über den Grenzen
            des
            Wachstums und Belege für den anthropogenen Treibhauseffekt vor. Er stellt sich die Frage, warum im Kampf ums
            Klima „Stillstand“ herrscht und sieht nicht nur die Komplexität des Themas als Ursache, sondern auch die
            Störfeuer aus Politik (Aufstieg der Populisten) und Wirtschaft sowie die Veränderung der Diskussionskultur
            durch
            die neuen Medien. Latif fordert einen schnellen Umbruch und erinnert daran, dass man mit der Physik nicht
            verhandeln kann. Er meint, dass es eine neue Klimakommunikation bräuchte und es in diesem Zusammenhang nicht
            ausreicht, wissenschaftliche Fakten zu vermitteln. Es ginge nun darum, die Menschen in ihrer Lebenswelt
            abzuholen und ihnen klarzumachen, „was die globale Erwärmung für sie selbst bedeutet“.
          </div>
          <p>&nbsp;</p>
          Wissenschaftlicher Beirat der Bundesregierung Globale Umweltveränderungen, WBGU (2011): Hauptgutachten:
          Gesellschaftsvertrag für eine Große Transformation. Online unter: <a
            href="https://www.wbgu.de/de/publikationen/publikation/welt-im-wandel-gesellschaftsvertrag-fuer-eine-grosse-transformation#sektion-downloads"
            target="_blank">
          https://www.wbgu.de/de/publikationen/publikation/welt-im-wandel-gesellschaftsvertrag-fuer-eine-grosse-transformation#sektion-downloads</a>.
          <div class="tab">
            <p>
              Der WBGU setzte 2011 auf eine verstärkte Einbindung der Bürger:innen in Fragen des Klima- und
              Umweltschutzes,
              aber auch für einen Wertewandel in der Bevölkerung und in der Politik: „Für das Gelingen einer
              Transformation
              in Richtung Nachhaltigkeit ist […] eine ausreichende Verbreitung entsprechender Einstellungen und
              Präferenzen
              unabdingbare Voraussetzung.“ Damit diese Partizipation, Wirkung entfalten kann, soll der Staat sich seiner
              Stärke wieder bewusst werden. Es brauche rasch und global eine CO<sub>2</sub>-Bepreisung, eine globale
              Einspeisevergütungen für erneuerbare Energien und eine Stärkung der internationalen Institutionen wie der
              UN
              und der EU.
            </p>
            <p>
              Die Sonder- und Hauptgutachten und andere Stellungnahmen des WBGU finden sich <a
                href="https://www.wbgu.de/de/publikationen/alle-publikationen"
                target="_blank">
              hier</a>. Es findet sich eine enorme
              Themenbreite von Landnutzung über Urbanisierung und Megatrends bis hin zu Digitalisierung und planetare
              Gesundheit. Die Gutachten sind von immer wieder neuen Expert:innen geschrieben worden und bestechen durch
              eine
              enorme Kenntnis der internationalen wissenschaftlichen Literatur.
            </p>
          </div>
          <p>&nbsp;</p>
          Schneidewind, Uwe (2019): Die große Transformation. Eine Einführung in die Kunst des gesellschaftlichen
          Wandels.
          Frankfurt am Main: S. Fischer.
          <div class="tab">
            Unter dem Motto „Making Utopia possible” entwickelt Schneidewind zusammen mit vielen Mitarbeiter:innen des
            Wuppertal Instituts das Konzept der „Zukunftskunst“. Damit meint er „das Zusammenspiel von technologischen,
            ökonomischen, politisch-institutionellen und kulturellen Dynamiken in Prozessen der Großen Transformation zu
            verstehen und sie für das Projekt einer Nachhaltigen Entwicklung fruchtbar zu machen“. Schneidewind versteht
            unter einer Großen Transformation keinen grundlegenden Systemwechsel oder gar einer Abkehr vom Kapitalismus,
            betont aber auch, dass sich „transformative Kräfte […] sich mit bestehenden Interessen und sozialen und
            strukturellen Machtstrukturen auseinandersetzen [müssen], die der sozial-ökologischen Transformation im Weg
            stehen. Er ist Vertreter der multi-level-perspective und vertraut – wie auch Leggewie und Welzer – darauf,
            dass
            sich die nachhaltigen Lebensstile aus der Nische in die gesamte Gesellschaft ausbreiten.
          </div>
          <p>&nbsp;</p>
          Göpel, Maja (2021): Unsere Welt neu denken. Eine Einladung. Berlin: Ullstein.
          <div class="tab">
            Die Honorarprofessorin der Leuphana-Universität Lüneburg appelliert an jeden Einzelnen: „Wir alle können
            jeden
            Tag Teil der Veränderung sein, die wir uns für die Welt wünschen, auch wenn sich diese Veränderung erst
            klein
            und wenig anfühlt“ und kritisiert die Zwecke für die der Mensch seinen Verstand einsetzt: „Wenn wir den
            technologischen Fortschritt weiter so einsetzen wie bisher und ihm keine klar andere Funktion geben als die
            des
            kurzfristigen ökonomischen Wachstums und der weiteren Zunahmen an Konsum, verschieben wir die Lösung der
            Probleme in die Zukunft.“ Im Gegensatz zu Ralf Fücks verortetet sie die Ursprünge von Innovationen nicht in
            Unternehmen, sondern in Universitäten und anderen staatlich finanzierten Institutionen. Eisenbahn,
            Raumfahrt,
            Atomkraftwerke, Computer, Internet, Nanontechnologie und Pharmaforschung führt Göpel im Rekurs auf Mariana
            Mazzucato, Professorin für Economics of Innovation and Public Value am University College London, als
            Beispiele
            für bahnbrechende Innovationen an, die nicht von Hasardeuren aus Unternehmen vorangetrieben wurden, sondern
            von
            staatlicher Seite oder zumindest finanziert durch den Staat.
          </div>
          <p>&nbsp;</p>
          Göpel, Maja (2022): Wir können auch anders. Berlin: Ullstein.
          <p>&nbsp;</p>
          Weizsäcker, Ernst Ulrich von (2022): So reicht das nicht! Paderborn: Bonifatius.
          <p>&nbsp;</p>
          Griese, K. M., & Halstrup, D. (2013). Suffizienz in der Unternehmenskommunikation. Ökologisches
          Wirtschaften-Fachzeitschrift, 28(4), 40-43. Online unter: <a
            href="https://oekologisches-wirtschaften.de/index.php/oew/article/view/1314/1299"
            target="_blank">
          https://oekologisches-wirtschaften.de/index.php/oew/article/view/1314/1299</a>.
          <div class="tab">
            Die Autor:innen argumentieren, dass nicht nur mit einem Immer-Mehr für den Kauf industrielle hergestellter
            Waren
            geworben werden kann, sondern „den konsumentenseitig genügsamen und umweltverträglichen Verbrauch von
            Material-
            sowie Energiemengen“. Auch in der Suffizienz liegt also ein Versprechen an die Chancen aus Eigen- oder
            Fremdkapital immer mehr Kapital erzeugen zu können.
          </div>
          <p>&nbsp;</p>
          Leggewie, Claus; Welzer, Harald (2009): Das Ende der Welt, wie wir sie kannten: Klima, Zukunft und die Chancen
          der
          Demokratie. Frankfurt am Main: S. Fischer.
          <div class="tab">
            Die beiden <i>Public Intellectuals</i> sehen in den Nischen bereits die Veränderung in der Zukunft
            aufscheinen.
            Sie
            vertrauen im Stile von 1968 auf die Wirkkraft von Basisinitiativen, erklären aber auch, wieso es den
            Menschen
            so
            schwerfällt, ihr Wissen, um die planetaren Grenzen in alltägliche Handlungsentscheidungen einfließen zu
            lassen.
            Selten liest man so eine eingängige Erklärung über den Umgang des Menschen mit kognitiven Dissonanzen. Claus
            Leggewie ist Inhaber der Ludwig Börne-Professur an der Justus-Liebig-Universität Gießen und war Direktor des
            Kulturwissenschaftlichen Instituts. Harald Welzer ist Honorarprofessor für Transformationsdesign an der
            Europa-Universität Flensburg
          </div>
          <p>&nbsp;</p>
          Jackson, Tim (2009): Wohlstand ohne Wachstum. Leben und Wirtschaften in einer endlichen Welt. Herausgegeben
          von
          der Heinrich-Böll-Stiftung. München: oekom.
          <div class="tab">
            Jackson, Professor of Sustainable Development am Centre for Environment and Sustainability an der University
            of
            Surrey, fordert Wohlstand nicht länger „als ein sich ständig ausdehnendes Wirtschaftsparadies“ anzusehen. Er
            möchte, dass sich ein Bewusstsein etabliert, dass zu erkennen vermag, „dass mehr manchmal weniger ist“. Das
            Bruttoinlandsprodukt vermag nicht zu messen, ob die Menschen in einem Land glücklich oder unglücklich sind.
            Jackson findet, dass z.B. „sozialer Zusammenhalt“ mehr Glück für das Individuum verspricht als die Anhäufung
            von
            Konsumgütern.
          </div>
          <p>&nbsp;</p>
          Radermacher, Franz Josef (2009): Welt mit Zukunft. Überleben im 21. Jahrhundert. 5. Auflage. Hamburg: Murmann.
          <div class="tab">
            Radermacher, Professor für Informatik an der Universität Ulm, macht deutlich, dass „Reiche die meiste
            Verschmutzung“ verursachen und fordert einen Global Marshall Plan, dessen Ziel nicht die „besseren Menschen“
            sind, sondern ein Ordnungsrahmen, der einen „vernünftigen Gesellschaftsvertrag für den Globus“ darstellt.
          </div>
          <p>&nbsp;</p>
          Löwy, Michael (2016): Ökosozialismus. Die radikale Alternative zur ökologischen und kapitalistischen
          Katastrophe.
          Hamburg: Laika.
          <div class="tab">
            Löwy, Forschungsdirektor am französischen Centre national de la recherche scientifique (CNRS), konstatiert:
            „Es
            ist offensichtlich, dass uns das verrückte Wettrennen nach Profiten, die produktivistische und
            merkantilistische
            Logik in ein ökologisches Desaster von unvorhersehbaren Ausmaßen führen“. Löwy hat kein Vertrauen in eine
            ökologische Modernisierung des kapitalistischen Wirtschaftssystems und fordert eine Systemveränderung, die
            er
            als Ökosozialismus bezeichnet. Er möchte eine Gesellschaft, die auf demokratischer Planung und Kontrolle
            basiert
            und es ein kollektives Eigentum an den Produktionsmitteln gibt. „Es braucht eine revolutionäre Umwälzung auf
            sozialer und wirtschaftlicher Ebene. Löwy setzt sich nicht mit der Frage auseinander, ob die Menschen so
            eine
            Transformation überhaupt wollen oder ob sie die unternehmerische Freiheit und die Freiheit des Konsums nicht
            mehr schätzen.
          </div>
          <p>&nbsp;</p>
          Herrmann, Ulrike (2022): Das Ende des Kapitalismus. Warum Wachstum und Klimaschutz nicht vereinbar sind – und
          wie
          wir in Zukunft leben werden. Köln: Kiepenheuer & Witsch.
          <div class="tab">
            Die Journalistin Herrmann (taz, häufiger Gast im Presseclub der ARD) geht davon aus, dass eine Entkoppelung
            von
            Wirtschaftswachstum und Treibhausgasemissionen nicht möglich sei. Deswegen schlägt die Journalistin als
            Ausweg
            von der Klimakrise vor, Wirtschaft wie zu Kriegszeiten zu betreiben. Als Vorbild nimmt sie, die britische
            Kriegswirtschaft von 1940. Abgesehen von funktionalen Fragen, bleibt offen wie hoch die Akzeptanz der
            Bevölkerung für den Vorschlag sein wird, ohne Krieg wie in Kriegszeiten leben zu sollen.
          </div>
          <p>&nbsp;</p>
          Malm, Andreas (2020): Wie man eine Pipeline in die Luft jagt. Kämpfen lernen in einer Welt in Flammen. Berlin:
          Mattes & Seitz. Sowie: Malm, Andreas (2020): Klima|x. Berlin: Mattes & Seitz.
          <div class="tab">
            Der Geograph Malm, Senior Lecturerer im Fach der Humanökologie an der Universität Lund, schenkt der Idee,
            dass
            innerhalb des bestehenden politischen und ökonomischen Systems, die Klimakrise noch aufzuhalten sei, keinen
            Glauben. Malm hat sich einem „Ökoleninismus“ verschrieben, fordert also revolutionäre Zustände, die er auch
            als
            Aktivist herbeiführen möchte und so finden sich in seinen Schriften auch biographische Erzählungen. Malm
            kritisiert die Gewaltfreiheit der Klimabewegung und fordert ein radikales Vorgehen: „Es gab eine Zeit für
            eine
            gandhianische Klimabewegung; vielleicht kommt auch eine Zeit für eine fanonianische. Schließlich könnte das
            Einreißen von Zäunen eines Tages durchaus als bloß geringfügiges, vielleicht sogar notwendiges Vergehen
            angesehen werden.“
          </div>
        </div>
      </div>

      <div class="tipp">
        <div class="tipp-header" @click="lastRemark = !lastRemark">
          <i :class="{ expanded : lastRemark }" class="fa fa-chevron-right expand-icon"/>
          Eine letzte Bemerkung
        </div>
        <div v-show="lastRemark" class="tipp-content">
          <p>
            <b>Forschung ist Teamarbeit und ständiger Widerspruch</b>. In der Wissenschaft beweist man nichts. Man
            versucht
            zu
            widerlegen und wenn man es nicht schafft, eine aufgestellte These zu widerlegen, dann gilt sie als vorläufig
            gut
            bestätigt. <b>Bleiben Sie immer kritisch, aber nicht aus Prinzip skeptisch</b>. Was einzig zählt, ist der
            zwanglose
            Zwang des besseren Arguments. Über den menschengemachten Klimawandel ist sich die Wissenschaft einig wie in
            keinem anderen Feld. Die Einigkeit darüber, dass der Klimawandel auf Eingriffe des Menschen zurückzuführen
            ist,
            erreicht laut einer <a
              href="https://journals.sagepub.com/doi/10.1177/0270467619886266"
              target="_blank">
            Studie von James Powell 100%</a>. Er wertete dazu über 11.600 wissenschaftliche Artikel aus.
            Wissenschaft versucht nicht Sachverhalte zu belegen, sondern stets Thesen zu widerlegen. Bislang gelang
            keine
            Widerlegung der These, dass der momentane rasch voranschreitende Klimawandel mit dem Verhalten und Handeln
            der
            Menschen zusammenhängt.
          </p>
          <p>
            <b>Ohne Witz! Es muss jetzt wirklich Bewegung in die Sache kommen</b> und jeder Mensch sollte prüfen, in
            welchem
            Handlungsspielraum er zum Schutz der Lebensgrundlage der Menschheit aktiv werden kann.
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import sdgs from '@/data/sdg_urls.js'

const FAVOURITES_KEY = 'think-green:favourite-sdgs'

export default {
  name: 'SdgList',
  data() {
    return {
      sdgs: sdgs,
      favourites: [],

      onlyFavourites: false,
      showFullIntro: false,

      welcome: false,
      whatsSustainability: false,
      strategies: false,
      criticism: false,
      challenges: false,
      greenhouse: false,
      limit: false,
      emissions: false,
      weatherCLimate: false,
      neutral: false,
      budget: false,
      budgetAmount: false,
      ipcc: false,
      ipccCriticism: false,
      rebound: false,
      adaption: false,
      sustainabilityGer: false,
      eCarsAlternative: false,
      climateProtection: false,
      nuclear: false,
      overpopulation: false,
      consumption: false,
      individual: false,
      hydrogenCars: false,
      eCars: false,
      plastics: false,
      literature: false,
      lastRemark: false,

    }
  },
  created() {
    let storedFavourites = JSON.parse(localStorage.getItem(FAVOURITES_KEY))
    if (storedFavourites)
      this.favourites = storedFavourites
  },
  methods: {
    goto(s) {
      this.$router.push('/sdg/' + s.url)
    },
    toggleFavourite(id) {
      if (this.favourites.includes(id)) {
        this.favourites.splice(this.favourites.indexOf(id), 1)
        return
      }
      this.favourites.push(id)
    },
    goBack() {
      this.$router.push("/menu");
    },
    isFavourite(id) {
      return this.favourites.includes(id)
    },
    gotoPoll() {
      this.$router.push('/poll')
    }
  },
  computed: {
    filteredSdgs() {
      if (this.onlyFavourites) {
        return this.sdgs.filter(s => this.isFavourite(s.id))
      }
      return this.sdgs
    },
  },
  watch: {
    favourites() {
      localStorage.setItem(FAVOURITES_KEY, JSON.stringify(this.favourites))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.sdglist-container {
  text-align: center;
  position: relative;
  // padding-top: 20px;
}

.intro {
  padding: 20px;
  text-align: left;
}

.filter-container {
  position: absolute;
  top: -10px;
  right: 10px;
  font-size: 20px;
}

.tile {
  height: 30vw;
  width: 30vw;
  max-width: 200px;
  max-height: 200px;
  display: inline-block;
  cursor: pointer;

  @include shadow;
  border-radius: $border-radius;
  margin: 10px;
  overflow: hidden;

  position: relative;

  background-color: white;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.fav-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding-left: 10px;
  padding-top: 10px;
}

.favourite {
  color: $gold;
}

.tab {
  margin-left: 40px;
  margin-top: 5px;
}

.back-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 40px;
}

.tipps-container {
  height: 100%;
  @include gradient;
}

.content {
  min-height: 100vh;

  a {
    color: $secondary;
  }
}

.tipp {
  margin: 10px 0px;

  .tipp-header {
    font-weight: bold;
    font-size: 16pt;
    cursor: pointer;

    i {
      font-size: 12pt;
    }
  }
}

.expand-icon {
  transition: transform 0.2s;
}

.expanded {
  transform: rotate(90deg);
}
</style>
