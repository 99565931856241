<template>
  <div class="sub-question-container">
    <div class="subquestion-header">
      {{ question.question }}
    </div>
    <div class="options">
      <div v-for="o in question.options" class="option" 
        :key="o.uid" 
        @click="toggleAnswer(o)"
        :class="{'selected': answers.includes(o)}">
          {{ o.uid }}
      </div>
      <div v-if="isMultiSelect" class="option" @click="toggleFreetext()" :class="{'selected': showFreetext }">Andere</div>
      <div v-if="showFreetext" class="freetext-container">
        <textarea v-model="freetext" class="input" ref="freetext"></textarea>
      </div>
    </div>
    <div v-if="isMultiSelect || this.showFreetext" class="next-button-container">
      <div class="next-button" @click="next()">
        Weiter
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubQuestion',
  props: ['question'],
  data() {
    return {
      answers: [],
      showFreetext: false,
      freetext: ''
    }
  },
  methods: {
    toggleAnswer(a) {
      if (!this.isMultiSelect && this.showFreetext){
        this.answers = []
        this.toggleFreetext()
        return
      }
      
      if(this.answers.includes(a)) {
        this.answers.splice(this.answers.indexOf(a), 1)
        return
      }
      this.answers.push(a)
      
      if (!this.isMultiSelect){
        this.questionDone()
      }
    },
    questionDone() {
      this.$emit('next', this.answers)
      this.freetext = ''
      this.showFreetext = false
      this.answers = []
    },
    toggleFreetext() {
      this.showFreetext = !this.showFreetext
      if(this.showFreetext) {
        this.$nextTick(() => {
          this.$refs.freetext.focus()
        })
      }
    },
    next() {
      if(this.freetext != '') {
        this.answers.push({ uid: '[ANDERE] ' + this.freetext, score: 0 })
      }
      this.questionDone()
    }
  },
  computed: {
    isMultiSelect() {
      return this.question.type == 'multi-select' 
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.subquestion-header {
  padding: 20px;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  border-bottom: 2px solid $secondary;
}
.options {
  padding: 10px;
  font-weight: 500;
  
  .option {
    padding: 10px 20px;
    border: 1px solid $primary;
    background-color: white;
    border-radius: $border-radius;
    margin: 10px;
    cursor: pointer;
  }
  .selected {
    background-color: $secondary;
    color: white;
  }
}

.next-button-container {
  text-align: right;

  .next-button {
    padding: 20px;
    background-color: $secondary;
    color: white;
    border-radius: 0px 0px $border-radius $border-radius;
    font-weight: 700;
    cursor: pointer;
  }
}

.freetext-container {
  padding: 20px;
  textarea {
    width: 100%;
    border: 1px solid $secondary;
  }
}
</style>
