<template>
  <div class="poll-container">
    <div class="header">
      {{ currentSection.section }} {{ sectionProgress }}
    </div>
    <div class="progress-bar"></div>
    <div class="measure">
      {{ currentMeasure.name }} 
      <div v-if="currentMeasure.info != ''" class="info-icon" @click="showInfo = true"><i class="fa fa-info-circle"/></div>
    </div>
    <div class="question">
      <question :question="currentMeasure" :section="currentSection.section" @next="nextMeasure()"/>
    </div>

    <div v-if="showInfo" class="modal-overlay" @click="showInfo = false">
      <div class="modal-content info" @click.stop>
        {{ currentMeasure.info }}
      </div>
    </div>
  </div>
</template>

<script>
import Question from '@/components/Question.vue'

import sections from '@/data/poll_questions.js'

export default {
  name: 'Poll',
  components: {
    Question,
  },
  data() {
    return {
      currentSectionIndex: 0,
      currentMeasureIndex: 0,

      measureCount: 1,

      sections: sections,

      showInfo: false,
    }
  },
  created() {
    this.$store.commit('resetAnswers')
  },
  methods: {
    nextMeasure() {
      this.measureCount++

      if(this.currentMeasureIndex < this.currentSection.measures.length-1) {
        this.currentMeasureIndex++
        return
      }

      if(this.currentSectionIndex < this.sections.length-1) {
        this.currentMeasureIndex = 0
        this.currentSectionIndex++
        return
      }
      
      this.pollFinished()
    },
    pollFinished() {
      this.$router.push('/demographics')
    }
  },
  computed: {
    currentSection() {
      return sections[this.currentSectionIndex]
    },
    currentMeasure() {
      return this.currentSection.measures[this.currentMeasureIndex]
    },
    sectionProgress() {
      return (this.measureCount) + '/' + this.numberOfMeasures
    },
    numberOfMeasures() {
      return sections.reduce((total, section) => total + section.measures.length, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.info-icon {
  float: right;
}

.progress-bar {
  height: 5px;
  width: 100%;
  background-color: $secondary;
}

.measure {
  padding: 20px 30px;
  padding-bottom: 0;
  text-align: left;
  font-weight: 500;
  font-size: 20px;
}

.question {
  padding: 30px;
}

.info {
  padding: 30px;
  box-sizing: border-box;
  text-align: left;
}
</style>
